.payment-wrapper {
  hr {
    background-color: #e6e7e9 !important;
    opacity: 1 !important;
  }

  .failure {
    background-color: rgba(239, 68, 68, 1);
    font-size: 12px;
    color: #ffffff;
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 999;

    p {
      margin: 0;
    }
  }


  .payment-header {
    position: sticky;
    top: 0;
    padding: 15px 0px 15px 0px;
    background: #ffffff;
    z-index: 999;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -5px;
      padding-bottom: 5px;

      span {
        text-align: left;
      }

      button.payment-close {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        width: 30px;
        color: #000000;
        font-weight: 400;
        padding: 0;
        text-align: right;

        &:hover {
          background-color: #ffffff;
          border: 1px solid #ffffff;
          color: #000000;
          font-weight: 400;
        }
      }
    }
  }

  .checkout-block {
    position: relative;

    .review-col {
      display: flex;
      justify-content: end;
    }
  }

  .validation {
    color: rgba(239, 68, 68, 1);
    font-weight: 400;
    font-size: 14px;
    margin: 15px 0;

    &.code-error{
      color: var(--error-red);
    }

    &.code-success{
      color: var(--success-green);
    }
  }

  .info-wrapper {
    text-align: left;
    width: 85%;
    padding-bottom: 40px;

    .billing-form {
    .padding-20{
      padding-top: 14px;
    }
        .license-wrapper{
            border: 1px solid #e6e7e9;
            background: #ffffff;
            padding: 20px;
            .empty-license-count-error{
              border-radius: 2px;
              background: rgba(239, 68, 68, 0.06);
              padding: 11px 15px;
              border-radius: 2px 0px 0px 2px;
              border-left: 4px solid var(--Danger-Primary, #EF4444);
              margin-top: 15px;
              .error-text{
                color: var(--Danger-Primary, #EF4444);
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0;
              }
            }
            .update-licenses{
              color: #000;
              font-size: 14px;
              font-weight: 500;
            }
            .link{
              color: var(--Brand-Main, #342BC2);
              font-size: 13px;
              font-weight: 400;
              text-decoration-line: underline;
              cursor: pointer;
            }
            .plan-main-container{
              display: flex;
              align-items: center;
              gap: 15px;
              justify-content: space-between;
              .arrow-icon-container{
                .arrow-icon{
                  width: 20px;
                  height: 20px;
                }
              }
              .plan-wrapper{
                border: 1px solid #e6e7e9;
                border-radius: 2px;
                background: #ffffff;
                padding: 12px;
                width: 220px;
                &.active-plan-wrapper{
                  border: 1px solid var(--Icons-Brand, #342BC2) !important;
                }
                &.active-plan-wrapper-opacity {
                  border: 1px solid #e6e7e9 !important;
                  
                  > * {
                    opacity: 0.5;
                  }
                }
                .plan-type{
                  font-size: 12px;
                  margin-bottom: 5px;
                  
                  color: var(--text-tertiary-color);
                }
                .plan-count{
                  font-size: 12px;
                  margin-bottom: 5px;
                  
                  color:#000000;
                }
                .plan-price{
                  margin-bottom: 5px;
                  .price{
                    .currency-symbol{
                      font-size: 12px;
                    }
                  font-size: 24px;
                  margin-bottom: 5px;
                  
                  color:#000000;
                  font-weight: 500;}
            
                }
                .duration{
                  font-size: 10px;
                  margin-bottom: 5px;
                  
                  color:#000000;
                }
              }
            }
            .payment-details {
              h5 {
                font-size: 14px;
                font-weight: 400;
                color: #000000;
              }
          
              .gateway-details {
                padding: 10px 0 0;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
          
                p {
                  margin-bottom: 0px;
                  font-size: 12px;
                  color: #000;
                  ;
                }
          
                span {
                  color: #9ca0a9;
                  font-size: 12px;
                }
          
                .apply-button {
                  top: auto !important;
          
                  img {
                    margin: -3px 0 0 2px;
                  }
                }
              }
            }
        }
        
        .helper-text {
          color: var(--text-tertiary-color);
          font-size: 16px;
          font-weight: 400;
        }
        .license-counter-div{
          padding: 10px 10px 26px 10px;
          display: flex;
          justify-content: end;
          height: fit-content;
          .license-counter{
            display: flex;
          border: 1px solid #E6E7E9;
          .add{
            padding: 10px 5px;
            cursor: pointer;
          }
          .minus{
            padding: 10px 5px ;
            border-right: 1px solid #E6E7E9;
            cursor: pointer;
            &.disabled{
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
          .count{
            border-right: 1px solid #E6E7E9;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 6px;
            width: fit-content;
            .count-input{
              border: none;
              outline: none;
              width: 50px;
              text-align: center;
              background-color: transparent;
              &:focus{
                border: none;
                outline: none;
              }
              &:focus-within{
                border: none !important;
                outline: none !important;
              }
            }
          }
        }
      }
      .error {
        display: flex;
        background-color: #fef5d9;
        border: 1px solid #fad151;
        color: #5e5e5e;
        padding: 10px 14px;
        font-weight: 400;
        font-size: 12px;
        align-items: flex-start;
         
      .how-this-works{
        color: var(--Warning-primary, #FF9E2A);
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
      }
    
        >i {
            display: inline-block;
            padding: 4px 7px 4px 5px;
        }
    
        >span {
            padding: 4px 8px 2px 1px;
        }
    
    }
    
      }

    .choose-plan {
      h4 {
        color: #000000;
        font-weight: 400;
        font-size: 18px;
      }



      .billings {
        display: flex;
        gap: 25px;
        padding: 25px 0 33px;
        align-items: center;

        h4 {
          color: #000000;
          font-size: 15px;
          font-weight: 400;
        }

        .amount {
          display: flex;
          align-items: baseline;
          gap: 5px;

          h5 {
            font-size: 32px;
            font-weight: 500;
            color: #000000;

            span {
              font-size: 16px;
            }
          }

          h6 {
            color: rgba(156, 160, 169, 1);
            font-size: 12px;
            font-weight: 400;
          }
        }

        .period-billing {
          border: 1px solid rgb(52, 43, 194);
          // padding: 15px 45px 10px 25px;
          padding: 20px 50px 6px 30px;
          border-radius: 2px;
          cursor: pointer;
          position: relative;

          .save-money {
            position: absolute;
            color: #ffffff;
            background-color: #342bc2;
            border-radius: 2px;
            font-weight: 500;
            font-size: 12px;
            padding: 4px 10px;
            display: inline-flex;
            gap: 3px;
            top: -14px;
            right: 12px;

            p {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .payment-separate-line {
      background-color: #e6e7e9;
      height: 1px;
      opacity: 1;
      margin: 1.2rem 0;
    }

    .steps-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      p {
        margin-bottom: 0;
        font-size: 14px;
      }

      span {
        color: rgba(156, 160, 169, 1);
        padding: 0px;
      }

      .step-one {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #000000;
      }

      .step-two {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgba(156, 160, 169, 1);
      }

      .step-three {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgba(156, 160, 169, 1);
      }
    }

    .billing-form {
      h4 {
        color: #000000;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 20px;

        &.sub-heading {
          padding-top: 20px;
        }
      }

      .form-group {
        margin-bottom: 15px;
        .empty-license-count-error{
          border-radius: 2px;
          background: rgba(239, 68, 68, 0.06);
          padding: 11px 15px;
          border-radius: 2px 0px 0px 2px;
          border-left: 4px solid var(--Danger-Primary, #EF4444);
          margin-bottom: 10px;
          .error-text{
            color: var(--Danger-Primary, #EF4444);
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 0;
          }
        }
      }

      .address {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        gap: 15px;
      }

      .apply-button {
        background-color: transparent;
        color: #342bc2;
        border: 1px solid transparent;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: 42px;

        &:hover {
          background-color: transparent;
          color: #342bc2;
          border: 1px solid transparent;
        }
      }

      .agreement {
        padding: 10px 0;

        label {
          color: rgba(156, 160, 169, 1);
          margin-left: 5px;
        }

        span {
          color: rgba(52, 43, 194, 1);
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .assign {
        padding: 10px 0 0;

        label {
          color: #000 !important;
          margin-left: 2px;
        }

        span {
          color: rgba(52, 43, 194, 1);
          text-decoration: underline;
          cursor: pointer;
        }

        .workspace-info-icon{
          cursor: pointer;
          margin-left: 6px;
          margin-bottom: 2px;
        }
      }
    }
  }

  .copyright {
    color: var(--text-tertiary-color);

    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
  }

  .team-info-wrapper {
    margin: auto;
    text-align: center;
    width: 500px;
    padding-bottom: 30px;


    .payment-separate-line {
      background-color: #e6e7e9;
      height: 1px;
      opacity: 1;
      margin: 1.2rem 0;
    }

    .steps-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      p {
        margin-bottom: 0;
        font-size: 14px;
      }

      span {
        color: rgba(156, 160, 169, 1);
        padding: 0px;
      }

      .step-one {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #000000;
      }

      .step-two {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgba(156, 160, 169, 1);
      }

      .step-three {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgba(156, 160, 169, 1);
      }
    }

    .billing-form {
      h4 {
        color: #000000;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 20px;

        &.sub-heading {
          padding-top: 20px;
        }
      }

      p {
        color: var(--text-tertiary-color);
        font-size: 14px;
        font-weight: 400;
      }

      .form-group {
        margin-bottom: 15px;
        text-align: left;

        .team-image-uploader {
          border: 1px solid var(--Grey-Line, #E6E7E9);
          padding: 16px;
          .upload-button{
            color: #342BC2;
            text-decoration: underline;
            cursor: pointer;
          }
          .image-text {
            margin-left: 40px;
            color: var(--text-tertiary-color);
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .address {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        gap: 15px;
      }

      .create-button {
        font-size: 14px;
        margin-top: 20px;
      }

      .agreement {
        padding: 10px 0;

        label {
          color: #000;
          margin-left: 5px;
        }

        span {
          color: rgba(52, 43, 194, 1);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .review-wrapper {
    text-align: left;
    background-color: rgba(52, 43, 194, 0.02);
    padding: 40px 40px 10px;
    position: fixed;
    width: 510px;
    background-image: url("../assests/icons/pattern-lines.svg");
    background-repeat: no-repeat;
    background-position-x: 129px;

    &.review-wrapper-disabled{
      opacity: 0.5;
      pointer-events: none;
    }

    .profile-summary {
      display: flex;
      align-items: center;
      gap: 25px;
    }

    .initials-wrapper {
      background-image: url("../assests/images/premium_circle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      padding: 6px;
      position: relative;

      // &::after {
      //   content: '';
      //   width: 18px;
      //   height: 18px;
      //   background-color: #060606;
      //   border: 1px solid #060606;
      //   position: absolute;
      //   font-size: 12px;
      //   border-radius: 50%;
      //   top: -5px;
      //   right: 4px;
      // }

      .plan-icon {
        width: 32%;
        position: absolute;
        top: -5px;
        right: 3px;
        z-index: 99;
      }
    }

    .initials-block {
      border: 1px solid #5d5fef;
      width: 60px;
      height: 60px;
      background: #5d5fef;
      border-radius: 50%;
      margin: 0px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }

    h3 {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
    }

    h5 {
      color: #000000;
      font-size: 18px;

      span {
        font-size: 10px;
      }
    }

    .summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #e3e8ef;
      border-radius: 2px;
      padding: 16px;

      .plan-duration {
        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 12px;
          color: #5e5e5e;
        }
      }
    }
    .effective-billing{
      background-color: #22946a0F;
      font-size: 14px;
      padding: 15px;
      border-radius: 2px;
      padding-left: 30px;
      border-left: 5px solid #22946B;
      margin-top: 15px;
    }

    .discount {
      padding: 15px 0 5px;

      .payment-separate-line {
        background-color: #e6e7e9;
        height: 1px;
        opacity: 1;
        margin: 1rem 0;
      }

      .discount-label {
        font-weight: 400;
        font-size: 16px !important;
        color: #000000;
      }

      .redeem-icon {
        background-image: url("../assests/icons/redeem.svg") !important;
        background-repeat: no-repeat !important;
        background-position: 15px !important;
        padding: 5px 85px 5px 50px !important;

        &.form-control:disabled{
          background-color: white;
          opacity: 0.7;
        }
      }

      

      .apply-button {
        background-color: transparent;
        color: #342bc2;
        border: 1px solid transparent;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: 50%;

        &:hover {
          background-color: transparent;
          color: #342bc2;
          border: 1px solid transparent;
        }
      }

      .discount-value {
        background-color: #dff5ef;
        color: #22946b;
        font-size: 10px;
        white-space: nowrap;
        padding: 3px 5px 1px;
        border-radius: 2px;
        position: absolute;
        top: 2px;
        margin-left: 8px;
      }

      h4 {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }

      .discount-summary {
        .deductions {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 0;
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-weight: 400;
            font-size: 16px;
            color: #5e5e5e;
          }

          &.tax-amount {
            padding-bottom: 10px;
          }
        }
      }

      .total {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 500;
          font-size: 18px;
          color: #000000;
        }
      }
    }

    .agreement {
      padding: 25px 0;

      label {
        color: #5e5e5e;
        margin-left: 5px;
      }

      span {
        color: rgba(52, 43, 194, 1);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .checkout {
      .prime-btn{
      &.disabled-checkout-btn{
        pointer-events: none !important;
        cursor: default !important;
        background: var(--Text---Light-mode-disabled, rgba(0, 0, 0, 0.25)) !important;
        border: none !important;
        opacity: 1;
        }
      }
      .onload-image {
        width: 25%;
        margin-top: -34px;
      }

      span.prefix {
        position: relative;

        &::before {
          content: url("../assests/icons/payment.svg");
          width: 100%;
          height: 100%;
          width: 20px;
          height: 20px;
          top: 0px;
          left: -22px;
          position: absolute;
        }
      }

      .taxes {
        font-weight: 400;
        font-size: 14px;
        color: #9ca0a9;
      }

      .stripe-info {
        font-weight: 400;
        font-size: 12px;
        color: #9ca0a9;
        text-align: center;
        padding-top: 10px;
        position: relative;
        margin-left: 17px;

        &::before {
          content: url("../assests/icons/secure.svg");
          top: 11px;
          position: absolute;
          left: -12px;
        }
      }
    }
  }
}

:root {
  --PhoneInput-color--focus: #e6e7e9 !important;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0em !important;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: 0.95em !important;
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: currentColor !important;
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em !important;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: #e6e7e9 !important;
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: #ffffff !important;
}

.PhoneInputInput {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #e6e7e9;
  background-color: #ffffff;
  padding: 5px 15px;
  height: 50px;
  color: #000000;
  outline: 0 !important;
  border-left: 1px solid #e6e7e9 !important;

  // &:hover {
  //   background: #ffffff;
  //   border-radius: 2px;
  //   border: 1px solid #e6e7e9;
  //   background-color: #ffffff;
  //   outline: 0 !important;
  // }

  &:focus-visible {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #e6e7e9;
    background-color: #ffffff;
    outline: 0 !important;
  }

  &:focus-within {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #e6e7e9;
    background-color: #ffffff;
  }
}

.PhoneInputCountry {
  border: 1px solid #e6e7e9;
  padding: 0px 15px;
  border-right: 1px solid transparent !important;
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 10px 5px !important;
  font-size: 16px;
  margin: 10px 5px !important;
  margin-bottom: 1px;
  cursor: pointer;
  padding: 0.5em;
  border-width: 0;
}

.status-wrapper {
  .payment-header {
    position: sticky;
    top: 0;
    padding: 10px 0px 15px 0px;
    background: #ffffff;
    z-index: 999;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -5px;

      span {
        text-align: left;
      }

      button.payment-close {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        height: 50px;
        font-size: 18px;
        color: #000000;

        &:hover {
          background-color: #ffffff;
          border: 1px solid #ffffff;
          color: #000000;
        }
      }
    }
  }

  .steps-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    p {
      margin-bottom: 0;
      font-size: 14px;
    }

    span {
      color: rgba(156, 160, 169, 1);
      padding: 0px;
    }

    .step-one {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #000000;
    }

    .step-two {
      display: flex;
      align-items: center;
      gap: 8px;
      color: rgba(156, 160, 169, 1);
    }

    .step-three {
      display: flex;
      align-items: center;
      gap: 8px;
      color: rgba(156, 160, 169, 1);
    }
  }

  .graphics-text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;

    .status-graphics {
      width: 50%;
      margin: auto;
    }

    .status-text {
      h2 {
        font-size: 2em;
        color: #000000;
      }

      p {
        font-size: 1em;
        color: #9ca0a9;
      }
    }

    .cta-block {
      color: #342bc2;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

.checkout-cancel {
  .modal-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p.cancel-question {
    color: rgba(239, 68, 68, 1);
    background-color: rgba(239, 68, 68, 0.05);
    padding: 20px;
    border-left: 3px solid rgba(239, 68, 68, 1);
  }

  .closes-button {
    margin-top: 0px;

    button {
      text-align: right;
      // font-size: 25px;
      width: 30px;
      min-width: 30px !important;
      height: auto !important;
      background-color: #ffffff !important;
      background: #ffffff !important;
      border: 1px solid #ffffff !important;
      color: #000000;
      font-weight: 400;
      padding: 0px;
      margin-top: 0px;

      &:hover {
        background-color: #ffffff !important;
        background: #ffffff !important;
        border: 1px solid #ffffff !important;
        color: #000000;
        font-weight: 400;
      }
    }
  }
}


.tip-note {
  color: var(--text-secondary-color);
  text-align: left !important;
  font-size: 12px;
  padding: 10px 15px 10px 48px;
  position: relative;
  margin: 25px 0 !important;

  strong {
    color: var(--text-primary-color);
    white-space: nowrap;
  }

  &.note {
    border: 1px solid var(--warning-orange);
    background-color: var(--warning-light-orange);

    &::before {
      content: "";
      background-image: url('../assests/icons/note.svg');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      min-width: 16px;
      min-height: 16px;
      position: absolute;
      top: 37%;
      left: 3.3%;
    }
  }
}

.before-price {
  color: var(--text-tertiary-color);
  text-decoration: line-through;
  font-size: 14px;

  &.popup {
    font-size: 12px;
  }
}

.payment-wrapper {
  &.add-credits-wrapper {
    .add-credits-heading {
      font-size: 24px !important;
      font-weight: 500 !important;
      margin-bottom: 10px !important;
      padding-top: 10px !important;
    }
    .add-credits-subheading {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    .target-accounts-container {
      margin-bottom: 15px;
      text-align: left;
      .target-account-first-box-container {
        padding: 16px 20px;
        border: 1px solid #E6E7E9;
        border-radius: 4px;
        margin-bottom: 8px;
        .target-accounts-details-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .target-account-details {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;
            .target-account-initials {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 36px;
              width: 36px;
              border-radius: 50%;
              background-color: #FF9E2A;
            }
            .target-account-name {
              font-size: 16px;
              font-weight: 400;
              color: #000000;
              max-width: 350px;

              display: -webkit-box;
              word-break: break-all;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .target-account-second-box-container {
        padding: 16px 20px;
        border: 1px solid #E6E7E9;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: var(--Grey-Line, #E6E7E9);
        }
        &::-webkit-scrollbar-track {
          background: transparent !important;
        }
        .target-accounts-details-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .target-account-details {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 14px;
            .target-account-initials {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 36px;
              width: 36px;
              border-radius: 50%;
              background-color: #FF9E2A;
            }
            .target-account-name {
              font-size: 16px;
              font-weight: 400;
              color: #000000;
              max-width: 350px;

              display: -webkit-box;
              word-break: break-all;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .credit-bundle-container {
      text-align: left;
      padding-top: 15px;
      .credit-bundle-label {
        margin-bottom: 10px;
      }
      .credit-bundle-row {
        display: flex;
        align-items: center;
        margin: auto;
        gap: 10px;
        margin-bottom: 10px;
        &.row-one {
          margin-bottom: 13px;
        }
        .credit-bundle-column {
          padding: 14px 15px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #E6E7E9;
          background-color: #ffffff;
          border-radius: 4px;
          cursor: pointer;
          gap: 28px;
          width: 32%;

          &.selected {
            border: 1px solid #342BC2 !important;
            background-color: #342BC20D !important;
          }
          .credit-amount {
            font-size: 14px;
            font-weight: 400;
            color: #5E5E5E;
            margin-bottom: 0;
            &.selected {
              color: #342BC2 !important;
            }
          }
          .credit-tick {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
}

.credit-system-info-wrapper {
  margin: auto;
  text-align: center;
  width: 500px;
  padding-bottom: 30px;
  .credits-how-this-works-container {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: var(--brand-light-color);
    padding: 16px 24px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: 30px;
    .credits-how-this-works-text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      span {
        color: var(--brand-color);
        cursor: pointer;
        a, a:hover {
          text-decoration: none;
          color: var(--brand-color);
        }
      } 
    }
  }
  .current-balance-section {
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    padding: 15px;
    gap: 10px;
    border-radius: 2px;
    margin-bottom: 10px;
    img {
      filter: grayscale(100%);
    }
    .current-balance-text {
      margin-bottom: 0;
      color: #979797;
    }
  }
  .credit-system-bundle-section {
    margin-bottom: 25px;
    .credit-bundle-row {
      --bs-gutter-x:10px;
      --bs-gutter-y:10px;
      // margin: auto;
      // gap: 10px;
      .credit-bundle-column {
        border: 2px solid #E6E7E9;
        padding: 14px 12px;
        // width: 32%;
        cursor: pointer;
        border-radius: 4px;
        &.selected {
          border: 2px solid #342BC2;
        }
        .bundle-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .credit-amount {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            color: #5E5E5E;
          }
        }
        .bundle-price {
          display: flex;
          align-items: center;
          gap: 9px;
          margin-top: 15px;
          margin-bottom: 5px;
          .bundle-price-text {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
          }
          .bundle-discount {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            padding: 2px 6px;
            border-radius: 1px;
            background-color: #22946B1A;
            .bundle-discount-text {
              font-size: 13px;
              font-weight: 400;
              margin-bottom: 0;
              color: #22946B;
            }
          }
        }
        .per-credit-price {
          font-size: 13px;
          font-weight: 400;
          color: #9CA0A9;
          margin-bottom: 0;
        }
      }
    }
  }
  .custom-credits-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF9F3;
    border: 1px solid #FF9E2A29;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
    .left-part {
      .custom-credits-header {
        display: flex;
        align-items: center;
        gap: 5px;
        .custom-credits-title {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .custom-credits-description {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 400;
        color: #5E5E5E;
        margin-top: 4px;
      }
    }
    .right-part {
      .custom-credits-contact {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        color: #342BC2;
        cursor: pointer;
      }
    }
  }
}

.request-for-credits-modal-container {
  .credit-selection-title {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500 !important;
    margin-top: 20px;
  }
  .request-for-credits-select {
    margin: 10px 20px;
  }
  .credits-request-button {
    width: fit-content !important;
    font-size: 12px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-color);
    color: var(--text-active-color);
    border: 1px solid var(--brand-color);
    gap: 4px;
    border-radius: 2px !important;
    height: 36px;
    margin: 24px 0 22px auto;
    font-weight: 400 !important;
    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }
  }
}

.credit-system-review-wrapper {
  .discount {
    margin-top: 15px;
  }
}

.add-credits-lazy-container {
  margin: auto;
  text-align: center;
  width: 500px;
  padding-bottom: 30px;
  .add-credits-lazy-header {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 60px;
    .add-credits-lazy-header-line {
      width: 75px;
      margin-bottom: 0;
      .shimmer-title-line {
        height: 23px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-circle {
      margin-bottom: 0;
      vertical-align: middle;
    }
  }
  .add-credits-lazy-body {
    .add-credits-lazy-body-line-one {
      margin-bottom: 10px;
      .shimmer-title-line {
        height: 28px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-two {
      margin-bottom: 25px;
      .shimmer-title-line {
        height: 42px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-three {
      width: 100px;
      margin-bottom: 8px;
      .shimmer-title-line {
        height: 19px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-four {
      width: 350px;
      margin-bottom: 14px;
      .shimmer-title-line {
        height: 17px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-five {
      margin-bottom: 8px;
      .shimmer-title-line {
        height: 68px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-six {
      margin-bottom: 25px;
      .shimmer-title-line {
        height: 196px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-seven {
      margin-bottom: 24px;
      .shimmer-title-line {
        height: 60px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-eight {
      .shimmer-title-line {
        height: 17px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-body-line-nine {
      width: 350px;
      .shimmer-title-line {
        height: 17px;
        border-radius: 6px;
      }
    }
    .add-credits-lazy-bundle-container {
      margin-bottom: 20px;
      .add-credits-lazy-bundle {
        display: flex;
        gap: 15px;
        .add-credits-lazy-body-line-ten {
          margin-bottom: 15px;
          width: 158px;
          .shimmer-title-line {
            height: 46px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

.credits-system-info-lazy-container {
  margin: auto;
  text-align: center;
  width: 500px;
  padding-bottom: 30px;
  .credits-system-info-lazy-header {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 60px;
    .credits-system-info-lazy-header-line {
      width: 75px;
      margin-bottom: 0;
      .shimmer-title-line {
        height: 23px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-circle {
      margin-bottom: 0;
      vertical-align: middle;
    }
  }
  .credits-system-info-lazy-body {
    .credits-system-info-lazy-body-line-one {
      margin-bottom: 10px;
      .shimmer-title-line {
        height: 28px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-two {
      margin-bottom: 30px;
      .shimmer-title-line {
        height: 70px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-three {
      width: 100px;
      margin-bottom: 8px;
      .shimmer-title-line {
        height: 19px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-four {
      margin-bottom: 32px;
      .shimmer-title-line {
        height: 17px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-five {
      margin-bottom: 30px;
      .shimmer-title-line {
        height: 47px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-six {
      margin-bottom: 25px;
      .shimmer-title-line {
        height: 196px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-seven {
      margin-bottom: 24px;
      .shimmer-title-line {
        height: 60px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-eight {
      .shimmer-title-line {
        height: 17px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-body-line-nine {
      margin-bottom: 20px;
      .shimmer-title-line {
        height: 20px;
        border-radius: 6px;
      }
    }
    .credits-system-info-lazy-bundle-container {
      margin-bottom: 10px;
      .credits-system-info-lazy-bundle {
        display: flex;
        gap: 15px;
        .credits-system-info-lazy-body-line-ten {
          margin-bottom: 15px;
          width: 158px;
          .shimmer-title-line {
            height: 110px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}