.sidebar-padding {
    padding: 0;
    height: 70vh;
}

.close-buttons {
    position: absolute;
    right: 10px;
    top: 10px;

    button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;

        &:hover {
            color: #000000;
            background-color: #e9ecef;
            border: 1px solid #e9ecef;
            font-weight: 900;
        }
    }
}

.transform-component-module_wrapper__1_Fgj {
    height: 485px !important;
}

.react-transform-wrapper,
.react-transform-component,
.transform-component-module_wrapper__1_Fgj,
.transform-component-module_content__2jYgh {
    height: unset !important;
}


.template-preview-wrapper {

    height: 100vh;

    .image-wrapper-preview {
        padding: 30px;
        display: flex;
        width: 100%;
        align-items: stretch;
        justify-content: center;
        order: -1;

        .fullscreen {
            display: flex;
            justify-content: center;
        }

        .toggle-btn {
            height: 25px;
            color: #060515;
            background-color: #ffffff;
            border: 1px solid #f6f6f6;
            padding: 2px 7px;
            line-height: 8px;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05), 0px 0px 5px rgba(0, 0, 0, 0.05);
            left: 320px;
            z-index: 999999;

            i {
                font-weight: 600;
                font-size: 15px;
            }
        }

        .sidebar-collapse-preview {
            min-width: 270px;
            max-width: 270px;
            min-height: 100vh;
            padding: 0 10px;
            transition: all 0.3s;
            background-color: #ffffff;
            z-index: 9999;
            position: fixed;
            left: 0;
            height: 100vh;
            z-index: 999;
            overflow: auto;

            &.active {
                margin-left: -270px;
            }

            .more {
                padding: 20px 5px;
                text-align: left;

                p {
                    margin-block-end: 0;
                    margin-block-start: auto;
                    padding: 5px 0 0;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    min-width: 20px;
                }

                .thumbnail {
                    border: 1px solid #e6e7e9;
                    display: block;
                    margin: 0 auto;
                    background: #f6f6f6;
                    padding: 20px;


                    &.active {
                        border: 1px solid #342bc2;
                    }
                }

                .row-pad {
                    padding-top: 5px;
                }
            }
        }

        .main-content {
            overflow: auto;
            width: 100%;
            background-color: #000000;
            max-height: 100%;
            transition: all 0.3s;
            display: flex;
            justify-content: center;

            .quick-access {
                position: fixed;
                bottom: 60px;
                padding: 10px 10px;
                background-color: #060515;
                // left: 40%;
                left: 50%;
                transform: translateX(-50%);
                color: #ffffff;

                span {
                    margin: 0 13px;
                    cursor: pointer;
                }
            }

            .preview-resize {
                object-fit: scale-down;
            }
        }
    }

    @media (max-width: 768px) {
        .sidebar-collapse-preview {
            margin-left: -320px;
        }

        .sidebar-collapse-preview.active {
            margin-left: 0;
        }
    }

    .image-wrapper {
        padding-top: 57px;
        background-color: #f6f6f6;

        .img-block {
            padding: 20px;
        }

        .sidebars {
            background: #ffffff !important;
            position: fixed;
            right: 0;
            height: 100%;
            overflow: overlay;
            order: -1;
        }

        .more {
            padding: 20px 5px;
            text-align: left;

            .file-name-input {
                background-color: #ffffff;
                border: 1px solid #ffffff;
                height: 25px;
                color: #000000;
                width: 50%;
                padding: 10px;
                border-radius: 2px;
                font-size: 14px;

                &:focus {
                    background-color: #ffffff !important;
                    border: 1px solid #ffffff !important;
                    color: #000000 !important;
                    outline: none !important;
                }
            }

            .thumbnail {
                border: 1px solid #e6e7e9;
                display: block;
                margin: 0 auto;
                background: #f6f6f6;
                padding: 20px;

            }

            .row-pad {
                padding-top: 5px;
            }
        }


    }

    .tools-block {
        position: absolute;
        padding: 20px;
        background-color: #060515;
        top: 85%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);

        .quick-access {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                margin: 0 15px;
            }
        }
    }
}

.main-margin {
    width: 100%;
    margin-left: 290px;
}

.template-sidebar {
    padding: 10px 15px 40px 15px;
    max-width: 280px;
    flex: 0 0 280px;
    order: -1;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    scrollbar-gutter: stable;
    background-color: #060515;
    color: #ffffff;
    height: 100%;
    text-align: left;
    z-index: 1001;

    .header-template {
        display: flex;
        align-items: center;
        margin-top: 15px;
        cursor: pointer;

        h4 {

            color: #ffffff;
            font-size: 14px;
            margin: 2px 0 0 8px;
            font-weight: 100;

        }
    }

    .logo-div {
        margin-top: 15px;
        text-align: center;
        max-width: 75px;
        background-color: #342bc2;
        // padding: 2px 8px 5px 8px;
        margin: 0px 8px 0 8px;
        border-radius: 2px;

        .tooltips-headers {
            margin-left: 10px;
        }

        .back-to-btns {
            height: 30px;
            width: 100%;
            font-size: 16px;
            background-color: #342bc2;
            border: 1px solid #342bc2;
            border-radius: 2px;
            font-weight: 900;
            padding: 0 10px;
        }

        .back-to-btn {
            height: 35px;
            min-width: 125px;
            font-size: 13px;
            background-color: #1f1e2c;
            border: 1px solid #1f1e2c;
            border-radius: 2px;
            color: rgba(255, 255, 255, 0.8);
        }
    }

    ul {
        padding-inline-start: 0px;

        a {
            text-decoration: none;
            color: #ffffff;
        }

        li {
            list-style-type: none;
            line-height: 55px;
            font-size: 14px;
            padding-left: 15px;
            cursor: pointer;
            width: 240px;
            color: rgba(255, 255, 255, 0.75);

            &:hover {
                background-color: rgba(255, 255, 255, 0.04);
            }

            &.active {
                background-color: #1f1e2c;
                color: #ffffff;
                position: relative;

                &::after {
                    content: "";
                    background-image: url("../assests/icons/selection-arrow-icon.svg");
                    display: inline-block;
                    background-repeat: no-repeat;
                    width: 8px;
                    height: 8px;
                    background-size: 8px;
                    transform: rotate(225deg);
                    position: absolute;
                    right: 15px;
                    top: 45%;
                    // content:url("../assests/icons/selection-arrow-icon.svg");
                    // text-align: right;
                    // color: #ffffff;
                    // font-family: FontAwesome;
                    // float: right;
                    // padding: 0 15px;
                    // font-size: 20px;

                    // content: "\f0da";
                    // text-align: right;
                    // color: #ffffff;
                    // font-family: FontAwesome;
                    // float: right;
                    // padding: 0 15px;
                    // font-size: 20px;
                }
            }
        }

        .menu-icon {
            padding: 0 15px 0px 0px;
            max-width: 18%;
        }

        margin-bottom: 30px;
    }

    h5 {
        font-size: 16px;
        padding: 3px 16px 10px;
    }

    .template-footer {
        margin-top: 30px;
        text-align: left;
        border: 1px solid #1f1e2c;
        background-color: #1f1e2c;

        padding: 8px 18px 18px 18px;
        width: 100%;
        border-radius: 4px;

        p {
            color: rgba(255, 255, 255, 1);
            max-width: 215px;
            margin: 0 auto;
            padding: 10px 0 15px 0;

            font-size: 11px;
            font-weight: 400;
            line-height: 18.15px;
        }

        .get-started-button {
            height: 34px !important;
            font-size: 12px !important;
            font-weight: 400;
            text-align: center;
            background-color: #FFBB00 !important;
            border: none !important;
            width: 100%;
            border-radius: 2px;
        }
    }
}

.polygon-temp {
    position: absolute;
    width: 20px;
    right: 146px;
    margin: -12px 0px;
    transform: rotate(43deg);
}

.grey-block-temp {
    position: absolute;
    width: 65px;
    height: 85px;
    margin: -48px 0px 0 -156px;
    z-index: 1;
}

.blue-curve-temp {
    position: absolute;
    width: 41.44px;
    margin: -27px -55px;
    z-index: 1;
}

.templates-section {
    padding: 0px 100px 0 100px;
    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000000;
    }

    p {
        color: #5e5e5e;
    }

    .templates-header {
        padding: 0px 0px 20px 0px;
        background: #ffffff;
        top: 0;

        h2 {
            color: #000;
            font-size: 24px;
            padding-top: 5px;
            word-break: break-word;
        }

        .templates-btn-block {
            text-align: right;

            button {
                height: 40px !important;
                border: 1px solid #e6e7e9 !important;

                &.border-none {
                    border-right: none !important;
                }
            }

            span {
                padding-left: 11px;
            }
        }
    }

    .search-templates {
        padding: 0 10px 0 10px;

        .css-qc6sy-singleValue {
            color: #9ca0a9;
            margin-right: 0px;
        }

        .css-319lph-ValueContainer {
            padding: 2px 0px 2px 8px;
        }

        .no-margin {
            padding: 0;
            margin: 0;
        }

        padding-bottom: 36px;

        .form-group>.input-style {
            background: none;
            border-radius: 2px;
            border-bottom: 1px solid #e6e7e9;
            background-color: none;
            height: 50px;
            border-left: none;
            border-right: none;
            border-top: none;
            font-family: 'FontAwesome', 'Rubik';

            &:focus-within {
                border: none !important;
                border-bottom: 1px solid #e6e7e9 !important;
            }
        }
    }

    .template-grid {

        .row-padding {
            padding-bottom: 20px;
        }

        .design-image {
            min-height: 260px;
            max-height: 260px;
            overflow: hidden;
            width: 100%;
            display: flex;
            margin: auto auto;

            img {
                display: flex;
                margin: auto auto;
            }

            .page-count {
                position: absolute;
                right: 10px;
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #ffffff;
                border: 1px solid transparent;
                background-color: rgba(62, 62, 62, 0.7);
                padding: 0px 7px;
                border-radius: 5px;
                top: 10px;

                img {
                    margin: -1px 6px 0 0;
                    max-width: 100% !important;
                    width: 10px;
                }
            }

            border: 1px solid #e6e7e9;
            padding: 25px;
            background-color: #f6f6f6;
            margin: auto auto;
            cursor: pointer;
            position: relative;

            &:hover {
                .hover-block {
                    display: block;
                }
            }

            img.dashboard-image {
                margin: auto auto;
                display: block;
            }
        }

        h4 {
            font-size: 14px;
            color: #000000;
            padding: 20px 0 0px 0;
            cursor: pointer;
            width: 250px;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
        }

        p {
            font-size: 12px;
            color: #9ca0a9;
            cursor: pointer;
        }
    }

    .templates-list {
        padding-bottom: 80px;

        span {
            padding-right: 0px;
        }

        .list-view {
            thead {
                border-bottom: 2px solid #e6e7e9;

                th {
                    padding: 0 0 20px 0;
                    color: #000000;
                    font-weight: 500;
                }
            }

            tbody {
                th {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;

                    span {
                        padding-right: 10px;

                        .design-image-icon {
                            display: block;
                            margin: auto auto;
                            cursor: pointer;
                        }

                        &.image-name {
                            padding: 5px;
                            background-color: #f6f6f6;
                            margin-right: 8px;
                            border: 1px solid #f6f6f6;
                            min-height: 40px;
                            max-height: 40px;
                            overflow: hidden;
                            width: 60px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    p.item-name {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 190px;
                        margin-top: 15px;
                        cursor: pointer;
                        color: #000 !important;
                    }

                    .flex-image {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                    }
                }

                td {
                    padding: 30px 0 25px 0;
                    color: #5d5d5d;
                    font-size: 14px;
                    cursor: default;
                }

                .dropdown-btn {
                    margin: -7px 0 0 0;
                }
            }
        }
    }
}

.template-info-block {
    text-align: left;
    padding: 0px 100px 0 100px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000000;
    }

    p {
        color: #5e5e5e;
    }

    .template-separate-line {
        background-color: #E6E7E9 !important;
        height: 1px;
        opacity: 1;
        margin-bottom: 0px !important;
    }

    &.detail {
        padding: 0px 10px 0 10px;

        .cover-image {
            margin-bottom: 30px;
        }

        .btn-blocks {
            padding-bottom: 20px;
            text-align: center;

            button {
                margin: 0 10px;
                background-color: #342bc2 !important;
                color: #ffffff !important;
                border: 2px solid #342bc2 !important;

                &:hover {
                    background-color: #272092 !important;
                    border: 2px solid #272092 !important;
                }
            }
        }
    }

    .row-padding {
        padding-bottom: 40px;
    }

    .col-padding {
        padding-right: 0;
    }

    .template-info-header {
        padding-bottom: 15px;
        background: #ffffff;
        // position: sticky;
        padding-top: 30px;
        top: 0px;

        .heading-content-separateLine {
            background-color: #E6E7E9;
            height: 1.5px;
            opacity: 1;
            margin-bottom: 0px !important;
        }

        .nav-template {
            span {
                font-size: 12px;
            }
        }

        .back-btn {
            background: #f6f6f6 !important;
            font-size: 13px !important;
            height: 40px !important;

            &::before {
                font-family: 'FontAwesome', 'Rubik';
                content: '\f104';
                font-size: 13px;
                color: #000;
            }
        }

        h2 {
            padding: 30px 0 0;
            font-size: 24px;
        }

        p {
            font-size: 13px;
            color: #9ca0a9;
            padding: 0px 0 0;
        }

        .info-btn-block {
            text-align: right;
            padding: 33px 0 0;

            &.edit {
                button {
                    min-width: 12% !important;
                }
            }

            button {
                min-width: 35% !important;
                font-size: 14px;
            }

            .live-demo {
                border-radius: 2px;
                border: solid 1px #342bc2;
                background-color: rgba(52, 43, 194, 0.08);
                color: #342bc2;
                height: 50px;

                &:hover {
                    border: 1px solid #342bc2;
                    background-color: #e3e2ea;
                }
            }

            .new-soon {
                padding-left: 5px;
                pointer-events: none;

                img {
                    opacity: 0.6 !important;
                }

                span {
                    opacity: 0.6 !important;
                    padding-left: 0px;
                }

                label {
                    color: #9ca0a9;
                    padding-left: 5px;

                    &::after {
                        content: 'Soon';
                        background-color: #342bc2;
                        border: 1px solid #342bc2;
                        padding: 2px 8px;
                        color: #ffffff;
                        border-radius: 10px;
                        margin-left: 0px;
                        font-size: 8px;
                    }

                }

            }

            .new-live-demo {
                background-color: #ffffff;
                border: none;
                color: #000000;
                font-size: 14px;
                padding: 0 11px 0 6px;
                border: 1px solid #E6E7E9;
                border-radius: 2px;

                span {
                    padding-left: 0px;
                }





                img {
                    width: 35px;
                    margin-right: 2px;
                }

                &:hover {
                    background-color: #ffffff;
                    border: 1px solid #E6E7E9;
                    border-radius: 2px;
                }
            }

            .aa {
                padding: 0px;
            }

            span:not(.crown-icon-wrapper) {
                padding-left: 10px;
            }
        }
    }

    .cover-image {
        margin-bottom: 40px;
        position: relative;
        border: 1px solid #e6e7e9;
        background-color: #f6f6f6;
        display: flex;
        justify-content: center;
        min-height: 485px;
        max-height: 485px;
        overflow: hidden;

        .fullscreen-enabled {
            display: flex !important;
            justify-content: center;
            align-items: center;

            img {
                max-height: 100% !important;
            }
        }

        img {
            display: block;
            margin: 0 auto;
            position: relative;
            pointer-events: auto;

            &:hover {
                cursor: grab;
            }
        }

        &:hover {
            .slider-btns {
                display: block;
            }
        }

        .slider-btns {
            display: none;
            z-index: 9;

            .previous {
                background-color: #060515;
                color: #ffffff;
                border: 1px solid #060515;
                border-radius: 2px;
                position: absolute;
                top: 50%;
                left: 29px;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }

            .next {
                background-color: #060515;
                color: #ffffff;
                border: 1px solid #060515;
                border-radius: 2px;
                position: absolute;
                top: 50%;
                right: -3px;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }
        }

        .fullscreen-btn {
            background-color: #060515;
            color: #ffffff;
            border: 1px solid #060515;
            height: 35px;
            width: 35px;
            right: 15px;
            top: 20px;
            position: absolute;
            text-align: center;
            border-radius: 2px;
            padding: 7px 5px 20px;
            z-index: 9;

            img {
                max-width: 500% !important;
            }
        }

        .zoom {
            background-color: #060515;
            color: #ffffff;
            border: 1px solid #060515;
            height: 35px;
            width: 35px;
            text-align: center;
            border-radius: 2px;
            padding: 5px 5px 40px;
            font-size: 20px;
            z-index: 9;

            &.in {
                position: absolute;
                right: 15px;
                bottom: 50px;
            }

            &.out {
                position: absolute;
                right: 15px;
                bottom: 20px;
                padding: 0px 5px 30px !important;
                font-size: 24px !important;
            }
        }
    }

    .tags-comp {
        margin-top: 30px;
    }

    .tags {
        text-align: center;
        padding: 20px 0 10px;

        h5 {
            font-size: 18px;
            padding-top: 6px;
            padding-bottom: 12px;
        }

        span {
            border: 1px solid #e6e7e9;
            border-radius: 14.5px;
            font-size: 14px;
            font-weight: 400;
            padding: 7px 15px;
            color: #5e5e5e;
            display: inline-block;
            margin: 5px;
            cursor: pointer;
        }
    }

    .template-blog {

        // width: 90%;
        // h3 {
        //     color: var(--text-primary-color);
        //     font-size: 24px;
        //     margin-bottom: 2rem;
        // }
        .content-grey-out {
            position: relative;
        }

        .grey-bottom {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.27) 0%, #FFFFFF 100%);
            height: 120px;
            position: absolute;
            width: 100%;
            bottom: 0px;
        }

        button {
            height: 40px !important;
            font-size: 14px !important;
            background-color: var(--background-color) !important;
            background: var(--background-color) !important;
            width: 100%;
            margin-bottom: 40px;

            &:hover {
                background-color: #ebeaea !important;
                border: 1px solid var(--border-color);
            }

            span {
                position: relative;
            }

            i {
                color: var(--icon-color);
                font-size: 10px;
                padding: 0 5px 0 5px;
                position: absolute;
                top: 30%;
            }
        }

        .no-blog-present {
            padding-bottom: 20px;

            .gif-block {
                border: 1px solid var(--border-color);
                padding: 0px 35px 45px;
                text-align: center;
                margin-top: 30px;

                img {
                    width: 25%;
                }

                h4 {
                    color: var(--text-primary-color);
                    font-size: 18px;
                }

                p {
                    color: var(--text-tertiary-color);
                    font-size: 14px;
                    margin-top: 15px;
                    max-width: 500px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    .more-templates {
        padding-bottom: 40px;
        padding-top: 2rem;

        .template-grid {
            .row-padding {
                padding-bottom: 20px;
            }

            .design-image {
                min-height: 260px;
                max-height: 260px;
                overflow: hidden;
                width: 100%;
                display: flex;
                margin: auto auto;

                img {
                    display: flex;
                    margin: auto auto;
                }

                .page-count {
                    position: absolute;
                    right: 10px;
                    display: flex;
                    align-items: center;
                    font-size: 11px;
                    color: #ffffff;
                    border: 1px solid #5d5d5d;
                    background-color: #5d5d5d;
                    opacity: 0.8;
                    padding: 0px 7px;
                    border-radius: 5px;
                    top: 10px;

                    img {
                        margin: -1px 6px 0 0;
                        max-width: 100% !important;
                    }
                }

                border: 1px solid #e6e7e9;
                padding: 25px;
                background-color: #f6f6f6;
                // margin: 0 auto;
                cursor: pointer;
                position: relative;

                &:hover {
                    .hover-block {
                        display: block;
                    }
                }

                img.dashboard-image {
                    margin: auto auto;
                    display: block;
                }
            }

            h4 {
                font-size: 14px;
                color: #000000;
                padding: 20px 0 0px 0;
                cursor: pointer;
            }

            p {
                font-size: 12px;
                color: #9ca0a9;
                cursor: pointer;
            }
        }

        h3 {
            font-size: 20px;
            margin-bottom: 50px;

            span {
                float: right;
                margin-right: 10px;

                button {
                    background: #f6f6f6;
                }
            }
        }

        .design-image {
            border: 1px solid #e6e7e9;
            padding: 20px;
            background-color: #f6f6f6;
            cursor: pointer;

            img {
                margin: 0 auto;
                display: block;
            }
        }

        h4 {
            font-size: 16px;
            color: #000000;
            padding: 20px 0 0px 0;
            cursor: pointer;
        }

        p {
            font-size: 12px;
            color: #9ca0a9;
        }
    }

    .create-new {
        border: 1px solid #060515;
        text-align: center;
        background-color: #060515;
        color: #ffffff;
        padding: 20px 10px;

        h6 {
            font-size: 14px;
            max-width: 160px;
            margin: 0 auto;
            padding-bottom: 15px;
        }

        p {
            font-size: 12px;
            max-width: 200px;
            margin: 0 auto;
            color: rgba(255, 255, 255, 0.8);
            padding-bottom: 15px;
        }

        button {
            width: 60% !important;
        }
    }

}

.tab-debugRenderMode {
    display: block !important;
}

.view-all {
    height: 40px !important;
    font-size: 14px !important;
    padding: 0 15px;
    background: #f6f6f6 !important;
}

.blog-content {
    // padding-bottom: 40px;
    margin: 0 auto;

    a {
        color: #342BC2;

        &:hover {
            color: #272092;
        }
    }

    p {

        text-align: justify;
        font-size: 16px;
        color: #5e5e5e;
        line-height: 1.50;
        padding: 3px 0px 3px 0px;


    }

    ul {
        padding-inline-start: 30px;
        margin-bottom: 5px;

        li {
            text-align: justify;
            list-style-type: none;
            position: relative;
            font-size: 16px;
            color: #5e5e5e;
            line-height: 1.75;
            padding-bottom: 10px;

            p {
                padding: 0 0 0 0;
            }

            &::before {
                content: '';
                background-image: url('../assests/images/blue-tick.svg');
                width: 25px;
                height: 25px;
                display: inline-block;
                position: absolute;
                background-repeat: no-repeat;
                top: 7px;
                left: -25px;
            }
        }
    }

    ol {
        padding-inline-start: 40px;
        margin-bottom: 5px;

        li {
            text-align: justify;
            font-size: 16px;
            list-style: auto;
            color: #5e5e5e;
            line-height: 1.50;
            padding-bottom: 5px;
            padding-left: 5px;

            p {
                padding: 0 0 0 0;
            }
        }
    }

    h2 {

        font-size: 20px;
        font-weight: 500;
        color: #000000;
        padding: 10px 0;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        padding: 10px 0;
    }

    h4 {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        padding: 10px 0;
    }

    p,
    h1,
    h2,
    h3 {
        img {
            display: block;
            padding: 20px;
            text-align: center;
            width: 100%;
            height: auto;
            // margin: 20px auto;
            background-color: #f2f2f2;
        }
    }

    figure {
        padding: 0 0px;
    }

    table {
        margin: 0 auto;
        width: 100% !important;
        border: 1px solid #e6e7e9;
        margin-bottom: 20px;

        thead {
            background-color: #f6f6f6;

            th {
                text-align: center;
                border: solid 1px #342bc2;
                background-color: rgba(52, 43, 194, 0.08);
                font-size: 16px;
                padding: 5px;
            }
        }

        tbody {

            // tr:first-child{
            //     border: solid 1px #342bc2;
            //     td {
            //       text-align: center;
            //       border: solid 1px #342bc2;
            //       background-color: rgba(52, 43, 194, 0.08);
            //       font-size: 16px;
            //       padding: 5px;
            //       font-weight: 500;
            //       color: #000000;
            //       strong {
            //         color: #000000;
            //       }
            //       p {
            //         color: #000000;
            //         font-size: 14px;
            //         font-weight: 300;
            //       }
            //     }
            //   }
            tr {
                border: solid 1px #342bc2;

                td {
                    border: solid 1px #342bc2;
                    font-size: 14px;
                    padding: 8px;
                    font-weight: 300;

                    p {
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

div.log-window-appender {
    display: none !important;
}

.responsive-iframe {
    width: 100%;
}

.hover-block {
    background: #000000a1;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    display: none;

    button {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        margin: 0 10px;
        background-color: #f6f6f6 !important;
        border: 1px solid #e6e7e9 !important;
        height: 40px !important;
        width: 10%;
        border-radius: 0px;

        &:hover {
            background-color: #342bc2 !important;
            border: 1px solid #342bc2 !important;
        }
    }
}


body.modal-open {
    overflow: hidden;
}


.preview-modal {
    padding: 10px 5px 0px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-size: 18px;
            margin: 0;
        }

        .closes-button {
            button {
                height: 30px !important;
                text-align: center;
                font-size: 11px;
                border-radius: 2px;
                color: #ffffff;
                background-color: #000000;
                border: 1px solid #000000;

                &:hover {
                    color: #000000;
                    background-color: #e9ecef;
                    border: 1px solid #e9ecef;
                    font-weight: 900;
                }
            }
        }
    }

    hr {
        color: #e6e7e9;
        margin: 15px 0 15px;
    }

    .content {

        span {
            font-size: 14px;
            color: #B9B9B9;
        }

        .options {
            padding: 15px 0 0px;
            padding: 10px;
            border: 1px solid #f6f6f6;
            margin: 0 0;

            label {
                font-size: 16px;
                color: #5e5e5e;
            }

            span.label {
                padding: 0 10px 0 0;

                img {
                    width: 6%;
                    margin-top: -3px;
                }
            }

            &.tab {
                margin-top: 15px;

                span.label {
                    padding: 0 13px 0 0;

                    img {
                        width: 5% !important;
                    }
                }
            }

            .option-btn {
                text-align: right;

                img {
                    width: 12%;
                }
            }
        }
    }

    .active-option {
        background-color: #E7E6F9;
        border: 1px solid #342bc2 !important;
        font-weight: 400;
    }

    .btns-blocks {
        padding-top: 25px;

        button {
            height: 45px !important;
        }

        p {
            padding: 15px 0 0;
            color: #B9B9B9;
            font-size: 12px;
            text-align: center;
        }
    }
}

.soon {
    pointer-events: none;

    label {
        color: #9ca0a9;

        &::after {
            content: 'Soon';
            background-color: #342bc2;
            border: 1px solid #342bc2;
            // width: 10px;
            padding: 3px 10px;
            color: #ffffff;
            border-radius: 10px;
            margin-left: 10px;
            font-size: 12px;
        }
    }


}

.tab-soon {
    pointer-events: none;
    display: flex;
    align-items: center;

    label {
        color: #9ca0a9;
        margin-bottom: 4px;

        &::after {
            content: 'Soon';
            background-color: #342bc2;
            border: 1px solid #342bc2;
            // width: 10px;
            position: relative;
            right: 9px;
            padding: 3px 10px;
            color: #ffffff;
            border-radius: 10px;
            font-size: 8px;

        }
    }


}


.template-wrapper {
    display: flex;

    .sidebar-width {
        flex-basis: 270px;
    }

    .template-component {
        flex-basis: calc(100% - 270px);

        .template-info-lazy-container {

            padding: 0 100px;
            padding-top: 30px !important;

            text-align: left !important;

            .shimmer-thumbnail,
            .shimmer-button {
                margin-bottom: 0 !important;

                min-width: 40px !important;
            }

            .template-info-lazy-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 46px;
                padding-bottom: 31px;

                .template-info-lazy-header-right {
                    display: flex;
                    gap: 10px;

                    .shimmer-button--sm {
                        height: 50px !important;
                        width: 110px !important;
                    }
                }
            }
        }




    }
}

.buttons-image {
    color: #342bc2;
    border: 1px solid #342bc2;
    background-color: #ffffff;
    border-radius: 2px;
}

.p-20,
.shimmer-card {
    padding: 0 !important;
    box-shadow: none !important;
    margin: auto;
    margin-top: 0 !important;
}

.reducedSizeThumbnail_lazy {
    width: 98% !important;
    margin: auto;
}

.lazyDesign_tableHeading {
    margin-top: -10px;

    .shimmer-title-line {
        height: 45px;
        border-radius: 6px !important;
    }

}

.lazyDesign_tableRows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;

    .shimmer-thumbnail {
        display: block !important;
    }


}

.lazyDesign_tableCol1 {
    min-width: 60px !important;
}

.lazyDesign_tableCol2 {
    width: 20%;
    margin-left: -90px;
    margin-right: -10px;

    .shimmer-title-line {

        height: 20px;
        border-radius: 6px !important;


    }
}


.lazyDesign_tableCol3 {

    min-width: 30px !important;
}

.lazyDesign_tableCol4 {
    width: 10%;

    .shimmer-title-line {

        height: 20px;
        border-radius: 6px !important;
        margin-left: -18px !important;


    }
}

.lazyDesign_tableCol5 {
    width: 10%;

    .shimmer-title-line {

        height: 20px;
        border-radius: 6px !important;
        margin-left: -40px !important;


    }
}

.lazyDesign_tableCol6 {
    min-width: 30px !important;
    margin-left: -50px !important;
}

.lazyTemplate_tableHeading {
    margin-top: -10px;

    .shimmer-title-line {
        height: 45px;
        border-radius: 6px !important;
    }

}

.lazyTemplate_tableRows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;

    .shimmer-thumbnail {
        display: block !important;
    }


}

.lazyTemplate_tableCol1 {
    min-width: 60px !important;


}

.lazyTemplate_tableCol2 {
    width: 20%;
    margin-left: -190px;
    margin-right: -10px;

    .shimmer-title-line {

        height: 20px;
        border-radius: 6px !important;


    }
}


.lazyTemplate_tableCol3 {

    min-width: 30px !important;
    margin-left: -70px !important;
}

.lazyTemplate_tableCol4 {
    min-width: 30px !important;
    margin-left: -80px;
}

.lazyTemplate_tableCol5 {
    min-width: 30px !important;
    margin-left: -70px !important;
}


.lazyTrash_tableHeading {
    margin-top: -10px;

    .shimmer-title-line {
        height: 45px;
        border-radius: 6px !important;
    }

}

.lazyTrash_tableRows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 26px;

    .shimmer-thumbnail {
        display: block !important;
    }
}

.lazyTrash_tableCol1 {
    min-width: 60px !important;
}

.lazyTrash_tableCol2 {
    width: 20%;
    margin-left: -180px;
    margin-right: -10px;

    .shimmer-title-line {
        height: 20px;
        border-radius: 6px !important;
    }
}


.lazyTrash_tableCol3 {

    min-width: 70px !important;
    margin-left: -60px !important;
}

.lazyTrash_tableCol4 {
    min-width: 70px !important;
    margin-left: -50px !important;
}

.lazyTrash_tableCol5 {
    min-width: 30px !important;
    margin-left: -60px !important;
}

.overlay-blog {
    bottom: 0;
    opacity: 1;
    max-width: 100%;
    margin: 5rem auto 0;
    transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;

    .blog-overlay-lazy-container {
        padding-top: 0 !important;
        max-height: calc(99.7vh - 80px);
        overflow: auto;
        height: 100vh;
        width: 100%;
        margin: 0 auto;

        .shimmer-thumbnail,
        .shimmer-button {
            margin-bottom: 0 !important;
            height: 100% !important;
            min-width: 55px !important;
        }

        .blog-overlay-lazy-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 0;

            .blog-overlay-lazy-header-left {
                display: flex;
                gap: 20px;

                .blog-overlay-lazy-header-title-subtitle {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 5px;
                }
            }

            .blog-overlay-lazy-header-right {
                display: flex;
                gap: 20px;

                .shimmer-button--lg {
                    height: 45px !important;
                    width: 150px !important;
                }

            }
        }
    }

    .content-blog {
        padding: 20px 120px;
        padding-top: 0 !important;
        max-height: calc(99.7vh - 80px);
        overflow: auto;

        .cover-image {
            margin-bottom: 40px;
            position: relative;
            border: 1px solid #e6e7e9;
            background-color: #f6f6f6;
            padding: 60px;
            display: flex;
            justify-content: center;
            max-width: 1030px;
            margin: 0 auto 40px auto;

            img {
                display: block;
                margin: 0 auto;
                position: relative;
            }

            &:hover {
                .slider-btns {
                    display: block;
                }
            }

            .slider-btns {
                display: none;

                .previous {
                    background-color: #060515;
                    color: #ffffff;
                    border: 1px solid #060515;
                    border-radius: 2px;
                    position: absolute;
                    top: 50%;
                    left: 29px;
                    -moz-transform: translateX(-50%) translateY(-50%);
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                }

                .next {
                    background-color: #060515;
                    color: #ffffff;
                    border: 1px solid #060515;
                    border-radius: 2px;
                    position: absolute;
                    top: 50%;
                    right: -3px;
                    -moz-transform: translateX(-50%) translateY(-50%);
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .fullscreen-btn {
                background-color: #060515;
                color: #ffffff;
                border: 1px solid #060515;
                height: 35px;
                width: 35px;
                right: 15px;
                top: 20px;
                position: absolute;
                text-align: center;
                border-radius: 2px;
                padding: 7px 5px 20px;
                z-index: 9;

                img {
                    max-width: 500% !important;
                }
            }

            .zoom {
                background-color: #060515;
                color: #ffffff;
                border: 1px solid #060515;
                height: 35px;
                width: 35px;
                text-align: center;
                border-radius: 2px;
                padding: 5px 5px 40px;
                font-size: 20px;
                z-index: 9;

                &.in {
                    position: absolute;
                    right: 15px;
                    bottom: 50px;
                }

                &.out {
                    position: absolute;
                    right: 15px;
                    bottom: 20px;
                    padding: 0px 5px 30px !important;
                    font-size: 24px !important;
                }
            }
        }

        .heading-section {
            padding: 25px 0px 25px 0px;
            max-width: 825px;
            margin: 0 auto;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .read-more-use-template-btn {
                display: flex;
                gap: 20px;

                .read-more-blog-btn {
                    border-radius: 2px;
                    border: solid 1px #342bc2;
                    background-color: rgba(52, 43, 194, 0.08);
                    color: #342bc2;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        border: 1px solid #342bc2;
                        background-color: #e3e2ea;
                    }
                }

                .overlay-use-template-btn {
                    height: 45px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .btn-margin {

                margin-right: -175px;
                border-radius: 2px;
                border: solid 1px #342bc2;
                background-color: rgba(52, 43, 194, 0.08);
                color: #342bc2;
                height: 50px;

                &:hover {
                    border: 1px solid #342bc2;
                    background-color: #e3e2ea;
                }
            }

            .btn-width {
                width: 160px;

            }

            img {
                padding-right: 7px;
            }

            .category-heading {
                display: flex;
                gap: 15px;
                align-items: center;

                .icon-div-cat {
                    background-color: #EBEAF9;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 2px !important;

                    img {
                        width: 100%;
                        padding: 0 !important;
                    }
                }
            }

            .heading-subhead {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .subhead {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .info-icon {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        span {
                            color: #a6a6a6;
                        }
                    }

                    p {
                        padding: 0;
                        font-size: 14px;
                        margin-left: 0;
                        margin: 0;
                    }

                }
            }

            h2 {
                flex-grow: 0;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                color: #000000;
                max-width: 660px;
                margin: 0 auto;
                margin-left: 0px !important;
            }

            p {
                flex-grow: 0;
                font-size: 16px;
                line-height: 1.4;
                text-align: center;
                color: #9ca0a9;
                padding-bottom: 30px;
                margin: 0 auto;
                max-width: 845px;
            }

            button {
                border: none;
                background-color: #342BC2;
                color: #ffffff;
                border-radius: 2px;
                font-size: 14px !important;
                padding-left: 20px;
                padding-right: 20px;
                min-width: 20% !important;
            }
        }

        .heading-content-separateLine {
            max-width: 825px;

            margin: 0 auto;
            background-color: #E6E7E9;
            height: 1.5px;
            opacity: 1;
            margin-bottom: 25px;
        }
    }



    .blog-content {
        max-width: 825px;
    }

    .closes-button.overlay {
        position: absolute;
        top: -40px;
        right: 0px;

        button {
            background-color: transparent;
            border: 1px solid transparent;
            font-size: 24px;
            line-height: 23px;
        }
    }

}

.blog-info {
    width: 90%;
}

.readBlog-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #EFEEFA;
    padding: 1.5rem;


    .readBlog-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 45%;
        margin-bottom: 16px;

        .text-1 {
            font-size: 10px;
            margin: 0;
            opacity: 0.7;
        }

        .text-2 {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            line-height: 25px;
            margin-bottom: 5px;
            text-align: left;
        }

        .text-3 {
            font-size: 12px;
            margin: 0;
            line-height: 25px;
            opacity: 0.7;
        }

        .text-4 {
            text-align: left;

            span {
                color: #342BC2;
                text-decoration: none;
                font-size: 14px;
                border: 1px solid #342BC2;
                padding: 10px;
                border-radius: 2px;
            }


            margin: 20px 0 0 0;
        }
    }

    .readBlog-img {
        width: 300px;
    }

}


.animate-bottom {
    position: relative;
    animation: animatebottom 1.2s;
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0;
    }

    to {
        bottom: 0;
        opacity: 1;
    }
}

.live-links {
    .shimmer-title--secondary {
        display: none !important;
    }

    .shimmer.shimmer-thumbnail {
        height: 500px !important;
    }
}

.pro-use {
    background-color: rgba(255, 174, 0, 1) !important;
    border: 1px solid rgba(255, 174, 0, 1) !important;
}

.white-crown-use-template {
    width: 24px;
    padding: 0 7px 5px 0px;
}


.request-tempale-banner-section {
    margin: 20px 0px 50px;
    border-radius: 2px;

    .request-banner {
        position: relative;
        background-color: rgba(255, 158, 42, 0.07);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-content {
            text-align: left;
            max-width: 66%;
            padding: 30px;

            h5 {
                font-weight: 500;
                font-size: 14px;
                color: var(--text-primary-color);
            }

            p {
                font-size: 12px;
                color: var(--text-secondary-color);
                max-width: 98%;

            }

            button {
                font-size: 12px;
                border-radius: 2px;
                padding: 8px 28px;
                color: var(--text-active-color);
                background-color: var(--warning-orange);
                border-color: var(--warning-orange);

                &:hover {
                    background-color: #F58500;
                }
            }

        }

        .right-illustration {
            position: absolute;
            right: 24px;

            img {}
        }
    }
}

.template-request-form-popup {
    .themes-popup {
        .header {
            margin-bottom: 8px;
            align-items: flex-end;

            h4 {
                font-size: 18px;
                margin-bottom: 0px;
            }

            .closes-button {
                text-align: right;
            }
        }

        .content {
            max-width: 75%;

            h5 {
                padding: 0;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .form {
            .submit-btn {
                text-align: right;

                button {
                    min-width: 24% !important;
                }
            }
        }

        .fields {
            margin: 20px 0px;

            .name-category {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                .template-name {
                    width: 60%;

                }

                .category {
                    width: 40%;

                }
            }

            .form-group {
                .css-14el2xx-placeholder {
                    color: var(--text-tertiary-color);
                    // font-size: 12px;
                    opacity: 1 !important;
                }

                .css-16841bg-control {

                    // letter-spacing: 0.25px;
                    .css-319lph-ValueContainer {

                        // letter-spacing: 0.25px;
                        .css-qc6sy-singleValue {
                            // font-size: 14px !important;
                        }
                    }
                }

                .css-9t8w1h-control {
                    .css-319lph-ValueContainer {

                        // letter-spacing: 0.25px;
                        .css-qc6sy-singleValue {
                            font-size: 14px !important;
                        }
                    }
                }

                .css-7bc5xa-control {
                    // letter-spacing: 0.25px;
                }

                input {
                    font-size: 14px !important;
                    color: var(--text-primary-color);

                    // letter-spacing: 0.25px;
                    &::placeholder {
                        color: var(--text-tertiary-color);
                        font-size: unset;
                        opacity: 1 !important;
                    }
                }

                textarea {
                    max-height: 100px;
                    border-radius: 2px;
                    font-size: 14px !important;
                    padding: 14px 16px;
                    // letter-spacing: 0.25px;
                    color: var(--text-primary-color);

                    &::placeholder {
                        color: var(--text-tertiary-color) !important;
                    }

                    &:focus {
                        color: var(--text-primary-color);
                        border: 1px solid var(--border-color);
                        outline: 0;
                        box-shadow: none;
                    }
                }

                label {
                    font-size: 14px;
                    padding-bottom: 5px;
                }
            }

            .text-area {
                margin-top: 14px;
            }

        }
    }
}