.restored-deleted-workspace-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mokkup-logo-container {
        margin-bottom: 25px;

        .mokkup-logo {
            height: 40px;
            width: auto;
        }
    }

    .restored-deleted-workspace-title {
        color: var(--Text---Light-mode-primary, #000);
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .restored-deleted-workspace-subtitle {
        color: var(--Text---Light-mode-tertiary, #9CA0A9);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 35px;
    }

    .restored-deleted-workspace-details-container {
        border: 1px solid var(--Grey-Line, #E6E7E9);
        padding: 25px;
        margin-bottom: 35px;

        .restored-deleted-workspace-credentials {
            color: var(--text-blue-dark-surface-primary, #FFF);
            font-size: 32px;
            font-weight: 400;
            width: 80px;
            height: 80px;
            background-color: goldenrod;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }
        .workspace-restore-img {
            width: 80px;
            height: 80px;
            margin-bottom: 20px;
            border-radius: 50%;
        }

        .restored-deleted-workspace-name {
            color: var(--Text---Light-mode-primary, #000);
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 4px;
        }

        .restored-deleted-workspace-details {
            color: var(--Text---Light-mode-tertiary, #9CA0A9);
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 25px;
        }

        .restored-deleted-workspace-click-message {
            color: var(--Text---Light-mode-secondary, #5E5E5E);
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 15px;
        }

        .restored-deleted-workspace-button {
            border-radius: 2px;
            border: 1px solid var(--Brand-Main, #342BC2);
            background: var(--Brand-Light, rgba(52, 43, 194, 0.05));
            color: #000;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            height: 48px;
            width: 100%;
            margin-bottom: 8px;
        }

        .restored-deleted-workspace-error-message {
            float: left;
            margin-right: auto;
            text-align: left;
            font-family: Rubik;
            font-size: 12px;
            font-weight: 400;
            color: #EF4444;
            margin-bottom: 10px;
        }

        .restored-deleted-workspace-timer-message {
            margin-top: 5px;

            .message-one {
                color: var(--Text---Light-mode-secondary, #5E5E5E);
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                margin-bottom: 5px;
            }

            .message-two {
                color: var(--Text---Light-mode-tertiary, #9CA0A9);
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }

    .workspace-footer-separator {
        width: 640px;
        height: 1px;
        opacity: 0.1;
    }

    .restored-deleted-workspace-footer {
        color: var(--Text---Light-mode-tertiary, #9CA0A9);
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.workspace-requests-wrapper {
    margin-top: 25px;

    .workspace-request-header-container {
        align-items: center;
        border-bottom: 1px solid #E6E7E9;
        margin-bottom: 35px;
        // padding-bottom: 5px;
        .workspace-request-search {
            border: none;
            &:focus {
                border: none !important;
            }

            &:active {
                border: none !important;
            }
        }
        .ws-request-search {
            position: relative;
            right: 0px !important;
            left:250px !important;
            z-index: 1;
            display: block !important;
          
        }
        .permission-dropdown {
            color: var(--Text---Light-mode-tertiary, #9CA0A9);
            font-size: 14px;
            font-weight: 400;
            background-color: transparent;
            border: none;
            .option-value {
                color: var(--Text---Light-mode-primary, #000);
                font-size: 14px;
                font-weight: 400;
                margin-left: 3px;
            }
        }
    }
    .list-view {
        .w-20 {
            width: 20%;
        }

        .w-35 {
            width: 35%;
        }

        .w-23 {
            width: 23%;
        }

        .w-25 {
            width: 25%;
        }

        .checkbox-column {
            width: 5%;
        }

        tbody {
            border-top: 1px solid #dee2e6 !important;
        }

        thead {
            border-bottom: none !important;
        }

        th {
            color: var(--Text---Light-mode-primary, #000);
            font-size: 14px;
            font-weight: 500;
            border-bottom: none !important;
        }

        .user-name {
            color: var(--Text---Light-mode-primary, #000);
            font-size: 14px;
            font-weight: 400;
        }

        .user-email {
            color: var(--Text---Light-mode-secondary, #5E5E5E);
            font-size: 14px;
            font-weight: 400;
        }

        .invited-by {
            color: var(--Text---Light-mode-secondary, #5E5E5E);
            font-size: 14px;
            font-weight: 400;
        }

        .permission-dropdown {
            color: var(--Text---Light-mode-primary, #000);
            font-size: 14px;
            font-weight: 400;
            background-color: transparent;
            border: none;
        }

        .action-link {
            cursor: pointer;

            &.success {
                color: var(--Icons-Brand, #342BC2);
                font-size: 12px;
                font-weight: 400;
            }

            &.danger {
                color: var(--Danger-Primary, #EF4444);
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .billing-footer-container {
        display: flex;
        align-items: center;
        padding-top: 10px;
        // border-top: 1px solid #dee2e6;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: 40px;

        .end-note {
          color: #000000;
          font-size: 10px;
          padding-left: 0;
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        .pagination-block {
          padding: 0;
          &.ws-request-pagination-block {
            z-index: -1;
          }
        }
      }
}

@media screen and (max-width: 1366px) {
    .restored-deleted-workspace-details-container {
        padding: 18px !important;
        margin-bottom: 0px !important;
    }
}

.lazy-workspace-sidebar-container {
    margin-left: 8px;
    .lazy-workspace-sidebar-heading {
        margin-bottom: 30px;
        margin-top: 22px;
        .lazy-workspace-description-item-one {
            width: 105px;
            border-radius: 3px;

            .shimmer-title-line {
                height: 20px !important;
            }
        }
    }
    .lazy-workspace-sidebar-block {
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 12px;
        .lazy-workspace-description-item-two {
            width: 20px;
            border-radius: 3px;

            .shimmer-title-line {
                height: 20px !important;
            }
        }
        .lazy-workspace-description-item-three {
            width: 115px;
            border-radius: 3px;

            .shimmer-title-line {
                height: 20px !important;
            }
        }
    }
}

.lazy-transfer-workspace-ownership-container {
    padding: 5px 0 0 14px;
    .lazy-transfer-workspace-ownership-box {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;
        .lazy-transfer-workspace-ownership-image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            .shimmer-avatar {
                margin-bottom: 0;
            }
        }
        .lazy-transfer-workspace-ownership-description-container {
            .lazy-transfer-workspace-ownership-description-one {
                width: 94px;
                margin-bottom: 4px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
            .lazy-transfer-workspace-ownership-description-two {
                width: 125px;
                margin-bottom: 0;
                .shimmer-title-line {
                    height: 12px !important;
                }
            }
        }
    }
}

.lazy-transfer-workspace-ownership-container-type-two {
    padding: 10px 10px 0 10px;
    .lazy-transfer-workspace-ownership-box {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;
        .lazy-transfer-workspace-ownership-image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            .shimmer-avatar {
                margin-bottom: 0;
            }
        }
        .lazy-transfer-workspace-ownership-description-container {
            .lazy-transfer-workspace-ownership-description-one {
                width: 94px;
                margin-bottom: 4px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
            .lazy-transfer-workspace-ownership-description-two {
                width: 125px;
                margin-bottom: 0;
                .shimmer-title-line {
                    height: 12px !important;
                }
            }
        }
        .lazy-transfer-workspace-ownership-status-container {
            margin-left: auto;
            .lazy-transfer-workspace-ownership-status {
                width: 98px;
                margin-bottom: 5px;
                .shimmer-title-line {
                    height: 12px !important;
                }
            }
        }
    }
}

.lazy-workspace-requests-container {
    margin-top: 50px;
    .lazy-workspace-requests-row-one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .lazy-workspace-requests-row-one-item-one {
            width: 200px;
            .shimmer-title-line {
                height: 17px !important;
            }
        }
        .lazy-workspace-requests-row-one-item-two {
            width: 130px;
            position: relative;
            right: 8px;
            .shimmer-title-line {
                height: 17px !important;
            }
        }
    }
    .lazy-workspace-requests-row-two {
        margin-bottom: 10px;
        .lazy-workspace-requests-row-two-column-one {
                .lazy-workspace-requests-row-two-item-one {
                    width: 26px;
                    .shimmer-title-line {
                        height: 20px !important;
                    }
                }
            }
            .lazy-workspace-requests-row-two-column-two {
                position: relative;
                right: 27px;
                .lazy-workspace-requests-row-two-item-two {
                    width: 50px !important;
                    .shimmer-title-line {
                        height: 20px !important;
                    }
                }
            }
            .lazy-workspace-requests-row-two-column-three {
                position: relative;
                left: 12px;
                .lazy-workspace-requests-row-two-item-three {
                    width: 64px;
                    .shimmer-title-line {
                        height: 20px !important;
                    }
                }
            }
            .lazy-workspace-requests-row-two-column-four {
                position: relative;
                right: 27px;
                .lazy-workspace-requests-row-two-item-four {
                    width: 110px;
                    .shimmer-title-line {
                        height: 20px !important;
                    }
                }
            }
            .lazy-workspace-requests-row-two-column-five {
                .lazy-workspace-requests-row-two-item-five {
                    width: 44px;
                    .shimmer-title-line {
                        height: 20px !important;
                    }
                }
            }
    }
    .lazy-workspace-requests-row-three {
        align-items: center;
        margin-bottom: 7px;
        .lazy-workspace-requests-row-three-column-one {
            .lazy-workspace-requests-row-three-item-one {
                width: 26px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
        }
        .lazy-workspace-requests-row-three-column-two {
            position: relative;
            right: 27px;
            .lazy-workspace-requests-row-three-item-two {
                width: 130px;
                margin-bottom: 15px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
            .lazy-workspace-requests-row-three-item-two-plus {
                width: 200px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
        }
        .lazy-workspace-requests-row-three-column-three {
            position: relative;
            left: 12px;
            .lazy-workspace-requests-row-three-item-three {
                width: 200px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
        }
        .lazy-workspace-requests-row-three-column-four {
            position: relative;
            right: 27px;
            .lazy-workspace-requests-row-three-item-four {
                width: 80px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
        }
        
        .lazy-workspace-requests-row-three-column-five {
            .lazy-workspace-requests-row-three-item-five {
                width: 62px;
                .shimmer-title-line {
                    height: 14px !important;
                }
            }
        }
    }
}