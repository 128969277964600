.sidebar {
  .layout-sidebar {
    text-align: left;
    max-width: 265px;
    flex: 0 0 270px;
    order: -1;
    height: 100%;
    position: fixed;
    // overflow-y: auto !important;
    background: #fbfbfb !important;
    scrollbar-gutter: stable;
    padding: 10px 20px 10px 20px;
    border-right: 1px solid #e6e7e9;

    .more-option {
      max-width: none;
    }

    .logo-plus-user-block-container {
      // background-color: thistle;
    }

    .logo-header {
      padding: 10px 0 10px;
      width: 100%;

      hr {
        opacity: 0.9;
        background-color: #e6e7e9;
        width: 227px;
        background-color: #e6e7e9;
        max-width: 100%;
      }
    }

    .user-block {
      border: 1px solid #342bc2;
      padding: 10px 20px;
      background: #342bc2;
      color: #fff;
      margin-bottom: 20px;
      a {
        text-decoration: none;
      }

      .intials {
        width: 28px;
        height: 28px;
        background: #ecd348;
        border-radius: 50%;
        margin: 7px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
      }

      .name {
        font-size: 14px;
        color: #fff;
        line-height: 1.4;
        margin: 3px 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .position {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 1.4;
        margin: 3px 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .more {
        display: flex;
        justify-content: center;
        margin: 3px 0 0 5px;

        .btn-group {
          margin: -6px -20px 0 0;
        }

        .settings-dropdown {
          background-color: transparent;
          font-size: 20px;
          border: none;
          text-align: center;
          height: 38px;

          &:hover {
            color: #ffffff;
            background-color: none;
            border: none;
          }

          &[aria-expanded="true"] {
            background-color: none;
            color: #ffffff;
            border: none;
          }
        }

        .dropdown-toggle::after {
          margin-left: 0;
        }

        .menu {
          .dropdown-header {
            color: rgba(24, 24, 25, 0.7);
            font-size: 14px;
            line-height: 20px;

            a {
              color: rgba(24, 24, 25, 0.7);
            }
          }
        }
      }
    }

    .menu-block-plus-workspace-container {
      overflow-y: auto !important;
      padding-right: 5px;

      // height: 460px;
      &::-webkit-scrollbar-thumb {
        background-color: #E6E7E9;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
    .menu-block {
      padding: 0px;

      .project-screen-limits {
        background-color: #ff9e2a;
        border-radius: 11px;
        padding: 4px 10px;
        font-size: 12px;
        color: #ffffff;
        position: absolute;
        right: 0;
        top: 27%;
        line-height: 1;
        font-weight: 100;
        &.limit {
          padding: 4px 12px !important;
        }
      }

      ul {
        padding-inline-start: 0px;
        list-style: none;
        line-height: 45px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);

        a {
          text-decoration: none;
          color: rgba(0, 0, 0, 0.5);
        }

        li {
          position: relative;
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            .menu-icon {
              padding: 0 15px 0 10px;
            }
          }

          &.modal-link {
            &:hover {
              color: #342bc2;
              cursor: pointer;
            }
          }
        }

        a.active {
          color: #342bc2;

          li {
            background-color: rgba(52, 43, 194, 0.1) !important;
          }

          li::after {
            content: "\f0da";
            text-align: right;
            color: #342bc2;
            font-family: FontAwesome;
            float: right;
            padding: 0 15px;
            margin-left: auto;
          }
        }

        li:hover {
          background-color: rgba(93, 93, 93, 0.08) !important;
        }
      }
    }

    .soon {
      pointer-events: none;

      span {
        opacity: 0.6;
      }

      .learn-view {
        color: #9ca0a9;

        &::after {
          content: "Soon";
          background-color: #342bc2;
          border: 1px solid #342bc2;
          padding: 2px 8px;
          color: #ffffff;
          border-radius: 10px;
          margin-left: 10px;
          font-size: 10px;
          letter-spacing: 0.5px;
        }
      }
    }

    .announce {
      border: 1px solid #e6e7e9;
      background: #fff;
      text-align: left;
      padding: 8px 18px 16px 18px;
      width: 100%;
      border-radius: 4px;

      img.confetti {
        width: 75%;
      }

      img {
        margin-bottom: 10px;
        margin-top: 5px;
      }

      h5 {
        font-size: 14px;
        margin: 0.45rem 0.45rem 0.35rem;
        text-align: left;
        color: #000;
        padding-inline-start: 5px;
      }

      p {
        color: #000000;
        font-family: Rubik;
        font-size: 11px;
        font-weight: 400;
        line-height: 18.15px;
        text-align: left;
      }

      ul {
        list-style: none;
        text-align: left;
        margin: 0;

        li {
          color: #5e5e5e;
          font-size: 13px;
          padding: 3px 0;

          .icon {
            margin: 0px 0px 0px -27px;
            padding: 0 5px;
          }
        }

        li::marker {
          margin-top: 2px;
        }
      }

      .get-started-button {
        height: 34px !important;
        font-size: 12px !important;
        font-weight: 400;
        text-align: center;
        background-color: #FFBB00 !important;
        border: none !important;
        width: 100%;
        border-radius: 2px;
      }

      .btn-register {
        min-width: 148px;
        border-radius: 0px;
        border: 1px solid #e6e7e9;
        background: #fff;
        color: #000;
        font-size: 12px;
        padding: 10px;
      }
    }

    .hr-line {
      padding: 12px 0;

      hr {
        color: #e6e7e9;
        opacity: 0.9;
      }
    }

    .footer {
      border: 1px solid #eeeeee;
      padding: 5px 20px;
      background: #fbfbfb;

      .intials-footer {
        width: 28px;
        height: 28px;
        background: #342bc2;
        border-radius: 50%;
        margin: 7px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }

      .company {
        font-size: 13px;
        color: #5d5d5d;
        line-height: 1.4;
        text-align: center;
        padding: 13px 0 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .more-footer {
        margin: 8px 0px;

        img {
          margin-left: 6px;
        }
      }
    }
  }
}

.error {
  background-color: #fef5d9;
  border: 1px solid #fad151;
  color: #5e5e5e;
  padding: 8px 10px;
  font-size: 12px;

  > i {
    display: inline-block;
    padding: 4px 7px 4px 5px;
  }

  > span {
    padding: 4px 8px 2px 1px;
  }
}

.main {
  width: 100%;
}

.row-margin {
  margin-right: 0px;
}

.bottom-footer {
  position: fixed;
  bottom: 10px;
  width: 221px;
}

.tutorial-popup {
  padding: 0;
  border: none;

  .header-image {
    background-color: #e9e9e9;
    margin-bottom: -7px;
    text-align: center;

    video {
      max-width: 100%;
      width: 100%;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .info-blocks {
    background-color: #ffffff;
    padding: 25px 45px 60px;

    h3 {
      font-weight: 500;
      font-size: 18px;
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 7px;
      color: #000000;

      .hello-wave {
        margin-top: -2px;
      }
    }

    p {
      padding-bottom: 18px;
      font-weight: normal;
      font-size: 15px;
      color: #5e5e5e;
      max-width: 350px;
    }
  }

  .btn-section {
    button {
      margin: 0 15px 0 0px;
      height: 45px !important;
      font-size: 14px;

      &.notification-btn {
        min-width: 35%;
      }
    }
  }
}

@media screen and (max-height: 870px) {
  .tutorial-popup {
    .info-blocks {
      background-color: #ffffff;
      padding: 20px 45px 40px;

      h3 {
        font-weight: 500;
        font-size: 18px;
        font-weight: 500;
        font-size: 20px;
        padding-bottom: 7px;

        .hello-wave {
          margin-top: -2px;
        }
      }

      p {
        padding-bottom: 18px;
        font-weight: normal;
        font-size: 15px;
        color: #5e5e5e;
        max-width: 350px;
      }
    }

    .btn-section {
      button {
        margin: 0 15px 0 0px;
        height: 45px !important;
        font-size: 14px;

        &.notification-btn {
          min-width: 35%;
        }
      }
    }
  }
}

.steps-style {
  margin-bottom: -10px;

  h2 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding-bottom: 12px;
    font-family: "Rubik";
    color: #ffffff;
  }

  p {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    font-family: "Rubik";
    color: #ffffffbf;
  }

  span {
    position: absolute;
    bottom: 28px;
    font-size: 12px;
    font-weight: normal;
    color: white;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    max-width: 150px;

    img {
      width: 70%;
    }
  }
}

polygon {
  fill: #352cc2;
}

.skip-steps {
  color: #ffffff;
  font-weight: normal;
}

.common-layout {
  display: flex;
}

.home-wrapper {
  display: flex;

  .sidebar {
    flex-basis: 270px;
    background-color: #ffffff;
  }

  .component-wrapper {
    flex-basis: calc(100% - 270px);
  }
}

@media screen and (min-height: 0px) and (max-height: 640px) {
  .announce {
    border: 1px solid #e6e7e9;
    background: #fff;
    text-align: center;
    padding: 12px 10px 10px;

    img.confetti {
      width: 50% !important;
    }

    img {
      margin-bottom: 10px;
      margin-top: 5px;
      width: 28% !important;
    }

    p {
      font-size: 10px;
    }

    button {
      height: 32px !important;
    }

    h5 {
      font-size: 13px !important;
      margin: 0.35rem 0.35rem 0.25rem !important;
      text-align: left;
      padding-inline-start: 5px;
    }

    ul {
      list-style: none;
      text-align: left;
      margin: 0;

      li {
        color: #5d5d5d;
        font-size: 12px !important;
        padding: 3px 0 !important;

        .icon {
          margin: 0px 0px 0px -27px;
          padding: 0 5px;
        }
      }

      li::marker {
        margin-top: 2px;
      }
    }
  }
}

.workspace-creation-container {
  .workspace-hr {
    opacity: 0.1;
  }
  .workspace-heading {
    color: var(--Text---Light-mode-tertiary, #9CA0A9);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px !important;
    padding-left: 10px;
  }
  .workspace-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 9px 0;
    border: 1px solid #E6E7E9;
    cursor: pointer;
    .workspace-button {
      color: var(--Text---Light-mode-primary, #000);
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
    .workspace-btn-add-wrap {

      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px 0 10px;
      margin-bottom: 15px;

      .ws-btn-add-right-wrap {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        cursor: pointer;
        
        .ws-btn-add-heading {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-weight: 500;
          padding-left: 10px;
        }

        .workspace-list-arrow {
          height: 24px;
          width: 24px;
        }
      }

      .ws-btn-add {
        cursor: pointer;
        // color: #E6E7E9;
        img {
          color: #E6E7E9;
          background-color: #fbfbfb;
        }
      }
  }
  .workspace-button-options {

    ul {
      padding-inline-start: 0px;
      list-style: none;
      line-height: 45px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.5);
      }

      li {
        position: relative;
        display: flex;
        align-items: center;

        .ws-btn-option-icon-wrap {
          width: 15%;
          padding-left: 10px;
          margin-right: 5px;
          position: relative;
          bottom: 1px;
          .ws-btn-option-icon {
            padding: 0 0 0 10px;
          }
        }

        .ws-btn-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0px 8px;
        font-size: 14px;
        font-weight: 400;
        }

        &.modal-link {
          &:hover {
            color: #342bc2;
            cursor: pointer;
          }
        }
      }

      a.active {
        color: #342bc2;

        li {
          background-color: rgba(52, 43, 194, 0.1) !important;
        }

        // li::after {
        //   content: "\f142";
        //   text-align: right;
        //   color: #342bc2;
        //   font-family: FontAwesome;
        //   float: right;
        //   padding: 0 15px;
        //   margin-left: auto;
        // }
      }

      li:hover {
        background-color: rgba(93, 93, 93, 0.08) !important;
      }
    }
  }
}

.workspace-modal-container {
  &.delete-folder-modal {
    width: 328px;
    height: 304px;

    .leave-team-button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      margin-top: 25px;

      .cancel-button {
        margin: 0 !important;
        width: 136px !important;
        height: 42px !important;
        color: var(--Text---Light-mode-primary, #000) !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        background: transparent !important;
        border: 1px solid var(--Grey-Line, #E6E7E9) !important;
        border-radius: 2px !important;

        &:hover {
          background: transparent !important;
          border: 1px solid var(--Grey-Line, #E6E7E9) !important;
        }
      }

      .leave-button {
        margin: 0 !important;
        color: var(--White, #FFF) !important;
        width: 136px !important;
        height: 42px !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        border: none !important;
        border-radius: 2px !important;
        background: #E50000;

        &:hover {
          background-color: #ce1212 !important;
        }
      }
    }
  }
  .workspace-modal-body {
    padding: 20px 0px 0px 0px;
    &.delete-folder-modal-body {
      padding: 20px 20px 0 20px !important;
    }
    .ws-invite-members-tag {
      font-size: 14px;
      margin-left: 3px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        color: #000000;
      }
      &.delete-folder-modal-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 !important;
        gap: 20px;
        h4 {
          margin-bottom: 20px;
          font-size: 18px;
        }
      }
      &.no-side-padding {
        padding: 0;
      }
    }
    .workspace-share-project {
      margin: 0 20px 20px 20px;
    }
    hr {
      color: #e6e7e9;
    }
    .workspace-modal-wrapper {
      background-color: var(--text-active-color);
      h4 {
        font-size: 14px;
        margin-bottom: 0.25rem;
        font-weight: 400;
      }
      .workspace-modal-full-image {
        margin-bottom: 15px;
      }
      .workspace-modal-description-container {
        padding: 4px 20px 0 20px;
        &.ws-modal-desc{
          padding: 4px 20px 20px 20px !important;
        }
        .workspace-modal-heading {
          color: var(--Text---Light-mode-primary, #000);
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        .workspace-modal-description {
          color: var(--Text---Light-mode-tertiary, #9CA0A9);
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
          .bold-part {
            font-weight: 500;
            color: var(--Text---Light-mode-primary, #000);
          }
        }
        .enabling-invite-link-container {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 15px;
    
          .enabling-invite-link-switch {
            cursor: pointer;
          }
    
          .enabling-invite-link-description {
            margin-bottom: 0;
            color: #000;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .invite-team-members-label-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          top: 10px;
          .workspace-role-distribution {
            .workspace-dropdown-toggle {
              color: var(--Brand-Main, #342BC2);
              text-align: right;
              font-size: 12px;
              font-weight: 400;
              background-color: transparent;
              border: none;
              display: flex;
              align-items: center;
              gap: 2px;
            }
            .dropdown-item {
              border: none !important;
              &:hover {
                border: none !important;
              }
            }
          }
        }
        .workspace-modal-input-box-container {
          .workspace-modal-input-label {
            color: #212529;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            margin-top: 15px;
            &.rename-folder-label {
              margin-top: 0;
            }
            &.opening-input-label {
              margin-top: 10px;
            }
          }
          .target-location-container {
            border-radius: 2px;
            border: 1px solid var(--Grey-Line, #E6E7E9);
            padding: 12px;
            .personal-space-location {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 12px 8px 38px;
              cursor: pointer;
              &:hover {
                border-radius: 2px;
                background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04));
              }
              &.active {
                border-radius: 2px;
                background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04));
              }
              .two-items-collective {
                display: flex;
                align-items: center;
                .personal-credits-icon {
                  color: var(--text-blue-dark-surface-primary, #FFF);
                  font-size: 10px;
                  font-weight: 500;
                  background-color: rebeccapurple;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &.no-circle-background {
                    background-color: transparent;
                    border-radius: 0;
                  }
                }
                .personal-credits-title {
                  color: #000;
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 0;
                  margin-left: 12px;
                }
              }
              .move-disable {
                margin-left: 5px;
                pointer-events: visible !important;
              }
              .tick-mark {
                width: 16px;
                height: 16px;
              }
              &.dropdown-component {
                padding: 8px 12px 8px 12px;
                overflow-x: hidden;
                .personal-credits-icon {
                  margin-left: 6px;
                }
              }
              &.move-project-component{
                justify-content: flex-start !important;
                pointer-events: none;
              }
              &.folder-component {
                padding: 8px 12px 8px 74px;
                .personal-credits-title {
                  color: var(--Text---Light-mode-secondary, #5E5E5E);
                  font-size: 12px;
                  font-weight: 400;
                  margin-left: 8px;
                }
              }
            }
            &.move-project-target-location {
              height: 300px !important;
              overflow-y: auto;
            }
            .move-pr-folders {
              max-height: 120px !important;
              overflow-y: auto;
            }
          }
          .create-mew-folder-manage-access-container {
            .email-invites-role-container {
              display: flex;
              align-items: center;
              gap: 7px;
        
              .form-group {
                margin: 15px 0;
                display: flex;
                align-items: center;
                .form-group {
                  margin-top: 0 !important;
                  margin-bottom: 0 !important;
                }
        
                >div:first-child {
                  width: 100%;
                }
        
                .input-style {
                  height: 40px !important;
                  width: 100%;
                  font-size: 12px !important;
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                }
        
                .input-style::placeholder {
                  font-size: 12px;
                }
        
                .role-distribution {
                  border: 1px solid var(--border-color);
                  border-left: none;
                  border-radius: 0 !important;
        
                  .dropdown-toggle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2px;
                    height: 40px;
                    background-color: transparent !important;
                    color: var(--brand-color);
                    outline: none;
                    border: none;
                    width: 100% !important;
                    padding: 0 12px !important;
                    font-size: 12px;
                    box-shadow: -10px 0px 10px rgba(217, 217, 217, 0.4) !important;
                  }
                }
              }
        
              .invite-button {
                font-size: 12px;
                padding: 6px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--brand-color);
                color: var(--text-active-color);
                border: transparent;
                gap: 4px;
                width: 72px !important;
                border-radius: 2px !important;
                height: 40px;
        
                &:hover {
                  background-color: #272092;
                  border: 1px solid #272092;
                }
              }
            }
          }
          .workspace-members-container {
            padding: 4px 12px;
            border-radius: 2px;
            border: 1px solid var(--Grey-Line, #E6E7E9);
            background: #FFF;
            margin-top: 16px;
            max-height: 204px;
            overflow-y: auto;
            .workspace-members-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              // border-bottom: 1px solid #E6E7E9;
              padding-top: 14px;
              padding-bottom: 14px;
              margin-right: 10px;
              margin-left: 10px;
              &.no-border-bottom {
                border-bottom: none;
              }
              .workspace-members-title-container {
                display: flex;
                align-items: center;
                gap: 15px;
                .workspace-icon {
                  color: var(--text-blue-dark-surface-primary, #FFF);
                  font-family: Rubik;
                  font-size: 12px;
                  font-weight: 400;
                  background-color: yellowgreen;
                  border-radius: 50%;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .workspace-members-description-container {
                  display: flex;
                  flex-direction: column;
                  gap: 2px;
                  .workspace-members-title {
                    color: #000;
                    font-size: 12px;
                    font-weight: 400;
                  }
                  .workspace-members-count {
                    color: var(--Text---Light-mode-tertiary, #9CA0A9);
                    font-size: 10px;
                    font-weight: 400;
                  }
                }
              }
              .default-access {
                .dropdown-toggle {
                  color: var(--Brand-Main, #342BC2);
                  font-size: 12px;
                  font-weight: 400;
                  background-color: transparent !important;
                  border: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;
                  padding: 0 !important;
                }
                .default-access-menu {
                  padding: 12px;
                  border-radius: 2px;
                  background: #FFF;
                  box-shadow: 8px 10px 32px 8px rgba(52, 43, 194, 0.06);
                  width: 232px;

                  .default-access-menu-heading {
                    color: var(--Text---Light-mode-tertiary, #9CA0A9);
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 12px;
                  }
                  .default-access-items {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 12px;
                    gap: 12px;
                    cursor: pointer;
                    &.active {
                      border-radius: 2px;
                      background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04));
                    }
                    .default-access-option-container {
                      .default-access-title {
                        margin-bottom: 0;
                        color: var(--Text---Light-mode-primary, #000);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 120%;
                        margin-bottom: 5px;
                        &.no-access {
                          color: var(--Danger-Primary, #EF4444);
                        }
                      }
                      .default-access-subtitle {
                        margin-bottom: 0;
                        color: var(--Text---Light-mode-tertiary, #9CA0A9);
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 120%;
                        width: 155px;
                      }
                    }
                    .tick-icon {
                      width: 16px !important;
                      height: 16px !important;
                      img {
                        width: 16px !important;
                        height: 16px !important;
                      }
                    }
                    
                  }
                }
                .role-description-text {
                  color: var(--Text---Light-mode-tertiary, #9CA0A9);
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 0;
                }
              }
            }
          }
          .workspace-modal-input-box {
            font-size: 12px !important;
            font-weight: 400;
            width: 100%;
            border-radius: 2px;
            border: 1px solid var(--Grey-Line, #E6E7E9);
            padding: 13px 16px;
            color: var(--Text---Light-mode-primary, #000);
            &::placeholder {
              font-size: 12px;
              color: var(--Text---Light-mode-tertiary, #9CA0A9);
            }
            &:focus {
              border: 1px solid var(--Grey-Line, #E6E7E9);
              outline: none !important;
            }
          }
          .workspace-modal-radio-container {
            margin-top: 15px;
            .workspace-modal-permission-option {
              .workspace-modal-permission-label {
                color: var(--Text---Light-mode-primary, #000);
                font-size: 14px;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 12px;
                margin-top: 10px;
                .workspace-modal-radio-on {
                  cursor: pointer;
                }
                .workspace-modal-radio-off {
                  cursor: pointer;
                }
              }
              &.info-icon {
                display: flex;
                align-items: center;
                gap: 5px;
                .info-grey-icon {
                  margin-top: 9px;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .checkbox-and-label-container {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 16px;
          .notify-members-checkbox {
            accent-color: var(--brand-color);
            cursor: pointer;
            width: 16px;
            height: 16px;
            border: 2px solid #323232;
            background-color: transparent;
          }
          .notify-members-checkbox:checked {
            background-color: var(--brand-color); 
            border: 2px solid var(--brand-color) !important;        
          }
          .notify-members-checkbox:focus {
            border: 2px solid #323232 !important;
          }
          .notify-members-checkbox:focus-within {
            border: 2px solid #323232 !important;
          }
          .notify-members-label {
            margin-bottom: 0;
            color: var(--Text---Light-mode-secondary, #5E5E5E);
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .url {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid var(--border-color);
          border-radius: 2px;
          padding: 5px 5px 5px 10px;
          margin: 10px 0 0;
          height: 45px;
          p {
            font-size: 12px;
            padding-right: 2px;
            max-width: 358px;
            margin-bottom: 0px;
            color: var(--text-tertiary-color);
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            &.ws-url {
              max-width: 362px !important;
            }
          }
          button {
            font-size: 12px;
            padding: 8px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--brand-light-color);
            color: var(--active-blue-color);
            border: transparent;
            gap: 4px;
            width: 72px !important;
            &:hover {
              background-color: var(--brand-light-color);
            }
          }
          .regenerate-code-icon {
            cursor: pointer;
            margin-right: 12px;
            margin-left: 5px;
          }
        }
        &.success-modal-description-container {
          text-align: center;
          margin-left: 60px;
          margin-right: 60px;
          .success-modal-gif {
            margin: 20px 30px 30px 30px;
          }
          .workspace-modal-title {
            color: var(--Text---Light-mode-primary, #000);
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px;
          }          
          .workspace-modal-description {
            color: var(--Text---Light-mode-secondary, #5E5E5E);
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 50px;
          }          
        }
        .workspace-modal-email-invite {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin: 10px 0 0;

          .workspace-role-distribution-invite {
            .workspace-dropdown-toggle-invite {
              color: var(--Brand-Main, #342BC2);
              text-align: right;
              font-size: 12px;
              font-weight: 400;
              background-color: transparent;
              border: none;
            }
            .dropdown-item {
              border: none !important;
              &:hover {
                border: none !important;
              }
            }
          }
        }
      }
      .team-ownership-section {
        margin: 12px 21px auto 21px !important;

        .team-ownership-heading {
          color: var(--Text---Light-mode-primary, #000) !important;
          font-family: Rubik !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          margin-bottom: 15px !important;
        }

        .team-ownership-box-container {
          border-radius: 2px !important;
          border: 1px solid var(--Grey-Line, #E6E7E9) !important;
          margin-bottom: 0px;

          .box-first-half {
            .input-style {
              border-bottom: 1px solid var(--Grey-Line, #E6E7E9) !important;
              border-top: none !important;
              border-left: none !important;
              border-right: none !important;
            }

            .search-bar {
              background-size: 18px 18px;
              background-position: 16px !important;

              font-size: 12px;
              font-weight: 400;
              &::placeholder {
                font-size: 12px;
                font-weight: 400;
              }
            }

            .search-close {
              img {
                width: 20px;
                height: 20px;
              }
            }
          }

          .box-second-half {
            max-height: 248px !important;
            padding: 12px !important;
            overflow-y: auto;

            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background: var(--Grey-Line, #E6E7E9);
            }

            &::-webkit-scrollbar-track {
              background: transparent !important;
            }

            .individual-details-container {
              padding: 12px 16px !important;
              display: flex !important;
              align-items: center !important;
              gap: 15px !important;

              .individual-logo {
                color: var(--text-blue-dark-surface-primary, var(--Light, #FFF)) !important;
                font-family: Rubik !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                background-color: var(--Brand-Main, #342BC2) !important;
                border-radius: 50% !important;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                width: 32px !important;
                height: 32px !important;
              }

              .individual-details {
                .individual-name {
                  color: #000 !important;
                  font-family: Rubik !important;
                  font-size: 12px !important;
                  font-weight: 400 !important;
                  margin-bottom: 5px !important;
                  line-height: normal !important;
                }

                .individual-email {
                  color: var(--Text---Light-mode-tertiary, #9CA0A9) !important;
                  font-family: Rubik !important;
                  font-size: 10px !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                  line-height: normal !important;
                  margin-bottom: 0 !important;
                }
              }

              &:hover {
                border-radius: 3px !important;
                background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04)) !important;
                cursor: pointer;
              }

              .check-icon {
                margin-left: auto !important;
                margin-right: 10px;
              }

              &.clicked-container {
                border-radius: 3px !important;
                background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04)) !important;
                cursor: pointer;
              }
            }
          }
        }

        .team-ownership-footer {
          .team-ownership-footer-text {
            color: var(--Text---Light-mode-tertiary, #9CA0A9);
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 25px;
          }

          .team-ownership-buttons {
            display: flex;
            align-items: center;
            gap: 35px;
            .confirm-button {
              width: 170px !important;
              min-width: 0;
              height: 60px !important;
            }
            .go-back-button {
              color: #000;
              font-family: Rubik;
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0 !important;
              padding-bottom: 0 !important;
              cursor: pointer;
            }
          }
        }
      }
      .workspace-modal-button-container {
        padding: 0 20px;
        .error-text{
          color: var(--Danger-Primary, #EF4444);
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
        }
        .email-invites-role-container {
          display: flex;
          align-items: center;
          gap: 7px;
          .form-group {
            margin: 0;
            display: flex;
            align-items: center;
            >div:first-child {
              width: 100%;
            }
            .input-style {
              height: 40px !important;
              width: 100%;
              font-size: 12px !important;
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              margin: 7px 0 0;
              max-height: 400px;
              overflow-y: scroll;
            }
            .input-style::placeholder {
              font-size: 12px;
            }
            .role-distribution {
              border: 1px solid var(--border-color);
              border-left: none;
              border-radius: 0 !important;
              .dropdown-toggle {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;
                height: 40px;
                background-color: transparent !important;
                color: var(--brand-color);
                outline: none;
                border: none;
                width: 100% !important;
                padding: 0 12px !important;
                font-size: 12px;
                box-shadow: -10px 0px 10px rgba(217, 217, 217, 0.4) !important;
              }
            }
          }
        }
        .workspace-modal-button {
          font-size: 12px;
          padding: 6px 18px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--brand-color);
          color: var(--text-active-color);
          border: transparent;
          gap: 4px;
          border-radius: 2px !important;
          height: 36px;
          margin: 24px 0 22px 0;
          font-weight: 400 !important;
          float: right;
          &:hover {
            background-color: #272092;
          }
          &.skip-for-now-button {
            background-color: transparent !important;
            border: none !important;
            outline: none !important;
            color: var(--Text---Light-mode-tertiary, #9CA0A9);
            &:hover {
              background-color: transparent !important;
              border: none !important;
              outline: none !important;
            }
          }
          &.left-button {
            float: left !important;
          }
          &.transparent-button {
            background-color: transparent !important;
            color: var(--Text---Light-mode-tertiary, #9CA0A9);
            text-align: center;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .multi-input {
          width: 100%;
          margin-top: 10px;
          .tags-input-container {
            display: flex;
            flex-direction: column;
          }
          .tags-input {
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: flex-start;
            // align-items: center;
            border: 1px solid rgba(230, 231, 233, 1);
            border-radius: 2px;
            padding: 0px;
            min-height: 32px;
            max-height: 106px;
            overflow-y: auto;
            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background: var(--Grey-Line, #E6E7E9);
            }
            &::-webkit-scrollbar-track {
              background: transparent !important;
            }
            .workspace-role-distribution {
              .workspace-dropdown-toggle {
                color: var(--Brand-Main, #342BC2);
                text-align: right;
                font-size: 12px;
                font-weight: 400;
                background-color: transparent;
                border: none;
              }
              .dropdown-item {
                border: none !important;
                &:hover {
                  border: none !important;
                }
              }
            }
          }
          .tag {
            background-color: rgba(246, 246, 246, 1);
            color: rgba(156, 160, 169, 1);
            padding: 2px 6px;
            margin: 3px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            font-size: 14px;
            gap: 8px;
          }
          .tag button {
            background: none;
            border: none;
            color: rgba(156, 160, 169, 1);
            cursor: pointer;
            padding: 0;
          }
          .tag button:hover {
            color: #000000;
          }
          .tags-input input {
            flex: 1;
            border: none;
            outline: none;
            font-size: 14px;
            &::placeholder {
              font-size: 12px;
              color: rgba(156, 160, 169, 1);
              opacity: 1;
            }
            &:not(:placeholder-shown) {
              background-color: #ffffff;
            }
            &:focus-within {
              border: none !important;
              color: rgb(0, 0, 0) !important;
            }
          }
        }
        .workspace-modal-buttons-group {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .welcome-btn-section {
          padding: 20px 0px;
          button {
            margin: 0 15px 0 0px;
            height: 45px !important;
            font-size: 14px;

            &.notification-btn {
              min-width: 35%;
            }
          }
        }
      }
      .delete-folder-content-container {
        .content-one {
          color: var(--Text---Light-mode-secondary, #5E5E5E);
          font-size: 16px;
          font-weight: 400;
        }
        .content-two {
          color: var(--Text---Light-mode-tertiary, #9CA0A9);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .workspace-share-project-footer-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-bottom: 15px;
      // padding-top: 10px;
      // background: #FBFBFE;
      .workspace-share-project-footer-left {
          font-size: 14px;
        .dropdown-toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
          height: 20px;
          background-color: transparent !important;
          color: var(--brand-color);
          outline: none;
          border: none;
          width: 100% !important;
          padding: 0 12px !important;
          font-size: 12px;
        }
      }
      .workspace-share-project-footer-right {
        
        .dropdown-toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;
          height: 20px;
          background-color: transparent !important;
          color: var(--brand-color);
          outline: none;
          border: none;
          width: 100% !important;
          padding: 0 12px !important;
          font-size: 12px;
        }
      }
    }
    .right-close-button {
      margin-left: auto;
    }
    .closes-button {
      button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;
        &:hover {
          color: #000000;
          background-color: #e9ecef;
          border: 1px solid #e9ecef;
          font-weight: 900;
        }
      }
    }
    .close-player {
      position: absolute;
      top: 20px;
      right: 20px;
      button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;
        &:hover {
          color: #000000;
          background-color: #e9ecef;
          border: 1px solid #e9ecef;
          font-weight: 900;
        }
      }
    }
    .nav-tabs .nav-item {
      width: 33.3%;
      text-align: center;
    }
  }
  &.create-new-project-modal-container {
    .dashboardSizePopUp .frameSizePopUp-main .frameSizePopUp-right .outers-frame {
      max-height: 235px;
      min-height: 235px;
    }
    .workspace-create-new-project {
      margin-bottom: 20px;
      .nav-tabs .nav-item {
        width: 50%;
       }
    }
     .workspace-modal-body .workspace-modal-wrapper .workspace-modal-description-container {
      padding: 4px 0 25px 0 !important;
    }
  }
  // .workspace-invite-dropdown {
  //   position: fixed !important;
  //   max-width: 25%;
  //   height:20px;
  //   transform: translateX(22rem) !important;
  // }

  // .test-drop {
  //   display: flex;
  //   flex-wrap: wrap;
  //   max-width: 75%;
  // }
}

.workspace-folder-container {
  .ws-design-section {

    .ws-folder-header {

      .ws-designs-dropdown {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 15px;
        .ws-dropdown-profile-img {
          height: 60px;
          width: 60px;
          .workspace-team-initials {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            color: white;
            font-weight: 500;
          }
          .workspace-team-logo {
            width: 60px;
            height: 60px;
            border-radius: 10px;
          }
        }

        .ws-dropdown-profile-info {
          display: flex;
          align-items: flex-end;
          flex-direction: row;
          width: 85%;

          .ws-dropdown-profile-name {
            font-weight: 400;
            font-size: 28px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 400px;
            white-space: nowrap;
            margin: 0;
          }

          .ws-dropdown-option {
            cursor: pointer;

            .disabled{
              pointer-events: none !important;
            }

            // .ws-dropdown-main {
            //   z-index: 900;
            // }

            .ws-dropdown-item {
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              column-gap: 10px;
              padding: 5px 30px 5px 15px;
            }

            .ws-dropdown-hr {
              max-width: 100%;
              margin: 8px 0 8px 10px !important;
              color: #E6E7E9;
              border: 1px solid;
            }

            // .ws-dropdown-item::after {
            //   content: "\f0da";
            //   text-align: right;
            //   color: "#342bc2";
            //   font-family: FontAwesome;
            //   float: right;
            //   padding: 0 10px;
            //   margin-left: auto;
            // }

            .ws-dropdown-item::after {
              content: "\f0da"; 
              font-family: FontAwesome;
              color: "#342bc2";
              position: absolute;
              right: 10px;
              opacity: 0; 
              transition: opacity 0.2s ease;
            }
            
            .ws-dropdown-item:hover::after {
              opacity: 1;
            }

            .ws-dropdown-item:hover {
              .ws-icon {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(89deg) brightness(106%) contrast(101%);
              }
            }
          }
   
        }
      }

      .ws-designs-header {
        .ws-button-block {
          .ws-md-btn {
            background-color: #ffffff !important;
            color: #000000 !important;
          }
        }
        .ws-settings-header-path {
          display:flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          column-gap: 3px;
          font-size: 14px;
          font-weight: 400;
          padding-bottom: 16px;
          color: #9CA0A9;

          .chevron-ws-img {
           width: 14px;
           height: 14px;
           color: #9CA0A9;
          }
 
          span:nth-child(1) {
           color: #342BC2;
           cursor: pointer;
          }
       }
      }
    }
    .ws-folder-wrap {

      .ws-folder-heading {
          h2 {
            font-size: 16px;
            color: #000000;
            font-weight: 400;
            margin-bottom: 0;
          }
      
          .css-4dsw8p-control,
          .css-1uwauv8-control {
            border: none !important;
          }
      
          .css-n7zub7-menu {
            position: absolute;
            left: -10px;
          }
      
          .css-qc6sy-singleValue {
            color: #9ca0a9 !important;
          }
      
          .css-1jqbu51-MenuList,
          .css-n7zub7-menu {
            width: 210px;
            padding: 10px 0px;
          }
      
          .css-319lph-ValueContainer {
            padding-right: 0px;
      
            .css-qc6sy-singleValue {
              margin-right: 0px;
            }
          }
        }

      .ws-folder-containers {

        .workspace-view-more-folders-button {
          font-size: 14px;
          font-weight: 400;
          color: #9CA0A9;
          cursor: pointer;
          width: fit-content;
        }
        .ws-folder-child-compn {
          padding-bottom: 24px;
        }

        .ws-folder-contents {
          position: relative;
          display: flex;
          background-color: #F6F6F6;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          // padding: 17px 44px 17px 44px;
          padding: 17px 24px 17px 23px;
          column-gap: 9px;
          border: 1px solid #E6E7E9;
          cursor: pointer;
          border-radius: 2px;

          .pro-tag{
            right: 4px;
            top:-11px;
          }

          &.ws-folder-child-contents {
            justify-content: space-between !important;
            // padding: 16px 15px 16px 15px;
            padding: 8px 0px 8px 12px;

            .ws-folder-child-wrap {
                 display: flex;
                 flex-direction: column;
                 width: 78%;
                .ws-folder-child-left {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  column-gap: 9px;
                  overflow: hidden;
                  
            
                  .ws-folder-contents-primary-text {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 14px;
                    margin: 0;
                  }
                }

                .ws-folder-child-name {
                  margin-left: 29px;
                  .ws-folder-child-name-text {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 12px;
                    margin: 0;
                  }
                }
              }
          }

          // &.ws-folder-child-contents::after {
          //   content: "\f142";
          //   text-align: right;
          //   color: #9CA0A9;
          //   font-family: FontAwesome;
          //   float: right;
          //   margin-left: auto;
          // }

          .ws-folder-contents-icon {
            width: 18px;
            height: 18px;
          }
          .ws-folder-more-icon {
            width: 18%;
            .ws-folder-more-drop-toggle {
              background-color: transparent !important;
              border: none !important;
              padding:0 10px 0 0;
              margin:0;
            }

          }
          .ws-folder-contents-text {
            font-size: 14px;
            font-weight: 400;
            margin:0;
          }
        }

        .ws-folder-contents.active {
          background-color:rgba(52, 43, 194, 0.1);
          img {
            filter: brightness(0) saturate(100%) invert(10%) sepia(84%) saturate(6579%) hue-rotate(250deg) brightness(88%) contrast(88%);
          }
          p {
            color: #342bc2 !important;
          }
        }
        // .ws-folder-contents:hover {
        //   background-color: rgba(93, 93, 93, 0.08) !important;
        // }
      }

      .workspace-grid-view {

        .workspace-title-text {
          margin-bottom: 2px;
        }
        .workspace-desc-text {
          display: flex;
          align-items: baseline;
          flex-direction: row;
          justify-content: space-between;
          column-gap: 4px;
          white-space: nowrap;
          width: 50%;
        }
        .ws-desc-text-dot {
          color: #C4C4C4;
        }
      }
    }
    .ws-members-wrapper {
      padding:25px 0 0 0 !important;

      .member-search-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .ws-members-table {
        .checkbox-member-wrap {
          position: relative;
          top: 1.5px;
        .checkbox-member {
          cursor: pointer;
          width: 12px;
          height: 12px;
        }
        .disabled{
          pointer-events: none;
        }
        }
        .members-grey-text {
          color: #5E5E5E !important;
        }
     }

     .member-dropdown-container {
       
      .member-dropdown-text-wrap {
        display: flex;
        align-items: center;
        flex-direction: row;
        .member-drop-text { 
          color: #9CA0A9 !important;
          font-size: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding:7px 0px;
          font-weight: 400;
        }
      }
      .css-319lph-ValueContainer {
        padding: 2px 0;
      }
      &.member-dropdown-container-activity {
        .css-n7zub7-menu {
          width: 180px;
          transform: translateX(-10%);
        }
      }
     }
     .ws-checkbox-select-wrap {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      background: linear-gradient(90deg, #2667B5 0%, #5930FD 100%);
      border-radius: 2px;
      padding:20px;
      .ws-check-select-left {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 12px;
        .checkbox-member-select {
          width: 14px;
          height: 14px;
        }
        .member-select-text {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .ws-check-select-right {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 16px;
        .ws-check-span {
          cursor: pointer;
        }
        
        .ws-check-img {
          width: 20px;
          height: 20px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(89deg) brightness(106%) contrast(101%);
        }
      }
     }

     .ws-members-footer-container {
      margin-bottom: 40px !important;
      border-top: 0px !important;
      .ws-members-pagination-block {
        z-index: -1 !important;
      }
     }

     .ws-member-search {
      left: 40% !important;
      right: 0px !important;
      z-index: 1;
      max-width: 10%;
     }
     
    }
  }

  .ws-projects-error-child {

    .ws-projects-error-button-pr {
      width: 164px;
      height: 36px;
      font-size: 12px;
      border-radius: 2px;
      background-color: #342bc2;
      border: none;
      margin-top: 0.15rem;
      font-family: Rubik;
      transition: background-color 0.15s ease-in-out;
    }
    .ws-projects-error-button {
      width: 164px;
      height: 36px;
      font-size: 12px;
      background-color: #F6F6F6;
      color: #342BC2;
      border: none;
      margin-top: 0.15rem;
    }
  }

  .members-dropdown-toggle {
    display:flex;
    align-items: center;
    flex-direction: row;
    gap: 2px;
  }
}

.workspace-settings-container {

  .ws-settings-wrapper {
    .ws-settings-header {
      .ws-settings-header-path {
         display:flex;
         align-items: center;
         justify-content: flex-start;
         flex-direction: row;
         column-gap: 3px;
         font-size: 12px;
         font-weight: 400;
         padding-bottom: 16px;
         color: #9CA0A9;

         .chevron-ws-img {
          width: 14px;
          height: 14px;
          color: #9CA0A9;
         }

         span:nth-child(1) {
          color: #342BC2;
          cursor: pointer;
         }
      }
      .ws-settings-bar {
        top: 180px !important;
        // left:0 !important;

      }
    }
    .workspace-account-wrapper {

      .ws-account-top-card {

        .ws-acc-right-tab {

          .ws-acc-right-tab-note {
            font-size: 14px;
            font-weight: 400;
            color: #5E5E5E;
            margin-top: 20px;
          }
        }
      }
      
      .ws-delete-wrap {
        margin-top: 25px;

        .ws-delete-owner-section {

          .ws-delete-confirm-block {
            margin-bottom: 25px !important;
          }

          .ws-del-confirm-footer {

            .ws-delete-confirm-button-wrap {
              display: flex;
              align-items: center;
              gap: 20px;
              .ws-delete-confirm-button {
                min-width: auto;
                width: 130px;
                height: 60px;
                background-color: #BE1931 !important;
                border: 1px solid #BE1931 !important;
              }
              .go-back-button {
                margin-bottom: 0;
                cursor: pointer;
              }
            }
          }
        }
      }

      .workspace-delete-card {
        margin-top: 25px;

        .delete-workspace-btn {
          background-color: #BE1931 !important;
          border: 1px solid #BE1931 !important;
        }
      }

      .ws-leave-btn {
        width: fit-content !important;
        font-size: 16px !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
      }

    }
  }
}

// pagination overlaping filter dropdowns of member and requests in ws
.ws-dropd-menu-position {
  z-index: 9 !important;
}

#ws-tooltip {
  max-width: 21% !important;
}

.default-access-overflow {
  max-height: 135px;
  overflow-y: auto;
}

.workspace-tooltip-container {
  .tooltip-wrapper {
      position: relative;
      text-align: center;
      cursor: default;
  }

  .tooltip-wrapper .tooltip {
      background: #222;
      bottom: 100%;
      color: #fff;
      display: block;
      margin-bottom: 15px;
      opacity: 0;
      padding: 10px;
      pointer-events: none;
      position: absolute;
      width: 300px;
      border-radius: 2px;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
      -webkit-transition: all .25s ease-out;
      -moz-transition: all .25s ease-out;
      -ms-transition: all .25s ease-out;
      -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
      -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  }

  .tooltip-wrapper .tooltip:before {
      bottom: -20px;
      content: " ";
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      width: 100%;
  }

  .tooltip-wrapper .tooltip:after {
      border-left: solid transparent 10px;
      border-right: solid transparent 10px;
      border-top: solid #222 10px;
      bottom: -10px;
      content: " ";
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
  }

  .tooltip-wrapper:hover .tooltip {
      opacity: 1;
      pointer-events: auto;
  }

  /* IE can just show/hide with no transition */
  .lte8 .tooltip-wrapper .tooltip {
      display: none;
  }

  .lte8 .tooltip-wrapper:hover .tooltip {
      display: block;
  }
}

@media screen and (max-width: 1290px) and (max-height: 768px) {
  .no-design-parent-container {
    &.ws-projects-error {
    height: auto !important;
  }
  }
  
}

.ws-bold-text {
  font-weight: 500 !important;
}
.lazyWs_designcontainer {
  padding-top: 40px;
} 

.lazyWsdesignGrid_folderLeft {
  border-radius: 6px;
}
.lazyWs_designrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.lazyws_designBtn {
  width: 200px !important;
  padding: 17px 24px 17px 24px;
  border-radius: 6px;
}

.lazyWs_designrow2{
  padding-top: 40px;
}

.lazyws-trash-thumbnail {
    padding-top: 30px;
  }
.lazy-workspace-use-template-container, 
.lazy-workspace-move-project-container,
.lazy-workspace-copy-project-container {
  margin-top: 8px;
}
.lazy-workspace-template-personal {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px 0px 38px;
  gap: 12px;
}
.lazy-workspace-template-personal-title {
  width: 25%;
}
.lazy-workspace-template-personal-title-child {
  width: 30%;
}
.lazy-workspace-move-project-title-child {
  width: 35%;
}
.lazy-workspace-template-copy-project-child {
  width: 20%;
}
.lazy-workspace-template-personal-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px 0px 75px;
  gap: 12px;
}
.lazy-ws-member-container {

  .lazy-ws-member-row-two-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

    .lazy-ws-member-row-two-wrap-one {
      width: 50%;
    }
    .lazy-ws-member-row-two-wrap-two {
      width: 20%;
    }
    .lazy-ws-member-row-two-wrap-three {
      width: 15%;
    }
    .lazy-ws-member-row-two-wrap-four {
      width: 15%;
    }

    .lazy-ws-member-row-two-data-one {
      width: 183px;
      height: 17px;
    }
    .lazy-ws-member-row-two-data-two {
      width: 125px;
      height: 17px;
      margin-left: 20px;
    }
    .lazy-ws-member-row-two-data-three {
      width: 88px;
      height: 17px;
      margin-left: 20px;
    }
    .lazy-ws-member-row-two-data-four {
      width: 88px;
      height: 17px;
      margin-left: 20px;
    } 
  }
  .lazy-ws-member-row-three {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .lazy-ws-member-row-three-wrap-one {
      width: 5%;
    }
    .lazy-ws-member-row-three-wrap-two {
      width: 20%;
    }
    .lazy-ws-member-row-three-wrap-three {
      width: 25%;
    }
    .lazy-ws-member-row-three-wrap-four {
      width: 15%;
    }
    .lazy-ws-member-row-three-wrap-five {
      width: 15%;
    }
    .lazy-ws-member-row-three-wrap-six {
      width: 10%;
    }
    .lazy-ws-member-row-three-data-one {
      width: 60px;
      height: 20px;
    }
    .lazy-ws-member-row-three-data-two {
      width: 60px;
      height: 20px;
    }
    .lazy-ws-member-row-three-data-three {
      width: 88px;
      height: 20px;

    }
    .lazy-ws-member-row-three-data-four {
      width: 63px;
      height: 20px;

    }
    .lazy-ws-member-row-three-data-five {
      width: 55px;
      height: 20px;

    }
    .lazy-ws-member-row-three-data-six {
      width: 24px;
      height: 20px;
    }
  }
  .lazy-ws-member-row-four {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .lazy-ws-member-row-four-wrap-one {
      width: 5%;
    }
    .lazy-ws-member-row-four-wrap-two {
      width: 15%;
    }
    .lazy-ws-member-row-four-wrap-three {
      width: 30%;
    }
    .lazy-ws-member-row-four-wrap-four {
      width: 15%;
    }
    .lazy-ws-member-row-four-wrap-five {
      width: 15%;
    }
    .lazy-ws-member-row-four-wrap-six {
      width: 10%;
    }
    .lazy-ws-member-row-four-data-one {
      width: 100px;
      height: 17px;
      
    }
    .lazy-ws-member-row-four-data-two {
      width: 210px;
      height: 17px;
      margin-right: 30px;
    }
    .lazy-ws-member-row-four-data-three {
      width: 60px;
      height: 17px;
      
    }
    .lazy-ws-member-row-four-data-four {
      width: 35px;
      height: 17px;
      
    }
    .lazy-ws-member-row-four-data-five {
      width: 50px;
      height: 18px;
     
    }
    .lazy-ws-member-row-four-data-six {
      width: 24px;
      height: 18px;
    }
  }
}

.workspace-button {
  &.change-owner-button {
    width: fit-content !important;
    font-size: 16px !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}