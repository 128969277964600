.form-header.heading-section-dashboard {
  position: sticky;
  top: 0px;
  padding: 5px 100px;
  background: #ffffff;
  z-index: 999;

  .form-group>.input-style {
    font-family: "FontAwesome", "Rubik";
    background-color: none;
    background: none;
    position: relative;
  }

  .button-block {
    text-align: right;

    span {
      padding-left: 11px;
    }
  }

  .suggestion-box {
    display: none;
    position: absolute;
    width: 100%;
    top: 50px;
    background-color: #ffffff;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 300;
    z-index: 999;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05),
      0px 0px 5px rgba(0, 0, 0, 0.05);

    ul {
      padding-inline-start: 0px;
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;

      li {
        list-style: none;
        padding: 12px 16px;
        cursor: pointer;

        div {
          display: flex;
          justify-content: space-between;

          span {
            text-align: right;
            display: inline;

            img {
              width: 85%;
            }
          }

          p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-bottom: 0px;
            margin-bottom: 0px;
            height: 25px;
          }

          .info-templates {
            color: #ffffff;
            border: 1px solid #7a7a7a;
            background-color: #7a7a7a;
            font-size: 10px;
            font-weight: 400;
            padding: 10px;
            border-radius: 5px;
            width: 70px;
            margin-right: 12px;
          }

          .info-designs {
            color: #ffffff;
            font-weight: 400;
            font-size: 10px;
            padding: 10px 16px;
            background-color: #7a7a7a;
            border: 1px solid #7a7a7a;
            border-radius: 5px;
            width: 70px;
            margin-right: 12px;
          }
        }

        &:hover {
          background-color: #f6f6f6;

          .info-designs {
            background-color: #342bc2;
            border: 1px solid #342bc2;
          }

          .info-templates {
            background-color: #342bc2;
            border: 1px solid #342bc2;
          }
        }
      }
    }
  }

  .toggle-box {
    display: block;
  }
}

.dashboard-section {
  padding: 0px 100px 0 100px;
  text-align: left;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000000;
  }

  p {
    color: #5e5e5e;
  }

  .view-all {
    height: 40px !important;
    font-size: 14px !important;
    padding: 0 15px;
  }

  .tutorials {
    .heading {
      padding-bottom: 5px;

      h2 {
        font-size: 18px;
        color: #000000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;

        span {
          float: right;

          button {
            background: #f6f6f6;
          }
        }
      }
    }

    .grid-div {
      .video-div {
        border: 1px solid #f6f6f6;
      }

      .categories-list {
        cursor: pointer;
      }

      .img-div {
        border: 1px solid #e6e7e9;
        background-color: #f6f6f6;
        padding: 20px;
        cursor: pointer;
        width: 100%;
        overflow: hidden;
        display: flex;
        margin: auto;
        max-height: 200px;
        min-height: 200px;

        &:hover {
          border: 1px solid #342bc2;
          border-radius: 2px;
        }

        img {
          display: block;
          margin: auto auto;
        }
      }

      h4 {
        font-size: 16px;
        color: #000000;
        padding: 16px 0 0px 0;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        color: #9ca0a9;
      }
    }

    &.categories {
      padding-top: 40px;
      padding-bottom: 80px;

      .grid-div {
        padding-top: 43px;

        h4 {
          padding: 16px 0 0px 0 !important;
          margin-bottom: 5px;
          cursor: pointer;
        }

        .row-padding {
          padding-bottom: 40px;
        }
      }

      .search-category {
        margin: 0 10px 0 10px;

        .css-qc6sy-singleValue {
          color: #9ca0a9;
        }

        .css-319lph-ValueContainer {
          padding: 2px 0px 2px 8px;
        }

        .no-margin {
          padding: 0;
          margin: 0;
        }

        .form-group>.input-style {
          background: none;
          border-radius: 2px;
          border-bottom: 1px solid #e6e7e9;
          background-color: none;
          height: 50px;
          border-left: none;
          border-right: none;
          border-top: none;
          font-family: "FontAwesome", "Rubik";

          &:focus-within {
            border: none !important;
            border-bottom: 1px solid #e6e7e9 !important;
          }
        }
      }
    }
  }
}

.learnHover {
  cursor: pointer;

  &:hover {
    border: 1px solid #342bc2;
    border-radius: 2px;
  }
}

.slick-slider {
  .slick-arrow {
    display: block !important;
  }

  &:hover {
    .slick-arrow {
      display: block !important;
    }
  }

  .slick-next {
    z-index: 1;
    right: 0px;
    top: -42px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #e6e7e9;

    &::before {
      content: "\f105";
      font-family: "FontAwesome";
      color: #000;
      font-size: 15px;
      line-height: 0.5;
      font-weight: 600;
      display: inline-block;
      margin-right: -2px;
    }
  }

  .slick-prev {
    z-index: 1;
    width: 25px;
    height: 25px;
    top: -42px;
    border-radius: 50%;
    background-color: white;
    left: auto;
    right: 35px;
    border: 1px solid #e6e7e9;

    &::before {
      content: "\f104";
      font-family: "FontAwesome";
      color: #000;
      font-size: 15px;
      line-height: 0.5;
      font-weight: 600;
      display: inline-block;
      margin-left: -2px;
    }
  }
}

.css-1pu069y-option {
  &::before {
    content: "\f00c";
    color: "#342bc2";
    font-family: "FontAwesome", "Rubik";
    font-size: 16px;
    float: right;
  }
}

.project-limit-banner-container {
  padding-bottom: 36px;

  .project-limit-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 158, 42, 0.15);
    padding: 10px 20px;
    border-left: 3px solid #ff9e2a;
    border-radius: 2px;

    button {
      background-color: transparent;
      border: 1px solid #9ca0a9;
      border-radius: 2px;
      color: #000000;
      font-size: 12px;
      padding: 10px 20px;
    }

    .alert-icon-plus-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      p {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0;
        color: rgba(94, 94, 94, 1);
        font-size: 12px;

        span {
          color: var(--text-primary-color);
          // font-weight: 500;
        }

        .upgrade-now {
          color: var(--active-blue-color);
          cursor: pointer;
        }
      }
    }
  }
}

.css-1t4kpwk-option:active {
  background-color: #ffffff !important;
}

.css-4u73ap-option,
.css-1s84wwv-option {
  &::before {
    content: "\f00c";
    color: "#342bc2";
    font-family: "FontAwesome", "Rubik";
    font-size: 16px;
    float: right;
  }
}

.css-n8qycb-option:active,
.css-1su8503-option:active,
.css-coyjq5-option:active,
.css-1t4kpwk-option:active {
  background-color: #ffffff !important;
}

.css-hhnu5m-option,
.css-kfnnkm-option,
.css-8vy98u-option {
  &::before {
    content: "\f00c";
    color: "#342bc2";
    font-family: "FontAwesome", "Rubik";
    font-size: 16px;
    float: right;
  }
}

.css-m4z7df-option:active {
  background-color: #ffffff !important;
}

.sticky-header {
  // position: sticky;
  top: 0;
  padding: 50px 0 0px 0;
  background: #ffffff;
  z-index: 999;

}

.from-top-60 {
  top: 60px !important;
}

.designs-section {
  padding: 0px 100px 0px 100px;
  text-align: left;

  .no-padding {
    padding: 0px !important;
  }

  &.no-dashboards {
    // height: 100vh;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000000;
  }

  p {
    color: #5e5e5e;
  }

  .dropdown-btn {
    border: none;
    background: #ffffff;
  }

  .designs-header-section {
    padding-bottom: 20px;

    h2 {
      color: #000;
      font-size: 24px;
      padding-top: 5px;
    }

    .button-block {
      text-align: right;

      button {
        border: 1px solid #e6e7e9 !important;
        height: 44px !important;

        &.border-none {
          border-right: none !important;
        }
      }

      .notification-btn:hover {
        background-color: #fafafa !important;
      }

      span {
        padding-left: 11px;
      }
    }
  }

  .search-category-designs {
    margin: 0 10px 0 10px;

    .css-qc6sy-singleValue {
      color: #9ca0a9;
    }

    .css-319lph-ValueContainer {
      padding: 2px 0px 2px 8px;
    }

    .no-margin {
      padding: 0;
      margin: 0;
    }

    padding-bottom: 36px;

    .form-group>.input-style {
      background: none;
      border-radius: 2px;
      border-bottom: 1px solid #e6e7e9;
      background-color: none;
      height: 50px;
      border-left: none;
      border-right: none;
      border-top: none;
      font-family: "FontAwesome", "Rubik";

      &:focus-within {
        border: none !important;
        border-bottom: 1px solid #e6e7e9 !important;
      }
    }
  }

  .designs-grid {
    padding-bottom: 0px;

    .row-padding {
      padding-bottom: 20px;
    }

    .parent-design-container {
      .design-image {
        cursor: pointer;
        min-height: 260px;
        max-height: 260px;
        overflow: hidden;
        width: 100%;
      }

      h4 {
        cursor: pointer !important;
        width: 250px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
      }
    }

    .design-image {
      position: relative;
      border: 1px solid #e6e7e9;
      padding: 25px;
      background-color: #f6f6f6;
      display: flex;
      margin: auto auto;
      min-height: 260px;
      max-height: 260px;
      overflow: hidden;
      width: 100%;

      &:hover {
        border: 1px solid #342bc2;
        border-radius: 2px;
      }

      img.dashboard {
        display: block;
        margin: auto auto;
      }
    }

    h4 {
      font-size: 16px;
      color: #000000;
      padding: 15px 0 0 0;
      cursor: default;
    }

    p {
      font-size: 12px;
      color: #9ca0a9;
      cursor: default;
    }

    // span {
    //   float: right;
    //   margin: -25px 0 0 0;
    // }

    .page-count {
      position: absolute;
      right: 5px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ffffff;
      border: 1px solid transparent;
      background-color: rgba(62, 62, 62, 0.7);
      padding: 0px 7px;
      border-radius: 5px;
      top: 30px;

      img {
        margin: -1px 6px 0 0;
        max-width: 100% !important;
        width: 10px;
      }
    }

    .page-count-embedded-container {
      position: absolute;
      right: 5px;
      top: 5px;
      gap: 5px;
      display: flex;
      flex-direction: row-reverse;

      .embedded {
        display: flex;
        align-items: center;
        color: #ffffff;
        border: 1px solid transparent;
        background-image: linear-gradient(90deg, #8872f3 0%, #744efd 100%);
        padding: 1px 9px;
        border-radius: 5px;
        margin: 0px;
        gap: 5px;

        span {
          font-size: 12px;
          margin: 0px;
        }
      }

      .page-count {
        position: unset;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        border: 1px solid transparent;
        background-color: rgba(62, 62, 62, 0.7);
        padding: 1px 7px;
        border-radius: 5px;
        margin: 0px;

        img {
          margin: -1px 6px 0 0;
          max-width: 100% !important;
          width: 10px;
        }
      }
    }
  }

  .designs-list {
    padding-bottom: 80px;

    span.image-name {
      padding-right: 10px;

      .design-image-icon {
        display: block;
        margin: auto auto;
      }

      &.image-name {
        padding: 5px;
        background-color: #f6f6f6;
        margin-right: 8px;
        border: 1px solid #f6f6f6;
        min-height: 40px;
        max-height: 40px;
        overflow: hidden;
        width: 60px;
        display: flex;
        align-items: center;
      }
    }

    .list-view {
      thead {
        border-bottom: 2px solid #e6e7e9;

        th {
          padding: 0 0 20px 0;
          color: #000000;
          font-weight: 500;
          width: 100px;
        }
      }

      tbody {
        th {
          padding: 20px 0 20px 0;
          color: #000 !important;
          font-size: 14px;
          font-weight: 400;

          p {
            color: #000 !important;
          }

          p.item-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 170px;
            margin-top: 15px;
            cursor: pointer;
            color: #000 !important;
          }

          .flex-image {
            display: flex;
            justify-content: left;
            align-items: center;
          }
        }

        td {
          padding: 20px 0 20px 0;
          color: #5e5e5e;
          font-size: 14px;

          .center-align {
            padding-left: 25px;
          }
        }

        .dropdown-btn {
          margin: -7px 0 0 0;
        }
      }
    }
  }
  .listPadding {
      padding-bottom: 55px !important;
    }
  .no-design-parent-container {
    width: 100%;
    background-color: #f6f6f6;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3rem;
  }

  .no-design-child-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4.25rem;
    padding-bottom: 4rem;
  }


  .no-design-child-container p {
    font-size: 12px;
    font-family: Rubik;
    font-weight: "400";
  }

  .no-design-child-container h5 {
    margin-top: 1.75rem;
    margin-bottom: 0.75rem;
    font-size: 18px;
    font-weight: 500;
    font-family: Rubik;
  }

  .no-design-button-tag {
    width: 102px;
    height: 36px;
    font-size: 12px;
    border-radius: 2px;
    background-color: #342bc2;
    border: none;
    margin-top: 0.15rem;
    font-family: Rubik;
    transition: background-color 0.15s ease-in-out;
  }

  .no-design-button-tag:hover {
    background-color: #272092;
  }

  .no-design-block {
    border: 1px solid #e6e7e9;
    background: #f6f6f6;
    position: relative;
    height: calc(100% - 55px);
  }

  .no-designs {
    text-align: center;
    padding: 0px 0 60px;
    margin: 0px -40px 0 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    h5 {
      padding-top: 15px;
      font-size: 18px;
    }

    p {
      max-width: 600px;
      margin: 0 auto;
      font-size: 12px;
      padding-top: 2px;
      // padding: 16px 20px;
    }

    .gif-div {
      // width: 35%;
      text-align: center;
      margin: 0 auto;
      padding: 0 0 20px;
    }


    button {
      min-width: 35% !important;
      margin-top: 10px;
    }

    ~.pagination-block {
      display: none;
    }
  }
}

.pointer {
  cursor: pointer;
}

.search-close-btn {
  position: relative;

  &:focus-within {
    .search-close {
      display: block;
    }
  }

  &:hover {
    .search-close {
      display: block;
    }
  }
}

.search-close {
  position: absolute;
  top: 7px;
  right: 2px;
  outline: 0 !important;
  cursor: pointer;
  background: none !important;
  border: none !important;
  display: none;
}

.info-block {
  background-color: rgba(52, 43, 194, 0.08);
  border-radius: 2px;
  border: solid 1px #342bc2;
  margin-bottom: 35px;
  padding: 5px 0px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .row {
    align-items: flex-start;
  }

  h6 {
    font-size: 14px;
    line-height: 1.2;
    color: #000;
  }

  p {
    font-size: 12px;
  }

  button {
    min-width: 96px !important;
    height: 33px !important;
    font-size: 13px;
  }

  img {
    width: 50%;
  }

  .image-block {
    width: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    padding: 18px 0 0;
  }
}

.learn-section {
  padding: 50px 100px 0 100px;
  text-align: left;

  .learn-header-section {
    padding-bottom: 40px;

    h2 {
      color: #000;
      font-size: 24px;
      padding-top: 8px;
    }
  }

  .tutorials-block {
    h4 {
      font-size: 16px;
      color: #000000;
      padding: 15px 0 0px 0;
      cursor: pointer;
    }

    p {
      font-size: 12px;
      color: #9ca0a9;
      cursor: pointer;
    }

    .row-padding {
      padding-bottom: 40px;
    }

    &.articles-block {
      padding-bottom: 80px;
    }
  }
}

hr.pagination-hr {
  color: #e6e7e9;
  margin: 0px 100px 20px 100px;
  padding: 1px;
}

.pagination-block {
  padding: 0 100px 40px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .count-page {
    h6 {
      font-weight: normal;
      font-size: 13px;
      color: #9ca0a9;

      span {
        color: #5e5e5e;
      }
    }
  }

  ul {
    margin-bottom: 0;
  }

  .page-item {
    margin: 0 3px;
  }

  .page-item.active .page-link {
    background-color: #342bc2 !important;
    border-color: #342bc2 !important;
    color: #ffffff !important;
  }

  .page-link {
    color: #000000 !important;
    font-size: 13px;
    box-shadow: none;
    outline: none;
    line-height: 1.3;
  }
}

.search-bar {
  background-image: url("../assests/images/search.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 7px !important;
  padding: 5px 45px !important;

  &.suggestion {
    background-position: 20px !important;
    padding: 5px 25px 5px 60px !important;
  }
}

.lastIcon {
  height: 31.5px;
  padding: 5px;

  &::before {
    background-image: url("../assests/images/last-page.svg");
    background-size: 20px 20px;
    display: inline-block;
    width: 19.6px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
  }
}

.firstIcon {
  height: 31.5px;
  padding: 5px;

  &::before {
    background-image: url("../assests/images/first-page.svg");
    background-size: 20px 20px;
    display: inline-block;
    width: 19.6px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
  }
}

.prevIcon {
  height: 31.5px;
  padding: 5px;

  &::before {
    background-image: url("../assests/images/prev-page.svg");
    background-size: 20px 20px;
    display: inline-block;
    width: 19.6px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
  }
}

.nextIcon {
  height: 31.5px;
  padding: 5px;

  &::before {
    background-image: url("../assests/images/next-page.svg");
    background-size: 20px 20px;
    display: inline-block;
    width: 19.6px;
    height: 20px;
    content: "";
    background-repeat: no-repeat;
  }
}

.error-block-404 {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;

  h2 {
    font-size: 36px;
    padding-bottom: 30px;
    line-height: 43px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    padding-bottom: 30px;
  }

  .error-btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  img {
    width: 45%;
  }
}

.delete-confirm {
  padding: 10px;
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000000;
  }

  p {
    color: #5e5e5e;
  }

  label {
    color: #9ca0a9;
    font-size: 12px !important;
  }

  .close-buttons {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .checkbox {
    margin-top: 0px !important;
  }

  .content {
    padding-bottom: 15px;

    h4 {
      font-size: 14px;
      margin-top: 20px;
    }

    p {
      font-size: 12px;
    }
  }

  .check {
    text-align: left !important;

    .checkbox {
      margin-bottom: 15px;
    }
  }
}

.suggestion-box {
  .no-results {
    padding: 20px;
    margin-bottom: 0px;

    .no-block {
      padding: 50px 50px !important;

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

.video-slider {
  width: inherit;

  .slick-list {
    margin: 0 -15px;
  }

  .slick-slide>div {
    padding: 0 15px;
  }

  .video-enclose {
    border: 0px solid transparent;
    outline: none !important;

    .thumbnail-block {
      position: relative;
      cursor: pointer;

      img.play {
        position: absolute;
        top: 38%;
        width: 13%;
        left: 45%;
      }
    }
  }
}

.player-modal {
  max-width: 962px;
}

.player-block {
  padding: 0;
  position: relative;

  #template-videos {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
  }

  #template-videos iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .close-player {
    position: absolute;
    top: 0px;
    right: -40px;

    button {
      color: #ffffff;
      background-color: #060606;
      border: 1px solid #060606;
      outline: none !important;
      font-weight: normal;
      font-size: 12px;
      border-radius: 2px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: #ffffff;
        background-color: #060606;
        border: 1px solid #060606;
      }
    }
  }
}

.slides-tutorials {
  .grid-div.videos {
    width: inherit;
  }
}

.align-center {
  text-align: center;
}

.new-home-project-limit-banner {
  .fixed-project-limit-banner-container {
    position: static;
  }
}

.explore-templates-slider {

  .create-new-slider {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    .create-new {
      width: 31%;
      background-color: var(--active-blue-color);
      height: 190px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 2px;
      cursor: pointer;

      p {
        color: var(--text-active-color) !important;
        padding: 5px 0px;
        margin: 0px;
        font-size: 12px;
      }
    }

    .slider {
      width: 65%;
    }

  }




  .slick-list {
    margin: 0 -15px;
  }

  .slick-slide>div {
    padding: 0 15px;
  }

  .parent {
    p {
      color: var(--text-primary-color) !important;
      padding-top: 8px;
      font-size: 12px;
    }

    .child {
      border-radius: 2px;
      background-color: #f6f6f6;
      border: 1px solid #e6e7e9;
      cursor: pointer;
      // display: flex;
      // align-items: center;
      margin: auto;
      max-height: 168px;
      min-height: 168px;
      overflow: hidden;
      padding: 25px;
      position: relative;
      width: 100%;
    }
  }

  .slick-slider .slick-prev {
    z-index: 1;
    width: 25px;
    height: 25px;
    top: 42%;
    border-radius: 50%;
    background-color: white;
    left: -56%;
    right: 35px;
    border: 1px solid #e6e7e9;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  }

  .slick-slider .slick-next {
    z-index: 1;
    right: -12px;
    top: 42%;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #e6e7e9;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  }

  .slick-slider .slick-disabled {
    visibility: hidden !important;
  }
}

.home-resource-container {
  .view-all {
    border: unset !important;
    background-color: unset !important;
    color: var(--active-blue-color) !important;
    padding-right: 0px;
  }

  .home-resource-section {
    img {
      position: absolute;
      padding-right: 10px;
      top: 10%;
      right: 10px;
    }

    .heading-text {
      color: var(--text-primary-color);
      font-size: 14px;
    }

    .description {
      color: var(--text-secondary-color);
      font-size: 12px;
      max-width: 75%;
    }

    .content {
      padding: 24px 0px 24px 24px;
    }

    .home-resource-one {
      position: relative;
      background-color: rgba(52, 43, 194, 0.04);
      border: 1px solid rgba(52, 43, 194, 0.05);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        background-color: var(--active-blue-color);
        border-radius: 2px;
        font-size: 9.75px;
        padding: 6px 12px;
        margin-bottom: 14px;
        color: var(--text-active-color);
      }

      h6 {
        color: var(--active-blue-color);
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
        margin-bottom: 0px;
      }
    }

    .home-resource-two {
      position: relative;
      background: rgba(255, 158, 42, 0.07);
      border: 1px solid rgba(52, 43, 194, 0.05);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        background-color: var(--warning-orange);
        border-radius: 2px;
        font-size: 9.75px;
        padding: 6px 12px;
        margin-bottom: 14px;
        color: var(--text-active-color);
      }

      h6 {
        color: var(--warning-orange);
        text-decoration: none;
        font-size: 12px;
        cursor: pointer;
        margin-bottom: 0px;
      }
    }
  }
}

.new-custom-section {
  border-bottom: 1px solid var(--border-color);

  .search-bar {
    border: transparent !important;
    background-position: 0px !important;
    padding-left: 40px !important;
  }
}

.new-custom-design-section {
  .view-all {
    border: unset !important;
    background-color: unset !important;
    color: var(--active-blue-color) !important;
    padding-right: 0px;
  }
}

.edit-template-section {
  .view-all {
    border: unset !important;
    background-color: unset !important;
    color: var(--active-blue-color) !important;
    padding-right: 0px;
  }
}

.resource-filter-section {
  margin: 0px 0px 45px;

  .filter-container {
    .css-319lph-ValueContainer {
      padding-right: 0px;

      .css-qc6sy-singleValue {
        margin-right: 0px;
      }
    }

    .css-1s84wwv-option,
    .css-1su8503-option {
      padding: 10px 15px !important;
      // font-size: 12px !important;
    }

    .form-group {
      .css-4dsw8p-control {
        .css-319lph-ValueContainer {
          padding-right: 0px;

          .css-qc6sy-singleValue {
            margin-right: 0px;
          }
        }
      }

      .css-1uwauv8-control {
        .css-319lph-ValueContainer {
          padding-right: 0px;

          .css-qc6sy-singleValue {
            margin-right: 0px;
          }
        }

      }
    }

    .css-4dsw8p-control {
      height: 42px !important;
      // font-size: 16px !important;
      border: transparent;

      .css-qc6sy-singleValue {
        color: var(--text-tertiary-color) !important;
      }
    }

    .css-1uwauv8-control {
      height: 42px !important;
      // font-size: 16px !important;
      border: transparent;

      .css-qc6sy-singleValue {
        color: var(--text-tertiary-color) !important;
      }
    }
  }

  .heading {
    margin-bottom: 30px;
  }

  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: unset;
    border-top-right-radius: 0.25rem;
    position: relative;
    color: var(--text-tertiary-color);
    font-size: 16px;
    padding-bottom: 12px;
    cursor: pointer;

    .bar {
      display: flex !important;
      // position: absolute;
      position: relative;
      height: 4px;
      top: 12px;
      border: none;
      border-radius: 10px;
    }

    &:hover {
      .bar {
        background: #e9ecef;
      }
    }

    &.active {
      background-color: transparent;
      border-color: transparent;
      color: var(--text-primary-color);

      .bar {
        background: var(--active-blue-color);
      }
    }
  }
}

.resource-grid-section {
  margin-bottom: 40px;

  .res-card {
    position: relative;
    border-radius: 2px;
    margin-bottom: 20px;

    .duration {
      background-color: rgba(255, 255, 255, 0.1);
      position: absolute;
      top: 5px;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      padding: 6px 8px;
      gap: 4px;

      .timing {
        font-size: 10px;
        color: var(--text-active-color);
      }
    }

    .card-img-top {
      width: 100%;
      height: auto;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .card-body {
      padding: 16px 0;

      .tag-label {
        display: inline-block;
        font-size: 10px;
        padding: 4px 12px;
        border-radius: 11.5px;
        margin-bottom: 10px;
      }

      .card-title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .card-text {
        font-size: 12px;
        line-height: 1.4;
        color: var(--text-tertiary-color);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .tableau {
    color: var(--active-blue-color);
    background-color: rgba(52, 43, 194, 0.07);
  }

  .power-bi {
    color: var(--warning-orange);
    background-color: rgba(255, 158, 42, 0.12);
  }

  .in-app {
    color: var(--success-green);
    background-color: rgba(223, 245, 239, 1);
  }
}

.new-resource-container {
  .dashboard-section .tutorials .heading h2 {
    font-size: 24px;
  }
}

.home-edit-templates-lazy {
  .shimmer-thumbnail {
    margin-bottom: 6px;
  }

  .shimmer-title {
    margin-bottom: 0px;
    max-width: 40%;

    .shimmer-title-line {
      max-height: 14px;
    }
  }
}

.resource-grid-lazy {
  .shimmer-thumbnail {
    margin-bottom: 12px;
  }

  .shimmer-button {
    margin-bottom: 8px;
  }

  .shimmer-button--sm {
    height: 24px;
    width: 20%;
  }

  .shimmer-button--md {
    height: 20px;
    width: 40%;
  }

  .shimmer-button--lg {
    height: 28px;
    width: 100%;
  }
}

.projects-section-banner {
  .fixed-project-limit-banner-container {
    position: static;
  }
}

.project-header {

  .css-4dsw8p-control,
  .css-1uwauv8-control {
    border: none !important;
  }

  .css-n7zub7-menu {
    position: absolute;
    left: -106px;
  }

  .css-qc6sy-singleValue {
    color: #9ca0a9 !important;
  }

  .tabs {

    .css-1jqbu51-MenuList,
    .css-n7zub7-menu {
      width: 210px;
      padding: 10px 0px;
    }

    .css-319lph-ValueContainer {
      padding-right: 0px;

      .css-qc6sy-singleValue {
        margin-right: 0px;
      }
    }

    .nav-tabs {
      border-bottom: none;
      gap: 4px;

      .nav-item {
        padding-bottom: 5px;

        .nav-link {
          color: #9ca0a9;
          margin-top: 6px;
          padding-left: 1px;
          border: none;
          font-weight: 400;
          cursor: pointer;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
          position: relative;
          display: flex;
          gap: 0.25rem;
          align-items: flex-end;

          .bar {
            display: none;
          }

          .count {
            background: #9ca0a9;
            min-width: 25px;
            text-align: center;
            position: relative;
            top: -4px;
            width: 24px;
            height: 16px;
            vertical-align: middle;
            padding-top: 0.65px;
            padding-bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            color: #ffffff;
            border-radius: 8px;
          }

          .count-number {
            width: 24px;
            height: 16px;
            min-width: 25px;
            background: var(--text-tertiary-color);
            font-size: 8px;
            color: var(--text-active-color);
            border-radius: 8px;
            position: relative;
            line-height: 9px;
            position: relative;
            top: -4px;

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
            }
          }

          &.active {
            // font-weight: 500;
            color: #000000;
            border: none;

            // border-bottom: 4px solid #342bc2;

            .bar {
              display: flex !important;
              background: #342bc2;
              position: absolute;
              height: 4px;
              left: 0px;
              bottom: -5px;
              border: none;
              border-radius: 10px;
            }

            .count-number {
              background: #342bc2;
            }
          }

          &:not(.active):hover {
            .bar {
              display: flex !important;
              background: #e9ecef;
              position: absolute;
              height: 4px;
              left: 0px;
              bottom: -5px;
              border: none;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

.option-designs {
  z-index: 99999;
  position: relative;
}

.resource-filter-lazy {
  margin-bottom: 30px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .shimmer-button--sm {
    height: 30px;
    width: 10%;
  }

  .shimmer-button--md {
    height: 30px;
    width: 11%;
  }

  .shimmer-button--lg {
    height: 30px;
    width: 12%;
  }
}



.resources-wrapper {
  text-align: left;

  hr {
    background-color: #e6e7e9 !important;
    opacity: 1 !important;
  }

  .resources-header {
    position: sticky;
    top: 0;
    background: #ffffff;

    .tabs {
      .nav-tabs {
        border-bottom: 1px solid #e6e7e9;

        .nav-item {
          padding-bottom: 0.5rem;
          margin-right: 1.05rem;


          .nav-link {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-left: 0px;
            padding-right: 0px;
            font-size: 16px;
            color: var(--text-tertiary-color);
            border: none;
            cursor: pointer;
            transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;

            .plans-icon {
              width: 21px;
            }

            img {
              opacity: 0.4;
              padding-bottom: 4.8px;
              padding-right: 5px;
              width: 22px;
            }

            .bar {
              display: none;
            }

            &.active {
              .bar {
                display: flex !important;
                background: #342bc2;
                position: absolute;
                height: 4px;
                top: 43px;
                border: none;
                border-radius: 10px;
              }

              font-weight: 400;
              color: #000000;
              border: none;
              border-radius: 2px;

              img {
                opacity: 1;
              }
            }

            &:not(.active):hover {
              .bar {
                display: flex !important;
                background: #e9ecef;
                position: absolute;
                height: 4px;
                top: 43px;
                border: none;
                border-radius: 10px;

              }

            }
          }
        }
      }
    }
  }
}

.whats-new-column {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .whats-new-button-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    height: 17px;
    gap: 10px;

    .new-element-tag-search {
    position: relative;
    border-radius: 2px;
    font-size: 8px;
    bottom:2px;
    margin-right: 6px;
    padding: 3px 8px;
    color: var(--text-active-color);
    background-color: var(--warning-orange);
    }

    img {
      width: 24px;
      height: auto;
    }


    .whats-new-button {
      outline: none;
      border: none;
      margin-left: auto;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      background-color: transparent;
      .dropdown-arrow {
        position: relative;
        bottom: 1px;
      }
    }
  }
}

.partnerwithus-box {
  position: absolute;
  width: 348px;
  height: 370px;
  background-color: var(--text-active-color);
  border: 1px solid var(--border-color);
  right: 0;
  top: 5px;
  border-radius: 2px;
  box-shadow: 0px 5px 15px 25px #00000005;
}

.partnerwithus-main-container {
  .partnerwithus-header {
    text-align: left;
    padding: 20px 20px 0 20px;

    .partnerwithus-count-container {
      float: left;
      padding-top: 14px;
    }

    .btn-container-partner-with-us {
      max-width: 115px;
      padding-top: 1%;
      .partnerwithus-custom-style-btn {
        height: 40px !important;
        width: auto;
        font-family: Rubik !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 14.22px !important;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .partnerwithus-work-steps {
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.5%;

    }

    .partnerwithus-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      row-gap: 12px;

      .steps-box {
        display: flex;
        justify-content: left;
        gap: 14px;
      }

      .steps-box span {
        display: flex;
        width: 20px;
        height: 20px;
        background-color: var(--success-green);
        border-radius: 50%;
        text-align: center;
        padding: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        color: var(--text-active-color);
        justify-content: center;
        align-items: center;
      }
    }

    h3 {
      font-weight: 600;
      line-height: 16px;
      font-size: 14px;
    }

    h4 {
      font-weight: 500;
      line-height: 16px;
      font-size: 12px;
      margin-bottom: 10px;
    }

    p {
      font-size: 12px;
      color: var(--text-tertiary-color);
    }

    .partnerwithus-custom-style-btn {
      font-size: 14px !important;

      span img {
        height: 16px;
        width: 17px;
      }
    }

    .work-icon {
      height: 35px;
      width: 35px;
      background-color: var(--active-blue-color);
      border-radius: 50%;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
        }
      } 
    }
  }
}

.resources-details-main-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 6rem;

  .resources-details-mapped-container {
    // width: 252px;
    // height: 232px;
    padding: 23px;
    margin-bottom: 23px;
    border-radius: 2px;
    border: 1px solid #E6E7E9;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    img {
      width: 44px;
      height: 44px;
    }

    .resources-details-block {
      .resources-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        color: #000000;
      }

      .resources-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #9CA0A9;
        margin-bottom: 0rem;
      }
    }

    .resources-button {
      width: 100%;
      height: 40px;
      border-radius: 2px;
      border: 1px solid #E6E7E9;
      font-size: 12px;
      font-weight: 400;
      color: #5E5E5E;
      margin-top: 10px;
      background-color: transparent;
    }
  }
}

.resources-breadcrumbs {
  border-bottom: 1.25px solid #E6E7E9;
  margin-bottom: 15px;

  .steps-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding-bottom: 12.5px;

    p {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
    }

    span {
      color: rgba(156, 160, 169, 1);
      padding: 0px;
      display: flex;
      align-items: center;

      img {
        transform: scale(0.9);
      }
    }

    .step-container {
      display: flex;
      align-items: center;
      color: #000000;
      gap: 2px;

      .step-one-title {
        color: #342BC2;
        cursor: pointer;
      }

      .step-two-title {
        color: #9CA0A9;
      }
    }
  }
}

.Canny_Badge {
  position: absolute;
  top: 5px !important;
  right: 87px !important;
  transform: scale(0.65);
}

.navigation-link {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--brand-color);
  color: var(--brand-color);
}

.shared-projects-main-container {
  .card-footer-container {
    display: flex;
    align-items: center;

    .multiple-users-icon-plus-number {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      font-weight: 400;
      color: var(--text-tertiary-color);
      border: 1px solid var(--border-color);
      border-radius: 2px;
      padding: 0px 6px;
      justify-content: center;
      height: 22px;
      margin-left: auto;
      vertical-align: baseline;
    }
  }
}

.active-multiple-users-icon-plus-number {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-tertiary-color);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  padding: 0px 6px;
  justify-content: center;
  height: 22px;
  margin-left: auto;
  vertical-align: baseline;
}

.shared-with-tabs-container {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;

  .shared-with-me-label-container {
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      color: var(--text-tertiary-color) !important;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0rem !important;
      max-width: 125px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
  }
}

.dashboard-footer-container {
  display: flex;
  align-items: center;

  .dashboard-more-option-container {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.active-grid {
  &.dropdown-btn {
    padding-left: 8px !important;
    padding-right: 0px !important;
  }
}

.shared-dropdown-container {
  position: absolute;
  top: 55px;
  right: 125px;
  width: 248px;
  height: 248px;
  box-shadow: 8px 10px 32px 8px #342BC20F;
  z-index: 999 !important;
  background-color: var(--text-active-color);
  border: 1px solid var(--border-color);
  border-radius: 2px;

  .shared-hr {
    margin-top: 4px !important;
    margin-bottom: 8px !important;
    border: 1px solid var(--text-tertiary-color);
  }

  .shared-profile-tab-container {
    margin: 8px 8px 0px 8px;
    // background: #342BC20A;
    cursor: pointer;

    .shared-profile-tab {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 0px 16px 14px;
      height: 50px;

      .shared-switch {
        display: flex;
        align-items: center;
        height: 18px !important;
        width: 18px !important;
      }

      .shared-profile-person-details {
        display: flex;
        align-items: center;
        gap: 8px;

        .person-details {
          p:nth-child(1) {
            margin-bottom: 0rem !important;
            font-size: 12px;
            color: var(--text-primary-color);
            font-weight: 400;
            max-width: 132px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }

          p:nth-child(2) {
            margin-bottom: 0rem !important;
            font-size: 10px;
            color: var(--text-tertiary-color);
            font-weight: 400;
            max-width: 132px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }
        }

        .shared-spherical-initials {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background-color: var(--success-green);
          border-radius: 50%;
          color: var(--text-active-color);
          font-size: 11px;
          cursor: pointer;
          margin-bottom: 0rem !important;
        }
        
        .shared-spherical-initials-p {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background-color: #F77737;
          border-radius: 50%;
          color: var(--text-active-color);
          font-size: 11px;
          cursor: pointer;
          margin-bottom: 0rem !important;

          img {
            border-radius: 50%;
          }
        }

        .shared-spherical-initials-a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background-color: #F03A7D;
          border-radius: 50%;
          color: var(--text-active-color);
          font-size: 11px;
          cursor: pointer;
          margin-bottom: 0rem !important;
        }
      }
    }
    .shared-profile-tab:hover {
      background: #342BC20A;
    }
  }
}

.members-label {
  margin-left: 20px;
  margin-bottom: 0rem !important;
  font-size: 12px;
  font-weight: 400;
  color: #979797 !important;
}

.shared-members-block {
  height: 150px;
  overflow: auto;
  .shared-profile-tab-container {
    margin: 0px 8px 0px 8px;
    background: var(--text-active-color) !important;
  }
}

.shared-dropdown-arrow:hover svg path {
  fill: hsl(0, 0%, 60%);
}

.shared-dropdown-arrow.active:hover svg path {
  fill: hsl(0, 0%, 20%);
}