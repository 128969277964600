.bg-color {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .layout-wrappers {
    margin: auto;
    display: inline-block;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.bg-color {
  padding-top: 10px;
  // padding-bottom: 10px;
  // scroll-padding-top: 10px;
  // scroll-padding-bottom: 10px;
  background-color: var(--text-active-color);
}


.page-btn {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0px;
  background-color: var(--background-color);

  button {
    padding: 6px 20px;
    min-width: unset !important;
    height: 36.4px !important;
    width: unset !important;
    color: var(--text-secondary-color) !important;
    background-color: var(--background-color) !important;
    border-left: 1px solid var(--border-color) !important;
    border-right: 1px solid var(--border-color) !important;
    border-radius: 0px !important;
    border-top: 2px solid transparent !important;
    border-bottom: 2px solid transparent !important;
    white-space: nowrap;
    font-size: 13px;

    .crown {
      margin: 0px;
      margin-left: 10px;
      content: "";
      background-image: url("/assests/icons/crown-bg.svg");
      background-size: 100% 100%;
      display: inline-block;
      width: 15px;
      height: 15px;
      position: relative;
      top: 2px;
    }
  }

  .active {
    border-radius: 0px !important;
    color: var(--text-secondary-color) !important;
    background-color: var(--text-active-color) !important;
    border-top: 2px solid var(--border-color) !important;
    border-bottom: 2px solid var(--border-color) !important;
    border-left: 2px solid var(--border-color) !important;
    border-right: 2px solid var(--border-color) !important;
  }

  .first-page {
    border-left: 2px solid var(--border-color) !important;
  }

  .last-page {
    border-right: 1px solid var(--border-color) !important;
  }
}


.bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5.5px 20px;
  background-color: var(--background-color);
  height: auto !important;
  justify-content: space-between;
  align-items: center;

  .bottom-logo {
    color: var(--text-secondary-color) !important;
    background-color: var(--background-color) !important;
    border: 0px !important;
    cursor: pointer;
    margin-top: -3px;

    img {
      width: 90px;
    }
  }

  .active {
    color: var(--text-secondary-color) !important;
    background-color: var(--text-active-color) !important;
  }

  .quick-access {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .full-screen {
      cursor: pointer;
    }

    .page-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      color: var(--text-secondary-color);

      .previous,
      .next {
        cursor: pointer;
      }
    }
  }

  .fullscreen-div {
    width: 25px;
    text-align: right;
    margin-top: -2px;
  }
}

.share {

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      color: #000000;
    }
  }

  hr {
    color: #e6e7e9;
  }

  .closes-button {
    button {
      height: 30px !important;
      text-align: center;
      font-size: 11px;
      border-radius: 2px;
      color: #ffffff;
      background-color: #000000;
      border: 1px solid #000000;

      &:hover {
        color: #000000;
        background-color: #e9ecef;
        border: 1px solid #e9ecef;
        font-weight: 900;
      }
    }
  }

  .close-player {
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      height: 30px !important;
      text-align: center;
      font-size: 11px;
      border-radius: 2px;
      color: #ffffff;
      background-color: #000000;
      border: 1px solid #000000;

      &:hover {
        color: #000000;
        background-color: #e9ecef;
        border: 1px solid #e9ecef;
        font-weight: 900;
      }
    }
  }

  .nav-tabs .nav-item {
    width: 25%;
    text-align: center;
  }

  // .nav-tabs .nav-link {
  //   // border: 1px solid transparent;
  //   // border-top-left-radius: unset;
  //   // border-top-right-radius: 0.25rem;
  //   // position: relative;
  //   // color: var(--text-tertiary-color);
  //   // font-size: 14px;
  //   // padding-bottom: 12px;
  //   // cursor: pointer;

  //   .bar {
  //     display: flex !important;
  //     position: absolute;
  //     height: 4px;
  //     top: 38px;
  //     border: none;
  //     border-radius: 10px;
  //   }

  //   &:hover {
  //     .bar {
  //       background: #e9ecef;
  //     }
  //   }

  //   &.active {
  //     // background-color: transparent;
  //     // border-color: transparent;
  //     // color: var(--text-primary-color);

  //     .bar {
  //       background: var(--active-blue-color);
  //     }
  //   }
  // }
}

.embed-code-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  ~hr {
    opacity: 0.7 !important;
    margin-left: 20px;
    margin-right: 20px;
  }

  img {
    padding: 20px 0px 30px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-primary-color);
    padding-bottom: 14px;
    margin-bottom: 0px;
  }

  p {
    text-align: center;
    max-width: 350px;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: var(--text-tertiary-color);
  }

  span {
    color: var(--active-blue-color);
    cursor: pointer;
    font-size: 14px;
  }
}

.generate-token-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px !important;
  font-size: 14px !important;

  img {
    width: 20%;
  }
}

.url-link-container {
  padding: 0px 20px 20px !important;

  .note {
    color: var(--text-tertiary-color);
    font-size: 12px;
    padding-top: 12px;
    margin-bottom: 0px;
  }

  .un-publish-btn {
    color: var(--text-active-color);
    background-color: var(--error-red) !important;
    border-color: var(--error-red) !important;
    height: 45px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    img {
      width: 20%;
    }
  }

  .url-link-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding: 20px 0px 10px;

    .url-section {
      p {
        font-weight: 400;
        font-size: 14px;
        color: var(--text-primary-color);
        margin-bottom: 0.5rem;
      }

      span {
        color: var(--active-blue-color);
        font-size: 12px;
        cursor: pointer;
      }

      .url {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #e6e7e9;
        border-radius: 2px;
        padding: 5px 5px 5px 10px;

        p {
          font-size: 12px;
          padding-right: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          margin-bottom: 0px;
          color: var(--text-secondary-color);
        }

        button {
          font-size: 12px;
          padding: 6px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--brand-light-color);
          color: var(--active-blue-color);
          border: transparent;
          gap: 4px;
          width: 72px !important;

          &:hover {
            background-color: var(--brand-light-color);
          }
        }
      }
    }

    .link-section {
      p {
        font-weight: 400;
        font-size: 14px;
        color: var(--text-primary-color);
        margin-bottom: 0.5rem;
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #e6e7e9;
        padding: 5px 5px 5px 10px;
        border-radius: 2px;

        p {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          margin-bottom: 0px;
          padding-right: 2px;
          color: var(--text-secondary-color);
        }

        button {
          font-size: 12px;
          padding: 6px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--active-blue-color);
          background-color: var(--brand-light-color);
          gap: 4px;
          border: transparent;

          &:hover {
            background-color: var(--brand-light-color);
          }
        }
      }
    }
  }
}

.embed-error-container {
  .embed-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 34%;
      padding: 40px 0px;
    }

    h1 {
      font-size: 24px;
      color: var(--text-primary-color);
      margin-bottom: 1rem;
    }

    p {
      font-size: 16px;

      &:first-of-type {
        color: var(--text-tertiary-color);
        margin-bottom: 36px;
      }

      &:last-of-type {
        color: var(--text-primary-color);

        a {
          color: var(--active-blue-color);
          text-decoration: none;
        }
      }
    }
  }
}

.customScroll {
  -ms-overflow-style: none;
  // scrollbar-width: none;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 100vw;
  animation: 500s ease-in-out;
}

.customScroll::-webkit-scrollbar {
  display: none;
}

.scroll-buttons {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  background: white;
  position: relative;
  border-bottom: 1px solid var(--border-color);

  span {
    margin: 0 5px;
  }

  &::before {
    content: "";
    display: block;
    width: 0px;
    height: 40px;
    position: absolute;
    left: -2px;
    top: 0;
    box-shadow: -1px 3px 5px 2px #efeeeb !important;
  }

  .active {
    cursor: pointer;
    color: #323232;
    font-size: 20px !important;
    margin-top: 2px !important;
  }

  .notActive {
    color: #e3e3e3;
    font-size: 20px !important;
    margin-top: 2px !important;
  }

}



.fullscreen {
  height: 100%;
  overflow: hidden;
}

.embed-wrapper {
  background-color: var(--text-active-color);
}



.invite-wrapper {
  background-color: var(--text-active-color);

  &.pro-invite-footer {
    padding: 0px 0px 20px 0px !important;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }

  .project-link {
    padding: 20px 20px 0 20px;

    .url {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--border-color);
      border-radius: 2px;
      padding: 5px 5px 5px 10px;
      margin: 10px 0 0;
      height: 45px;
      
      p {
        font-size: 12px;
        padding-right: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        margin-bottom: 0px;
        color: var(--text-tertiary-color);
      }

      button {
        font-size: 12px;
        padding: 6px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--brand-light-color);
        color: var(--active-blue-color);
        border: transparent;
        gap: 4px;
        width: 72px !important;

        &:hover {
          background-color: var(--brand-light-color);
        }
      }
    }

    .enabling-share-link-container {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 15px;

      .enabling-invite-link-switch {
        cursor: pointer;
      }

      .enabling-invite-link-description {
        margin-bottom: 0;
        color: #000;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .invite-box {
    padding: 0 20px;

    .email-invites-role-container {
      display: flex;
      align-items: center;
      gap: 7px;

      .form-group {
        margin: 15px 0;
        display: flex;
        align-items: center;

        >div:first-child {
          width: 100%;
        }

        .input-style {
          height: 40px !important;
          width: 100%;
          font-size: 12px !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        .input-style::placeholder {
          font-size: 12px;
        }

        .role-distribution {
          border: 1px solid var(--border-color);
          border-left: none;
          border-radius: 0 !important;

          .dropdown-toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            height: 40px;
            background-color: transparent !important;
            color: var(--brand-color);
            outline: none;
            border: none;
            width: 100% !important;
            padding: 0 12px !important;
            font-size: 12px;
            box-shadow: -10px 0px 10px rgba(217, 217, 217, 0.4) !important;
          }
        }
      }

      .invite-button {
        font-size: 12px;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--brand-color);
        color: var(--text-active-color);
        border: transparent;
        gap: 4px;
        width: 72px !important;
        border-radius: 2px !important;
        height: 40px;

        &:hover {
          background-color: #272092;
          border: 1px solid #272092;
        }
      }
    }
  }

  .invitees-block {
    border: 1px solid var(--border-color);
    height: 230px;
    overflow: auto;
    margin: 0 20px;

    &.with-comment-state {
      display: flex;
      flex-direction: column;
    }

    .invitees-left-column {
      display: flex;
      gap: 14px;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      .invitees-details {
        padding-top: 2px;
      }

      .spherical-initials {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        background-color: var(--active-blue-color);
        border-radius: 50%;
        color: var(--text-active-color);
        font-size: 12px;

        img {
          border-radius: 50%;
        }
      }

      .invitees-details {
        p {
          margin-bottom: 0;
        }

        p:nth-child(1) {
          color: var(--text-primary-color);
          font-size: 12px;
          font-weight: 400;
        }

        p:nth-child(2) {
          color: var(--text-tertiary-color);
          font-size: 10px;
          font-weight: 400;
          line-height: 19.45px;
          margin-block-start: 0em;
        }
      }
    }

    .invitees-right-column {
      .commenting-map-dropdown {
        font-size: 12px;
        padding: 6px 10px;
        border: 1px solid var(--border-color);
        border-radius: 2px;
        color: var(--text-primary-color);
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 2.5px;

        &.owner-role {
          margin-bottom: 0 !important;
        }
      }
    }

    .invitees-mapped-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 14px;
    }

    .invitee-separator {
      border-bottom: 1px solid var(--border-color);
    }
  }

  .invite-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: var(--brand-light-color);
    // padding: 14px 20px;

    .commenting-footer-dropdown {
      margin-bottom: 0rem;
      font-size: 12px;
      font-weight: 400;
      color: var(--active-blue-color);
      cursor: pointer;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      gap: 2.5px;
      padding: 0;
    }
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 6px;

  div {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    background-color: var(--border-color);
    margin-bottom: 10px;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary-color);
  }

  p {
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    color: var(--text-tertiary-color);

    width: 271px;
    margin-bottom: 0rem;
  }
}

.commenting-dropdown {
  background-color: transparent;
  font-size: 20px;
  border: none;
  text-align: center;
  height: 38px;

  &:hover {
    color: var(--text-active-color);
    background-color: transparent;
    border: none;
  }

  &[aria-expanded='true'] {
    background-color: transparent;
    color: var(--text-active-color);
    border: none;
  }
}

.share-modal-body {
  padding: 20px 0px 0px 0px;
}

.share-elements-tabs {
  &.elements-tabs {
    margin: 0px 20px !important;
  }
}

.export-padding {
  &.export-wrapper {
    padding: 0 20px 20px !important;
  }
}

.embed-button {
  margin: 0px 20px 20px !important;

}

.embed-unpublish {
  &.prime-btn.un-publish-btn {
    background-color: #e50000 !important;
    border: 1px solid #e50000 !important;

    &:hover {
      background-color: #cf0a0a !important;
      border: 1px solid #cf0a0a !important;
    }
  }
}


.bi-export-download {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 20px;
  justify-content: center;

  .buttons-download {
    color: black;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #e6e7e9;
    background-color: #e6e7e9;
    margin: 0 30px;
  }
}

.share-project-container {
  display: flex;
  background-color: #F6F6F6;
  padding: 0;

  .share-left-container {
    width: 100%;
    background-color: #FFFFFF;

    .header {
      padding-top: 20px;

      .header-title {
        display: flex;
        gap: 5px;
        align-items: center;
      }

      .beta-badge {
        background-color: #22946B;
        color: #FFFFFF;
        font-size: 8px;
        line-height: 11px;
        border-radius: 2px;
        padding: 2px 4px;
      }
    }
  }

  .share-sidebar {
    min-width: 196px;
    padding: 20px 15px;

    .share-sidebar-header-title {
      padding-left: 10px;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
    }

    .elements-tabs {
      background-color: transparent;
      border-bottom: none !important;

      .nav-tabs {
        background-color: transparent;
        border-bottom: none !important;
      }
    }

    .share-tabs {
      flex-direction: column;

      .nav-item {
        width: 100%;

        .nav-link {
          padding: 10px 5px;
        }

        &:hover {
          .tab-icon {
            path {
              fill: #323232;
            }
          }
        }
      }
    }

    .share-tab-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .new-badge {
        background-color: #FF9E2A;
        color: #FFFFFF;
        padding: 2px 4px;
        border-radius: 2px;
        font-size: 8px;
        line-height: 10px;
        font-weight: 400;
      }
    }

    .share-tab-content-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      text-align: left;
      font-size: 12px;
      font-weight: 400;

      .tab-icon {
        &.selected {
          path {
            fill: #000000;
          }
        }
      }

    }
  }

  .powerbi-tableau-container {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;

    .section-title {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 500;
      color: var(--text-primary-color);
    }

    .section-subtitle {
      font-size: 12px;
      color: var(--text-tertiary-color);
      margin-bottom: 0;
      .section-info-icon {
        cursor: pointer;
        filter: grayscale(100%); 
        padding-left: 3px;
        padding-bottom: 2px;
      }
    }

    .powerbi-tableau-select-section {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .section-title-container {
        display: flex;
        flex-direction: column;
      }

      .powerbi-tableau-selection-container {
        display: flex;
        gap: 20px;

        .option-label {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;

          span {
            color: var(--text-tertiary-color);
          }
        }
      }

      .export-select-option {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 158px;
        height: 46px;
        border-radius: 2px;
        border: 1px solid #E6E7E9;
        background-color: #F6F6F6;
        margin-bottom: 5px;
        position: relative;
        cursor: pointer;

        &.active {
          border: 1px solid #342bc2;

          &::after {
            content: "\f058";
            font-family: "FontAwesome", "Rubik";
            position: absolute;
            color: #342bc2;
            background-color: #FFFFFF;
            border: none;
            font-size: 12px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            top: -8px;
            right: -8px;
          }
        }
      }
    }

    .select-section-container {
      display: flex;
      justify-content: space-between;
      .source-dropdown-menu {
        transform: translate3d(0, 38px, 0px);
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .source-dropdown-item:active {
        background-color: #342bc2 !important;
        color: #ffffff !important;
        border: 1px solid #342bc2 !important;
      }
      .source-dropdown-item.active {
        background-color: #342bc2 !important;
        color: #ffffff !important;
        border: 1px solid #342bc2 !important;
      }
      .sceens-dropdown-container {
        width: 280px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
        .screens-dropdown-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          .screens-dropdown-header-left-title {
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #9CA0A9;
            margin-bottom: 0;
          }
          .screens-dropdown-header-right-title {
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #5E5E5E;
            margin-bottom: 0;
            cursor: pointer;
          }
        }
        .screens-dropdown-subheader-container {
          max-height: 184px;
          overflow-y: auto;
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: var(--Grey-Line, #E6E7E9);
          }

          &::-webkit-scrollbar-track {
            background: transparent !important;
          }
          .screens-dropdown-subheader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .screens-checkbox {
              margin-right: 30px;
              margin-bottom: 8px;
            }
            .screens-dropdown-subheader-title {
              font-size: 12px;
              font-weight: 400;
              text-align: left;
              color: #5E5E5E;
              margin-bottom: 0;
            }
          }
          .screens-dropdown-menu {
            .screens-dropdown-option {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .screens-dropdown-menu-title {
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: #5E5E5E;
                margin-bottom: 0;
                max-width: 198px;
                max-width: 198px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .screens-checkbox {
                margin-right: 30px;
                margin-bottom: 8px;
              }
            }
          }
        }
      }

      .nav-item {
        width: unset;

        .nav-link.active {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      &.source-container {
        .title-container {
          flex: 1;
        }

        .role-distribution {
          flex: 1;
          border: 1px solid var(--border-color);
          border-radius: 2px;

          .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2px;
            background-color: transparent !important;
            color: var(--text-primary-color);
            outline: none;
            border: none;
            width: 100% !important;
            padding: 0 12px !important;
            font-size: 12px;
            border-radius: 2px;
          }
        }
      }
    }

    .credits-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .credits-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .help-link {
          text-decoration: none;
          color: var(--brand-color);
        }
      }

      .balance-container {
        background-color: #FF9E2A0F;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 10px 15px;
        border-radius: 2px;

        .credit-icon {
          width: 14px;
          height: 14px;
        }

        .info-icon {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }

        .balance-info {
          display: flex;
          align-items: center;
          gap: 4px;
          line-height: 14px;
        }

        .current-balance-tooltip-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 0;
        }

        .more-credits-text {
          font-size: 12px;
          color: #FF9E2A;
          margin-bottom: 0;
          text-decoration: none;
          cursor: pointer;
          &.disabled {
            opacity: 0.5;
          }
        }

        &.disabled-balance {
          background-color: #F6F6F6;

          .credit-text {
            color: #979797;
          }

          .credit-icon {
            filter: grayscale(100%);
          }

          .more-credits-text {
            color: var(--brand-color);
          }
        }
      }
    }

    .export-info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      margin-bottom: 12px;
    }

    .credit-text {
      font-size: 12px;
      color: #FF9E2A;
      margin-bottom: 0;

      &.balance-text {
        padding-left: 5px;
        padding-right: 2px;
      }
    }

    .export-button {
      background: linear-gradient(90deg, #2667B5 0%, #5930FD 100%);
      font-size: 14px;
      font-weight: 400;
      padding: 10px 20px;
      border: none;

      &.disabled-export {
        opacity: 0.5;
      }
    }

    .buy-credits-button {
      background-color: #FF9E2A;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 20px;
      border: none;
    }
  }

  .powerbi-tableau-intro-container {
    width: 480px;
    padding: 20px;

    .powerbi-carousel-wrapper {
      margin-bottom: 20px;

      .slick-dots li {
        width: 4px;
        cursor: default;

        button {
          cursor: default;
        }
      }

      .slick-dots li.slick-active button:before {
        color: #342BC2;
      }
    }

    .introduction-card-container {
      min-width: 440px; // to handle the carousel initial load
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .image-wrapper {
      img {
        // max-width: 384px;
        // border-radius: 4px;
      }

      video {
        width: 100%;
      }
    }

    .card-title {
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      margin-bottom: 0;
    }

    .card-description {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      color: #9CA0A9;
    }

    .action-footer {
      display: flex;
      flex-direction: column;

      .help-link {
        align-self: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
        text-decoration: none;
        color: var(--brand-color);
        padding: 10px
      }

      .action-button {
        width: 100%;
        background: linear-gradient(90deg, #2667B5 0%, #5930FD 100%);
        font-size: 14px;
        font-weight: 400;
        padding: 10px 20px;
        border: none;
      }
    }
  }

  .export-progress-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .image-container {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 120px;
      }

      &.empty-box-animation {
        position: relative;
        overflow: visible;

        img {
          position: absolute;
          min-width: 200px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .export-progress-bar-container {
      padding: 0 80px;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 12px;

      .export-progress-bar-background {
        width: 100%;
        height: 6px;
        background-color: #F6F6F6;
        border-radius: 3px;
        overflow: hidden;
      }

      .export-progress-bar {
        height: 100%;
        background-color: #342BC2;
        border-radius: 2px;
        transition: "width 0.2s ease-in-out",
      }
    }

    .export-progress-content {
      padding: 0 45px;
    }

    .export-progress-title {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .export-progress-description {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
      color: #9CA0A9;
    }

    .footer-container {
      margin-top: 30px;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .star-image {
      width: 15px;
      height: 15px;
      mix-blend-mode: luminosity;
      margin: -3px 5px 0px 0px;
    }

    .export-progress-footer {
      text-align: center;
      margin-bottom: 30px;
      font-size: 12px;
      font-weight: 400;
    }

    .export-action-button {
      width: 100%;
      border: none;
      background: linear-gradient(90deg, #2667B5 0%, #5930FD 100%);
      font-size: 12px;
      padding: 10px;
      font-weight: 400;
    }

    .feedback-text {
      text-align: center;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      color: var(--text-tertiary-color);
      padding: 10px;
      margin-bottom: 0px;

      span {
        cursor: pointer;
        color: var(--brand-color);
        font-weight: 500;
      }
    }
  }
}