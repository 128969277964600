.fixed-sidebar {
  max-width: 312px;
  width: 100%;
  flex: 0 0 312px;
  order: -1;
  width: 100%;
  height: 100%;
  // position: fixed;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  background: #ffffff !important;
  // scrollbar-gutter: stable;
  z-index: 9 !important;
  box-shadow: 0px -4px 5px 5px rgba(0, 0, 0, 0.02);
}

.layout-wrapper {
  margin-left: 315px;
  width: 100%;
}

.preview-canvas-wrapper {
  display: flex;
  overflow: hidden;
  background-color: #f2f2f2;
  height: 99vh;

  .builder-sidebar {
    max-width: 312px;
    flex: 0 0 auto;
    z-index: 999;
    order: -1;
  }

  .builder-canvas {
    overflow: auto;

    .layout-wrappers {
      margin: 0px 20px 50px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .side-wrapper {
    flex-basis: 312px;
    background-color: #ffffff;

    .css-tj5bde-Svg {
      fill: #323232;
    }
  }

  .canvas-space {
    position: relative;
    margin: 3px !important;
    display: flex;
    width: 100%;
    align-items: center;
    overflow: auto;
    cursor: grab;

    .layout-wrappers {
      margin: auto;
      display: inline-block;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      .screenName {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        padding-bottom: 2px;
        padding-left: 2px;
        text-align: center;
        color: #5e5e5e;
      }
    }
  }

  .canvas-space {
    -ms-overflow-style: none;
    // scrollbar-width: none;
  }

  .canvas-space::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-wrapper {
  .overflow-div {
    padding: 0 10px 0 10px;
  }

  .not-overflow-div {
    padding: 0 7px 0 12px;
  }

  .tools-tabs {
    padding-top: 10px;
    padding-bottom: 20px;
    position: sticky;
    top: 0px;
    z-index: 9;
    background-color: #ffffff;
    margin-left: -2px;
    justify-content: space-evenly;
    width: 312px;

    &.main-tabs {
      padding-bottom: 16px !important;
      justify-content: space-between !important;

      .nav-tabs {
        justify-content: space-evenly !important;

        .nav-item {
          padding-bottom: 0px !important;
          width: 104px;

          .nav-link {
            padding-bottom: 10px !important;
            padding: 0.5rem 1.4rem;
          }
        }
      }
    }

    .nav-tabs {
      border-bottom: 1px solid #dfdfdf;

      .nav-item {
        padding-bottom: 22px;

        .nav-link {
          font-size: 14px;
          color: #5e5e5e;
          border: none;
          cursor: pointer;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;

          &.active {
            font-weight: 500;
            color: #000000;
            border: none;
            border-bottom: 2px solid #000;
          }

          &:hover {
            border-bottom: 2px solid#000;
          }
        }
      }
    }
  }

  .elements-wrapper {
    padding-bottom: 20px;

    .search-form-block {
      padding-bottom: 12px;
      // position: sticky;
      // top: 67px;
      // top: 128px;
      // z-index: 9999;
      background-color: #ffffff;
      margin-left: 0px;

      .form-group {
        margin-bottom: 20px;

        input {
          height: 40px !important;
          font-family: "FontAwesome", "Rubik";
          background: #f6f6f6;
          border: solid 1px #f6f6f6;
          background-color: #ffffff;
          font-size: 14px;
        }

        input:not(:placeholder-shown) {
          background-color: rgba(52, 43, 194, 0.05);
          background: rgba(52, 43, 194, 0.05);
        }

        input::placeholder {
          font-size: 14px;
        }
      }

      .tools-btn-group {
        .slick-list {
          max-height: 45px !important;
        }

        .slick-next {
          top: 40%;
          z-index: 1;
          right: 0px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid rgba(73, 73, 73, 0.5);

          &::before {
            font-size: 13px;
          }
        }

        .slick-prev {
          top: 40%;
          z-index: 1;
          left: 0px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid rgba(73, 73, 73, 0.5);

          &::before {
            font-size: 13px;
          }
        }

        .tools-btn {
          height: 35px;
          border-radius: 2px;
          font-size: 12px;
          color: #000;
          background-color: #f6f6f6;
          border-color: #f6f6f6;
          margin: 0 7px 20px 5px;

          &.active {
            border-color: #342bc2;
            background-color: #342bc2;
            color: #fff;
          }
        }
      }
    }

    .elements-block {
      .filter-collapse {
        padding-bottom: 20px;

        .collapse-div {
          font-size: 12px;
          padding: 10px 0 0 0;
          text-align: right;
        }
      }

      .elements-grid {
        .row.row-margin {
          margin-right: -12px;
          margin-left: -10px;
        }

        .hover-block-element {
          display: none;
        }

        [draggable="true"] {
          -khtml-user-drag: element;
        }

        .elements-list {
          padding: 5px 0;
          position: relative;
          cursor: pointer;
          -webkit-user-drag: element;

          p {
            font-size: 12px;
            font-weight: 400;
            color: #5e5e5e;
            padding-top: 3px;
          }

          .elements-icon {
            position: relative;
            border: 1px solid #f6f6f6;
            background-color: #f6f6f6;
            padding: 20px;
            height: 80px;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            -ms-user-drag: none;

            img {
              max-width: 40%;
            }

            &.hover {
              .hover-block-element {
                border: 1px solid #d9d9d9 !important;
                width: 237px !important;
                height: 150px !important;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          &:hover {
            .elements-icon {
              border: 1px solid #342bc2;
            }

            .more-fav {
              display: block;
            }
          }

          .active-element {
            border: 1px solid #342bc2;

            &::after {
              content: "\f058";
              font-family: "FontAwesome", "Rubik";
              position: absolute;
              color: #342bc2;
              margin: -19px 0px 0 27px;
              font-size: 14px;
            }
          }

          .more-fav {
            display: none;
            position: absolute;
            top: 5px;
            z-index: 999;
            left: 2px;

            .dropdown-btn {
              border: none;
              background: #f6f6f6;
              height: 20px;
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .layouts-wrapper {
    padding: 0 0px 50px;

    .sticky-heading {
      position: sticky;
      top: 67px;
      background-color: white;
      padding: 0px 0 6px 0;
      z-index: 999;
      padding-bottom: 15px;
    }

    .success-btn {
      height: 40px;
      color: #ffffff;
      font-size: 14px;
      background-color: #342bc2;
      border: 1px solid #342bc2;
      border-radius: 2px;
      width: 100%;
    }

    h5 {
      font-size: 14px;
      text-align: left;
      padding: 15px 0;

      span {
        float: right;
        font-size: 12px !important;
      }
    }

    p {
      padding-top: 10px;
      font-size: 14px;
      text-align: left;
      padding-bottom: 16px;
      color: #000;

      span {
        float: right;
      }
    }

    .layouts-block {
      button.prime-btn {
        height: 35px !important;
        font-size: 14px;

        .change-layout {
          padding: 0 5px;
        }
      }
    }

    hr {
      background-color: #dfdfdf;
      opacity: 1;
      margin: 30px 0px;
    }

    .screens-block {
      padding-bottom: 00px !important;

      .form-group {
        margin-bottom: 25px;

        input {
          height: 40px !important;
          font-family: "FontAwesome", "Rubik";
          background: #ffffff;
          border: solid 1px #f6f6f6;
          background-color: #ffffff;
          font-size: 14px;
        }

        input:not(:placeholder-shown) {
          background-color: rgba(52, 43, 194, 0.05);
          background: rgba(52, 43, 194, 0.05);
        }

        input::placeholder {
          font-size: 14px;
        }
      }

      .tools-btn {
        width: 100%;
        height: 35px;
        border-radius: 2px;
        font-size: 12px;
        color: #000;
        background-color: #f6f6f6;
        border-color: #f6f6f6;

        &.active {
          border-color: #342bc2;
          background-color: #342bc2;
          color: #fff;
        }
      }

      .more {
        padding: 0px 5px;
        text-align: left;
        margin: 5px 14px 25px 5px;

        span {
          text-align: right !important;

          .dropdown-btn[aria-expanded="true"] {
            margin: -6px 0 0 7px;
            background-color: #ffffff;
            border: 1px solid #ffffff;
            color: #000000;

            &:focus {
              border: none !important;
              outline: 0 !important;
            }
          }

          .dropdown-btn {
            border: none;
            background: #ffffff;

            &:focus {
              border: none !important;
              outline: 0 !important;
            }

            img {
              max-width: 150%;
            }
          }
        }

        .file-name-input {
          background-color: #ffffff;
          border: 1px solid #ffffff;
          height: 25px;
          color: #000000;
          width: 50%;
          padding: 10px;
          border-radius: 2px;
          font-size: 14px;

          &:focus {
            background-color: #ffffff !important;
            border: 1px solid #ffffff !important;
            color: #000000 !important;
            outline: none !important;
          }
        }

        .edit-screen {
          display: flex;
          align-items: center;
          flex-direction: row;
          position: relative;

          &:hover {
            .edit-icon {
              display: block;
            }
          }

          p {
            margin-block-end: 0;
            margin-block-start: auto;
            padding: 0;
            display: inline-block;
            font-weight: 400;
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-width: 20px;
            width: 170px;
          }

          .edit-icon {
            display: none;
            position: absolute;
            right: -20px;
            top: -3px;
          }
        }

        .thumbnail {
          border: 1px solid #e6e7e9;
          display: flex;
          margin: auto auto;
          background: #f6f6f6;
          padding: 20px;
          min-height: 200px;
          max-height: 200px;
          overflow: hidden;
          width: 100%;
          position: relative;

          img {
            display: block;
            margin: auto auto;
          }
        }

        .screen-name-side {
          display: flex;
          align-items: center;
          padding-top: 10px;
          padding-left: 2px;
          cursor: pointer;
          justify-content: space-between;
          padding: 10px 0px 0 10px;

          &:hover {
            .edit-icon {
              display: block;
            }
          }
        }
      }
    }
  }

  .themes-wrapper {
    padding: 0 5px 50px;
    width: 312px;
    h5 {
      font-size: 14px;
      text-align: left;
      padding: 15px 0;

      span {
        float: right;
        font-size: 12px !important;
      }
    }

    p {
      padding-top: 10px;
      font-size: 14px;
      text-align: left;
      padding-bottom: 16px;
      color: #000;
      padding-left: 10px;
    }

    .theme-list {
      margin-bottom: 20px;
      border: 1px solid #e6e7e9;
      cursor: pointer;

      &:hover {
        border: 1px solid #342bc2;
      }

      // &.inactive::after {
      //   content: "";
      //   position: absolute;
      //   color: #ffffff;
      //   border: 1px solid #c1c0c0;
      //   margin: 10px 0 0 -25px;
      //   font-size: 14px;
      //   width: 14px;
      //   height: 14px;
      //   border-radius: 50%;
      // }

      .custom-theme-list {
        .theme-head-sub-head {
          padding: 10px 10px 30px 10px;

          .theme-list-head {
            font-size: 15px;
            text-align: left;
            margin-bottom: 0px;
            font-weight: 400;
          }

          .theme-list-sub-head {
            font-size: 12px;
            color: var(--text-tertiary-color);
            margin-bottom: 0px;
            text-align: left;
            padding: 0px;
            font-weight: 400;
          }
        }

        .color-palette {
          width: 100%;
          display: flex;
          height: 32px;
          background-color: var(--text-active-color);
        }
      }
    }

    .inactive-theme {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        color: #ffffff;
        border: 1px solid #c1c0c0;
        top: 8px;
        right: 8px;
        font-size: 14px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }
    }

    .active-theme {
      border: 1px solid #342bc2;
      pointer-events: none;

      position: relative;

      &::after {
        content: "\f058";
        font-family: "FontAwesome", "Rubik";
        position: absolute;
        color: #342bc2;
        top: 2px;
        right: 8px;
        font-size: 19px;
        border: none;
      }
    }

    button.prime-btn {
      height: 35px !important;
      font-size: 14px;

      .change-layout {
        padding: 0 5px;
      }
    }
  }
}

.types-createNew-wrapper {
  padding: 5px;

  .logo-header {
    font-size: 24px;
    padding: 10px 17px 40px;
    color: #342bc2;
    font-weight: 900;
    text-align: left;
  }

  .types-block {
    background: #ffffff;
    // border: 1px solid #e6e7e9;
    padding: 15px 20px;
    max-width: 920px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    .heading-block {
      padding: 40px 0 10px;

      p {
        padding-top: 10px;
        color: #5e5e5e;
        max-width: 469px;
        margin: 0 auto;
        font-size: 15px;
      }

      h4 {
        max-width: 520px;
        margin: 0 auto;
        font-size: 24px;
        color: #000000;

        span {
          position: absolute;
          width: 25px;
          margin: -2px 0 0 7px;
        }
      }
    }

    .row-padding {
      padding: 20px;
      max-width: 720px;
      margin: auto;

      .col-sm-3.col-md-3 {
        padding: 0px 6px;
      }

      .second-row {
        display: flex;
        justify-content: center;
        padding: 10px 0 0 0;

        .col-sm-3.col-md-3 {
          padding: 0px 6px;
          margin: 0px 5px 0 5px;

          .categories {
            .icons {
              width: 52.4%;
            }
          }
        }
      }

      .categories {
        border: 1px solid #e6e7e9;
        padding: 10px 5px;
        margin-bottom: 25px;
        cursor: pointer;

        .icons {
          padding: 25px;
          width: 50%;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }

        &.active {
          border: 1px solid #342bc1;
          background-color: #342bc2;

          p {
            color: #ffffff;
          }
        }
      }
    }

    .btns-group {
      padding: 30px;

      button {
        width: 23% !important;
        font-size: 15px;
      }

      p {
        padding: 20px 0 0;

        a {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }

  .polygon {
    position: absolute;
    width: 25px;
    right: 260px;
    margin: -35px 0px;
    z-index: -1;
  }

  .blue-curve {
    position: absolute;
    width: 142.44px;
    right: 25px;
    margin: 35px 0px;
    z-index: -1;
  }

  .grey-blocks {
    position: absolute;
    width: 140px;
    height: 160px;
    z-index: -1;
    left: 270px;
    bottom: 185px;
  }
}

.types-wrapper {
  padding: 5px;

  .logo-header {
    font-size: 24px;
    padding: 10px 17px 40px;
    color: #342bc2;
    font-weight: 900;
    text-align: left;
  }

  .types-block {
    background: #ffffff;
    border: 1px solid #e6e7e9;
    padding: 15px 20px;
    max-width: 920px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    .heading-block {
      padding: 40px 0 10px;

      p {
        padding-top: 10px;
        color: #adb5bd;
        max-width: 469px;
        margin: 0 auto;
        font-size: 15px;
      }

      h4 {
        max-width: 520px;
        margin: 0 auto;
        font-size: 24px;

        span {
          position: absolute;
          width: 25px;
          margin: -2px 0 0 7px;
        }
      }
    }

    .row-padding {
      padding: 20px;
      max-width: 720px;
      margin: auto;

      .col-sm-3.col-md-3 {
        padding: 0px 6px;
      }

      .second-row {
        display: flex;
        justify-content: center;
        padding: 10px 0 0 0;

        .col-sm-3.col-md-3 {
          padding: 0px 6px;
          margin: 0px 5px 0 5px;

          .categories {
            .icons {
              width: 52.4%;
            }
          }
        }
      }

      .categories {
        border: 1px solid #e6e7e9;
        padding: 10px 5px;
        margin-bottom: 25px;
        cursor: pointer;

        .icons {
          padding: 25px;
          width: 50%;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: #000;
        }

        &.active {
          border: 1px solid #342bc1;
          background-color: #342bc2;

          p {
            color: #ffffff;
          }
        }
      }
    }

    .btns-group {
      padding: 30px;

      button {
        width: 23% !important;
        font-size: 15px;
      }

      p {
        padding: 20px 0 0;

        a {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }

  .polygon {
    position: absolute;
    width: 25px;
    right: 260px;
    margin: -35px 0px;
    z-index: -1;
  }

  .blue-curve {
    position: absolute;
    width: 142.44px;
    right: 25px;
    margin: 35px 0px;
    z-index: -1;
  }

  .grey-blocks {
    position: absolute;
    width: 140px;
    height: 160px;
    z-index: -1;
    left: 270px;
    bottom: 185px;
  }
}

.builder-wrapper {
  padding: 56px 0 0 0;
  background: #f6f6f6;
}

.builder-header {
  color: #e6e7e9;
  background-color: #060515;
  display: flex;
  padding: 0px 15px;
  justify-content: space-between;
  font-size: 14px;
  position: fixed;
  align-items: center;
  z-index: 9999;
  min-width: 100%;
  height: 56px;

  .react-joyride {
    display: none;
  }

  .filename-back-btn {
    display: flex;
    align-items: center;
    width: 485px;

    // .back-icon-cus img {
    //   max-width: 0%;
    // }
  }

  .logo-div {
    .back-to-btn {
      height: 30px;
      width: 100%;
      font-size: 16px;
      background-color: #342bc2;
      border: 1px solid #342bc2;
      border-radius: 2px;
      font-weight: 900;
      padding: 0 10px;
    }
  }

  .title-div {
    text-align: left;
    padding: 0 15px;
    // display: flex;
    align-items: center;
    position: relative;

    &:hover {
      .edit-icon {
        display: block;
      }
    }

    .dropdown-btn {
      color: #fff;
      background-color: transparent;
      border: none;
      margin: -1px -6px 0px;
      height: 30px;
    }

    .file-name-input {
      background-color: #060515;
      border: 1px solid #060515;
      height: 20px;
      color: #ffffff;
      width: 30%;
      padding: 5px;
      border-radius: 2px;

      &:focus {
        background-color: #060515 !important;
        border: 1px solid #060515 !important;
        color: #f6f6f6 !important;
        outline: none !important;
      }
    }

    .edit-icon {
      display: none;
      margin-left: 5px;
      position: absolute;
      right: 0px;
      cursor: pointer;
    }

    p {
      margin-block-end: 0;
      margin-block-start: auto;
      padding: 0;
      // display: inline-block;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 90px;
    }

    p.workspace{
      font-size: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 90px;
    }
  }

  .quick-access {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      padding: 16px 15px;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.21);
      }
    }
  }

  .save-tour {
    img {
      cursor: pointer;
    }
  }

  .saving {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin: 0 5px;

    h6 {
      margin: 0 5px 0 0;
      padding: 0;
      font-weight: 400;
      font-size: 12px;
      opacity: 0.7;
      width: 105px;
      text-align: right;
    }
    .changes_saved_div {
      color: #6c757d;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      column-gap: 6px;
    }
    .save_changes_box {
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      column-gap: 6px;
    }
    .save_changes_button {
      background-color: transparent;
      border: none;
      border-color: transparent;
      font-size: 14px;

      p {
        position: relative;
        margin-left: 5px;
      }
    }
  }

  .export-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // max-width: 375px; //375px
    width: 315px;

    div {
      margin: 0 7px;
    }

    .edit-icons {
      span {
        margin: 0 7px;
        cursor: pointer;
      }
    }

    .export-btn {
      height: 36px;
      border-radius: 2px;
      color: #ffffff;
      background-color: #342bc2;
      font-size: 12.5px;
      border: 1px solid #342bc2;
      min-width: 97px;

      &:hover {
        background: #272092;
        border-radius: 2px;
        border: 1px solid #272092;
      }

      img {
        display: inline-block;
        margin: 0px 3px 3px 3px;
        padding: 0px 4px 0 0px;
        width: 21.5%;
        cursor: pointer;
      }

      &.bi-export{
        img {
          width: 22px;
        }
      }

      &.save {
        background-color: rgba(30, 30, 44, 0.70) !important;
        border: none !important;

        &:hover {
          background: #232339 !important;
          border-radius: 2px !important;
        }

        img {
          display: inline-block;
          margin: 0px 3px 2px 3px;
          padding: 0px 4px 0 0px;
          width: 25%;
        }
      }
    }
  }
}

.builder-modal {
  padding: 20px;

  .success-btn {
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    background-color: #342bc2;
    border: 1px solid #342bc2;
    border-radius: 2px;
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      color: #000000;
    }

    .closes-button {
      button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;

        &:hover {
          color: #000000;
          background-color: #e9ecef;
          border: 1px solid #e9ecef;
          font-weight: 900;
        }
      }
    }
  }

  hr {
    background-color: rgb(194, 194, 194);
  }

  .form-group {
    margin-bottom: 50px;

    .label-details {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      color: #5e5e5e;
    }

    .input-style {
      font-size: 14px !important;
      color: #000000;
    }
  }

  button {
    height: 44px !important;
  }
}

.data-table {
  .form-group {
    margin-bottom: 0 !important;

    .input-details {
      height: 25px !important;
      width: 75px;
    }
  }
}

.search-tools-block {
  position: relative;

  &:focus-within {
    .search-tools-close {
      display: block;
    }
  }
}

.search-tools-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  outline: 0 !important;
  cursor: pointer;
  background: none !important;
  border: none !important;
  display: none;
}

.css-8eey2q-option {
  &::before {
    content: "\f00c";
    color: "#342bc2";
    font-family: "FontAwesome", "Rubik";
    font-size: 12px;
    float: right;
  }
}

.css-96ftc3-option {
  &::before {
    content: "\f00c";
    color: "#342bc2";
    font-family: "FontAwesome", "Rubik";
    font-size: 12px;
    float: right;
  }
}

.css-1t9swte-option,
.css-1f1fwq2-option {
  &::before {
    content: "\f00c";
    color: "#342bc2";
    font-family: "FontAwesome", "Rubik";
    font-size: 12px;
    float: right;
    margin-left: 5px;
  }
}

.css-1dq4x0m-option:active,
.css-1fn74ph-option:active,
.css-101l4h4-option:active {
  background-color: #ffffff !important;
}

.css-ked351-option:active {
  background-color: #ffffff !important;
}

.css-155rkfn-option:active {
  background-color: #ffffff !important;
}

.update-changes {
  .css-1nmc9ph-menu {
    padding: 5px 2px;
  }
}

.details-wrapper {
  text-align: left;
  padding: 0px 12px 60px 8px;
  margin-bottom: 0px;
  width: 312px;
  position: relative;

  &.overflow-details {
    padding: 0 12px 60px 8px !important;
  }

  &.not-overflow-details {
    padding: 0 10px 60px 10px !important;
  }

  .sticky-heading {
    position: sticky;
    top: 0px;
    background-color: white;
    padding: 10px 0 10px 0;
    z-index: 10;
  }

  h4 {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;

    span {
      .closes-button button {
        height: 25px !important;
        line-height: 10px !important;
        width: 25px;
        padding: 0;
      }
    }
  }

  h5 {
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
    color: #000000;

    span {
      float: right;
      font-size: 12px !important;
    }
  }

  .element-variants {
    background-color: #f6f6f6;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .inputArrows {
    background-color: #f6f6f6 !important;

    &.inputArrows-dataInput {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .inputArrows::-webkit-inner-spin-button {
    background: #f6f6f6 url("../assests/icons/updown.svg") no-repeat 50% 50%;
    width: 14px;
    height: 14px;
    padding: 5px;
    position: relative;
    right: -15px;
    cursor: pointer;
  }

  .fw400 {
    font-weight: 400;
  }

  .color5E {
    color: #5e5e5e !important;
  }

  .scatter-table {
    th {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: center;
    }
    td {
      padding: 0;
    }
  }
  .form-group {
    margin-bottom: 1rem;

    .label-details {
      font-weight: 300;
      padding: 5px 0;
      font-size: 13px;
      color: #5e5e5e;
    }

    .input-details {
      background: #ffffff;
      border-radius: 2px;
      border: solid 1px #e6e7e9;
      background-color: #ffffff;
      padding: 5px 15px;
      border-radius: 2px;
      height: 40px !important;
      font-size: 12px;
    }
    .input-details-scatter {
      padding: 0 !important;
      border: none;
      text-align: center;
      background-color: #ffffff;
      background: #ffffff;
      height: 40px !important;
      font-size: 12px;
    }

    input::placeholder {
      font-size: 13px;
    }
  }

  .css-roihfz-control {
    &:hover {
      border: 1px solid #e6e7e9;
      box-shadow: none;
    }

    &:focus {
      border: 1px solid #e6e7e9;
      box-shadow: none;
    }
  }

  .success-btn {
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    background-color: #342bc2;
    border: 1px solid #342bc2;
    border-radius: 2px;
    width: 100%;

    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }
  }

  .btn-icon {
    padding: 0 5px;
    margin-top: -1px;
  }

  hr {
    background-color: #dfdfdf;
    opacity: 1;
    margin: 20px 0px;
  }

  .redirect-btn {
    height: 35px;
    width: 100%;
    color: #000;
    font-size: 13px;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 2px;

    &.stacked {
      margin-top: 20px;
    }

    &:hover {
      background-color: #ededed;
      border: 1px solid #ededed;
    }
  }

  .close-block {
    position: fixed;
    bottom: -17px;
    background-color: #ffffff;
    width: 267px;
  }

  .close-btn {
    font-size: 13px;
    color: #342bc2;
    border: 1px solid #342bc2;
    background-color: #fefefe;
    height: 35px;
    width: 100%;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 30px;

    &:hover {
      border: 1px solid #342bc2;
      background-color: #e3e2ea;
    }
  }

  .radio-style-checked {
    margin-top: 7px;
    border: 5px solid !important;
    border-color: #342bc2 !important;
    background-color: #ffffff !important;
    border-radius: 50%;
  }

  .radio-style-unchecked {
    margin-top: 7px;
    border: 2px solid;
    border-color: #5d5d5d;
    background-color: #ffffff;
    border-radius: 50%;
  }

  .activeToggle {
    color: var(--text-secondary-color) !important;
  }

  .inactiveToggle {
    color: #979797 !important;
  }

  .select-input {
    .css-14dclt2-Input {
      width: 20px;
    }
  }

  .list-values {
    padding: 5px 0;

    span {
      border: 1px solid #e6e7e9;
      border-radius: 20.5px;
      font-size: 14px;
      padding: 5px 15px 2px;
      margin: 5px;
      color: #000;
      background-color: #f6f6f6;
      text-overflow: ellipsis;
      white-space: break-spaces;
      word-break: break-all;
      display: inline-block;

      img {
        padding: 5px;
        margin-top: -3px;
      }
    }

    button {
      background-color: transparent !important;
      border: none !important;
    }
  }

  .switch {
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    img {
      -webkit-transition: opacity 0.8s ease-in-out;
      -moz-transition: opacity 0.8s ease-in-out;
      -o-transition: opacity 0.8s ease-in-out;
      transition: opacity 0.8s ease-in-out;
    }
  }

  .upload-logo-label {
    cursor: pointer;
    background-color: #342bc2;
    color: white;
    font-size: 14px;
    padding: fit-content;
    height: 30px;
    padding: 5px;
    width: 100px;
    text-align: center;
  }

  .remove {
    border-radius: 2px;
    border: solid 1px #d7d7d7;
    background-color: #ffffff;
    color: rgb(92, 92, 92);
    font-size: 14px;
    height: 30px;
    width: 35%;
    float: right;
    padding: 0;
  }

  .cancel-input {
    position: relative;

    img {
      position: absolute;
      top: 39px;
      right: 15px;
    }
  }

  .reset-data {
    position: absolute;
    right: 7px;
    top: 36px;
  }

  .align-label {
    padding: 10px 0 5px !important;
  }

  .img-margin {
    margin-top: 5px;
  }

  .range-mb {
    margin-bottom: -16px !important;
  }

  .checkbox-slider {
    .form-check-input:checked {
      background-color: #b1ade7;
      border-color: #b1ade7;
      width: 30px;
      height: 16px;
      border-radius: 14.3px;
      background-image: url(../assests/images/blue-circle.svg) !important;
      background-repeat: no-repeat;
      background-position: right;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      margin-top: 10px;
    }

    .form-check-input {
      background-color: #ffffff;
      border-color: #e6e7e9;
      width: 30px;
      height: 16px;
      border-radius: 14.3px;
      background-image: url(../assests/images/grey-circle.svg) !important;
      background-repeat: no-repeat;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      margin-top: 10px;
    }
  }

  .select-input.error-div .form-control {
    border: solid 1px var(--error-red) !important;
    border-left: none !important;
    border-radius: 0 !important;
  }

  .select-input .form-control {
    border-left: none !important;
  }

  .dateFilter {
    input {
      font-size: 13px !important;
      font-weight: normal !important;
      border-radius: 0 !important;
      border: solid 1px #e6e7e9;
      background-color: #ffffff !important;
      padding: 5px 15px !important;
      height: 40px !important;
      background: #ffffff !important;

      &.error {
        border: solid 1px var(--error-red) !important;
      }

      .select-input {
        .form-control {
          border-left: none !important;
        }
      }
    }
  }

  .error-filter {
    border: solid 1px var(--error-red) !important;

    &:focus-within {
      border: solid 1px var(--error-red) !important;
    }
  }

  .error-msg {
    color: var(--error-red);
    font-size: 12px;
    margin-top: 1rem;
  }

  .chart-details {
    padding: 0 10px 0 5px;
  }

  .chart-data-grid {
    input {
      height: 25px !important;
      border: none !important;
    }

    tbody {
      th {
        border: 1px solid #e6e7e9;
      }

      td {
        border: 1px solid #e6e7e9;
        font-size: 14px;
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    thead {
      border: 1px solid #e6e7e9 !important;
      border-bottom: 2px solid #e6e7e9 !important;

      th {
        border: 1px solid #e6e7e9 !important;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  .grid-table {
    position: relative;
    width: 99%;
  }

  .rows-icon {
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin-top: 10px;
  }

  .add-rows-icon {
    display: block;
    text-align: center;
    padding: 0 5px 0;

    img {
      width: 80%;
    }

    &.delete {
      img {
        width: 80%;
      }
    }
  }

  .cols-icon {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    top: 39%;
    right: -1%;
  }

  .add-cols-icon {
    cursor: pointer;

    img {
      width: 80%;
    }

    &.delete {
      img {
        width: 80%;
      }
    }
  }
}

.details-wrapper .chart-data-grid .form-group .input-details {
  height: 25px !important;
  border: 1px solid transparent !important;
  padding: 1.25rem 0.7rem !important;
  text-align: start;
  border-radius: 0;

  &:focus {
    background: #efeffb !important;
    border: 1px solid #3238c7 !important;
    padding: 1.25rem 0.7rem !important;
    outline: 0 !important;
    height: 25px !important;
  }

  &.text-highlight {
    font-weight: 500 !important;
  }
}

.export-wrapper {
  padding: 0;

  hr {
    opacity: 0.7;
  }

  h4 {
    font-size: 16px;
    color: #000;
  }

  .export-header {
    padding: 20px 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .format-block {
    padding: 8px 0px;

    .export-format-container {
      margin-right: 20px;
    }

    .formats {
      width: 225px !important;
      background-color: var(--background-color);
      display: flex;
      border-radius: 2px;
      justify-content: space-evenly;
      padding: 2px 0px;
    }

    h4 {
      font-size: 14px;
      margin-bottom: 0.25rem;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      color: var(--text-tertiary-color);
      margin-bottom: 0px;
    }

    .format {
      border: 1px solid #f6f6f6;
      text-align: center;
      background-color: #f6f6f6;

      .icons {
        padding: 20px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: var(--text-tertiary-color);
        padding: 6px 22px;
        cursor: pointer;

        &.active {
          color: #000;
          background-color: var(--text-active-color);
        }
      }

      &.active {
        // border: solid 1px #342bc2;
        color: var(--text-primary-color);
        background-color: rgba(52, 43, 194, 0.08);
      }
    }
  }

  .project-block {
    padding: 20px 0px 8px;

    .all-screen-text,
    .current-screen-text {
      font-weight: 400;
      font-size: 12px;
      color: var(--text-primary-color);
      margin-bottom: 0px;
    }

    .all-screen {
      padding: 12px 8px 0px 8px !important;
      margin-bottom: 5px !important;
    }

    .current-screen {
      padding: 20px 8px 0px 8px !important;
      margin-bottom: 5px !important;
    }

    .sub-head {
      font-weight: 400;
      font-size: 12px;
      color: var(--text-tertiary-color);
      margin-bottom: 0.3rem;
    }

    h4 {
      font-size: 14px;
      margin-bottom: 0.25rem;
    }

    .project {
      border: 1px solid #f6f6f6;
      text-align: center;
      background-color: #f6f6f6;
      margin-bottom: 25px;
      margin-top: 10px;
      font-size: 14px;
      padding: 8px;
      color: #5e5e5e;

      &.active {
        border: solid 1px #342bc2;
        color: #000000;
        background-color: rgba(52, 43, 194, 0.08);
        position: relative;

        &::after {
          content: url(../assests/icons/blue-tick.svg);
          // background-image: url(../assests/icons/blue-tick.svg);
          background-color: var(--text-active-color);
          width: 14px;
          height: 14px;
          position: absolute;
          background-size: 100% 100%;
          top: -7px;
          right: -7px;
        }
      }
    }
  }

  .export-button-block {
    // padding: 0px 30px 20px;

    button {
      height: 45px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      img {
        width: 20%;
      }
    }

    .project-limit-banner-container {
      padding-bottom: 0px;

      .project-limit-banner {
        padding: 14px 18px;

        display: block !important;

        .alert-icon-plus-text {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 12px;

          img {
            width: 4%;
          }
        }
      }
    }
  }

  &.message-export {
    padding: 20px 25px 0px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 18px;
        margin: 0;
      }

      .closes-button {
        button {
          height: 30px !important;
          text-align: center;
          font-size: 11px;
          border-radius: 2px;
          color: #ffffff;
          background-color: #000000;
          border: 1px solid #000000;

          &:hover {
            color: #000000;
            background-color: #e9ecef;
            border: 1px solid #e9ecef;
            font-weight: 900;
          }
        }
      }
    }

    hr {
      color: #e6e7e9;
      margin: 15px 0 15px;
    }

    .export-success {
      padding: 0;

      h3 {
        padding: 20px 0 5px;
        font-size: 18px;
      }

      p {
        font-size: 14px;
        color: #494949;
        width: 292px;
        text-align: center;
        margin: 0px auto 27px;
      }
    }

    .export-button-block {
      padding: 0px 0px 20px;
    }
  }

  .success-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.export-success {
  padding: 30px;
  text-align: center;

  img {
    width: 25%;
  }

  h3 {
    padding: 30px 0;
    font-size: 22px;
    width: 340px;
    margin-left: auto;
    margin-right: auto;
  }

  .btns-group {
    button {
      font-size: 15px;
    }

    p {
      padding: 20px 0 0;
      color: #342bc2;
      cursor: pointer;
    }
  }
}

.elements-tabs {
  background-color: #d9d9d9;
  background-color: var(--menu-slider-color);
  border-bottom: 1px solid var(--menu-slider-color) !important;
  // margin-bottom: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  border-radius: 4px;
  justify-content: space-evenly;

  .nav-tabs {
    border-bottom: 1px solid var(--menu-slider-color);
    background-color: var(--menu-slider-color);
    width: 100%;

    .nav-item {
      padding: 1px 4px 0px;
      border-radius: 2px;
      width: 50%;

      .nav-link {
        font-size: 13px;
        color: #979797;
        border: none;
        cursor: pointer;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out !important;

        &.active {
          font-weight: 400;
          color: #000000;
          border: none;
          border-radius: 4px;
          padding-left: 25px;
          padding-right: 26px;
        }

        &:hover {
          color: #000;
        }
      }
    }
  }
}

.live-demo-dashboard {
  width: 20%;
  margin: 0px auto 15px auto;
  text-align: center;
}

.input-case {
  text-transform: capitalize !important;
}

.active-screen {
  border: 1px solid #342bc2 !important;

  &::after {
    content: "\f058";
    font-family: "FontAwesome", "Rubik";
    position: absolute;
    color: #342bc2;
    font-size: 16px;
    border: none;
    top: 0;
    right: 5px;
  }
}

.__react_component_tooltip {
  pointer-events: auto !important;
  z-index: 99999999 !important;
}

.screen-bar {
  bottom: -1px;
  position: fixed;
  width: calc(100% - 312px);
  background: #ffffff;
  left: 312px;
  z-index: 999;
}

.footer-wrapper {
  z-index: 999;

  .screen-tabs {
    display: flex;

    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }

    ul {
      li {
        list-style-type: none;

        a {
          text-decoration: none;
        }
      }
    }

    .nav-tabs {
      border-bottom: none;

      .nav-item {
        .nav-link {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          font-size: 14px;
          color: #979797;
          border: none;
          padding: 0.1rem 0rem;

          .screen-option {
            padding-right: 8px;
            padding-left: 5px;
          }

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
            margin-block-end: 0;
            margin-block-start: 0;
            font-size: 14px;
            width: 85px;
            color: #5e5e5e;
            padding-left: 20px;
          }

          &.active {
            color: #ffffff;
            border: none;
            background-color: #342bc2 !important;
            border-radius: 2px;

            p {
              color: #ffffff;
            }

            .dropdown-btn[aria-expanded="true"] {
              color: #ffffff;
            }

            .dropdown-btn {
              color: #ffffff;
              padding-bottom: 4px;
              margin-bottom: 4px;
              padding-left: 16px;
              padding-right: 8px;

              &:hover {
                background: rgba(255, 255, 255, 0.3);
                border-radius: 2px;
                padding-left: 16px;
                padding-right: 8px;
              }
            }
          }

          span {
            font-size: 10px;
            padding: 0 0 0 3px;

            .btn {
              line-height: 0.9;
            }

            .dropdown-btn[aria-expanded="true"] {
              background-color: transparent;
              border: 1px solid transparent;
              color: #979797;
              margin-bottom: 4px;

              &:focus {
                border: 1px solid transparent !important;
                outline: 0 !important;
                color: #ffffff;
              }
            }

            .dropdown-btn {
              border: 1px solid transparent;
              width: 10px;
              background: transparent;
              color: #979797;
              margin-bottom: 4px !important;
              padding: 6px 8px 2px 16px !important;

              &:focus {
                border: 1px solid transparent !important;
                outline: 0 !important;
                color: #ffffff;
              }

              i {
                font-size: 14px;
                margin-top: 0px;
              }
            }
          }
        }
      }
    }

    span {
      padding: 0 0px;
      margin-top: 7px;
      margin-bottom: 2px;
    }
  }

  .borderRight {
    border-right: 1px solid #dfdfdf;
  }

  .footer-tools {
    // text-align: right;
    padding: 5px 5px 2px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05),
      0px 0px 5px rgba(0, 0, 0, 0.05);
    border-left: 1px solid #dfdfdf;
    > :not(.new-chatbot) > :not(.chatbot) img {
      margin: 3px 8px;
      padding: 2px 3px;
      width: 20px;
      margin-top: 2px !important;
      cursor: pointer;

      &:hover {
        background: #ececec;
        border-radius: 2px;
      }
    }

    .new-chatbot-style {
      margin: 4px 0px 4px 0px;
      padding: 0px 5px;
      width: 30px;
      margin-top: 2px !important;
      cursor: pointer;

      &:hover {
        background: #ececec;
        border-radius: 2px;
      }
    }

    span.divider {
      color: rgba(236, 236, 236, 1);
      padding: 0 18px;
      margin-top: -3px;
    }
  }

  .footer-block {
    display: flex;
    border: 1px solid rgba(223, 223, 223, 0.5);
    box-shadow: 0px -4px 5px 5px rgba(0, 0, 0, 0.02);

    .screen-names {
      flex-basis: calc(100% - 320px);
    }

    .tools-block {
      flex: 0 0 190px;
      position: absolute;
      right: -10px;
    }
  }
}

.label-range-title {
  padding-left: 26px !important;
}

.start-end-range {
  position: relative;

  .middle-arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #ededed;
    padding: 0px;
    top: 10px;
    right: 45%;
    color: #ededed;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .start-range {
    padding-right: 0;

    input {
      border-radius: 0px !important;
      border-bottom-left-radius: 2px !important;
      border-top-left-radius: 2px !important;
      border-right-color: transparent !important;
    }
  }

  .end-range {
    padding-left: 0;

    input {
      border-radius: 0px !important;
      border-bottom-right-radius: 2px !important;
      border-top-right-radius: 2px !important;
    }
  }
}

.hideScroll {
  -ms-overflow-style: none;
  // scrollbar-width: none;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 54vw;
  animation: 500s ease-in-out;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.previewScroll {
  -ms-overflow-style: none;
  // scrollbar-width: none;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 54vw;
  animation: 500s ease-in-out;
}

.previewScroll::-webkit-scrollbar {
  display: none;
}

.moreOption-svg {
  margin-left: -8px;
}

.footer-wrapper
  .screen-tabs
  .nav-tabs
  .nav-item
  .nav-link
  span
  .dropdown-menu.show {
  will-change: transform;
  bottom: 50px !important;
  left: auto !important;
  top: auto !important;
  position: fixed !important;
}

.footer-tools {
  .active {
    cursor: pointer;
    color: #323232;
    font-size: 20px !important;
    margin-top: 2px !important;
  }

  .notActive {
    color: #e3e3e3;
    font-size: 20px !important;
    margin-top: 2px !important;
  }
}

.preview-wrapper {
  background-color: #f6f6f6;
  height: 100vh;

  .preview-header {
    background-color: #ffffff !important;
    color: #000000 !important;
    border-bottom: 1px solid #e6e7e9;

    .export-div .export-btn.save {
      background-color: #060515;
      color: #ffffff;

      &:hover {
        background-color: #5d5d5d !important;
        color: #ffffff;
      }
    }

    .logo-div .back-to-btn {
      background-color: #ededed !important;
      color: #000000 !important;
      border: 1px solid #ededed !important;
    }
  }

  .image-wrapper-preview {
    padding: 30px;
    display: flex;
    width: 100%;
    align-items: stretch;
    background-color: #f6f6f6;
    justify-content: center;
    order: -1;

    .fullscreen {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .toggle-btn {
      height: 25px;
      color: #060515;
      background-color: #ffffff;
      border: 1px solid #f6f6f6;
      padding: 2px 7px;
      line-height: 8px;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05),
        0px 0px 5px rgba(0, 0, 0, 0.05);
      left: 320px;
      z-index: 999999;

      i {
        font-weight: 600;
        font-size: 15px;
      }
    }

    .sidebar-collapse-preview {
      min-width: 270px;
      max-width: 270px;
      min-height: 100vh;
      padding: 0 10px;
      transition: all 0.3s;
      background-color: #ffffff;
      z-index: 9999;
      position: fixed;
      left: 0;
      height: 100vh;
      z-index: 999;
      overflow: auto;

      &.active {
        margin-left: -270px;
      }

      .more {
        padding: 20px 5px;
        text-align: left;

        p {
          margin-block-end: 0;
          margin-block-start: auto;
          padding: 5px 0 0;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          min-width: 20px;
        }

        .thumbnail {
          border: 1px solid #e6e7e9;
          display: block;
          margin: 0 auto;
          background: #f6f6f6;
          padding: 20px;

          &.active {
            border: 1px solid #342bc2;
          }
        }

        .row-pad {
          padding-top: 5px;
        }
      }
    }

    .main-content {
      overflow: auto;
      width: 100%;
      background-color: #000000;
      max-height: 100%;
      transition: all 0.3s;
      display: flex;
      justify-content: center;

      .quick-access {
        position: fixed;
        bottom: 60px;
        padding: 10px 10px;
        background-color: #060515;
        left: 40%;
        color: #ffffff;

        span {
          margin: 0 13px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .sidebar-collapse-preview {
      margin-left: -320px;
    }

    .sidebar-collapse-preview.active {
      margin-left: 0;
    }
  }

  .image-wrapper {
    padding-top: 57px;
    background-color: #f6f6f6;

    .img-block {
      padding: 20px;
    }

    .sidebars {
      background: #ffffff !important;
      position: fixed;
      right: 0;
      height: 100%;
      overflow: overlay;
      order: -1;
    }

    .more {
      padding: 20px 5px;
      text-align: left;

      .file-name-input {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        height: 25px;
        color: #000000;
        width: 50%;
        padding: 10px;
        border-radius: 2px;
        font-size: 14px;

        &:focus {
          background-color: #ffffff !important;
          border: 1px solid #ffffff !important;
          color: #000000 !important;
          outline: none !important;
        }
      }

      .thumbnail {
        border: 1px solid #e6e7e9;
        display: block;
        margin: 0 auto;
        background: #f6f6f6;
        padding: 20px;
      }

      .row-pad {
        padding-top: 5px;
      }
    }
  }

  .tools-block {
    position: absolute;
    padding: 20px;
    background-color: #060515;
    top: 85%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    .quick-access {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin: 0 15px;
      }
    }
  }
}

.toolTipWidth {
  width: 160px !important;
}

.toolTipFont {
  font-size: 10px !important;
}

input.showredBorder {
  &:focus-within {
    border: 1px solid var(--error-red) !important;
  }

  border: 1px solid var(--error-red) !important;
}

.tooltip {
  padding: 20px;
  background-color: #f6f6f6;
  border-color: #d9d9d9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 999999 !important;
}

@media screen and (min-height: 1080px) {
  .types-wrapper {
    .types-block {
      .heading-block {
        padding: 40px 0 20px;
      }
    }
  }
}

@media screen and (max-height: 768px) and (min-height: 667px) {
  .types-wrapper {
    .types-block {
      .btns-group {
        padding: 20px;
      }
    }
  }
}

@media screen and (min-height: 1080px) {
  .types-wrapper {
    .types-block {
      .heading-block {
        padding: 40px 0 20px;
      }
    }
  }
}

.element-theme-wrapper {
  h5 {
    span {
      color: #342bc2;
      font-weight: 400;
      display: inline-flex;
      cursor: pointer;

      img {
        width: 20%;
        margin-right: 2px;
      }
    }
  }

  button {
    img {
      width: 4%;
      margin-top: -3px;
    }
  }
}

.themes-popup {
  padding: 20px 20px 30px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h4 {
      font-size: 18px;
      color: #000000;
    }

    .closes-button {
      button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;

        &:hover {
          color: #000000;
          background-color: #e9ecef;
          border: 1px solid #e9ecef;
          font-weight: 900;
        }
      }
    }
  }

  hr {
    background-color: #dfdfdf;
    opacity: 1;
    margin: 15px 0 15px;
  }

  .content {
    h5 {
      font-weight: 100;
      color: #9ca0a9;
      font-size: 12px;
      padding: 15px 0px;

      span {
        color: #342bc2;
        font-weight: 400;
        float: right;
        display: inline-flex;
        cursor: pointer;

        img {
          width: 20%;
          margin-right: 2px;
        }
      }
    }

    .theme-list {
      padding: 0px 0 15px;
      max-height: 385px !important;
      overflow: auto;

      .theme-block {
        position: relative;
        font-weight: 500;
        text-align: center;
        margin-bottom: 25px;
        border: 1px solid #e6e7e9;
        cursor: pointer;

        .custom-theme-list {
          .theme-head-sub-head {
            padding: 10px 10px 30px 10px;

            .theme-list-head {
              font-size: 15px;
              text-align: left;
              margin-bottom: 0px;
              font-weight: 400;
            }

            .theme-list-sub-head {
              font-size: 12px;
              color: var(--text-tertiary-color);
              margin-bottom: 0px;
              text-align: left;
              padding: 0px;
              font-weight: 100;
            }
          }

          .color-palette {
            width: 100%;
            display: flex;
            height: 32px;
            background-color: var(--text-active-color);
          }
        }

        &.inactive-theme::after {
          content: "";
          position: absolute;
          color: #ffffff;
          border: 1px solid #c1c0c0;
          font-size: 14px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin: 0;
          top: 6px;
          right: 5px;
        }

        &:hover {
          border: 1px solid #342bc2;
        }

        &.active-theme {
          border: 1px solid #342bc2;

          &::after {
            content: "\f058";
            font-family: "FontAwesome", "Rubik";
            position: absolute;
            color: #342bc2;
            font-size: 19px;
            border: none;
            margin: 0;
            top: 0px;
            right: 5px;
          }
        }
      }
    }
  }

  button.prime-btn {
    margin-top: 25px;
    height: 45px !important;
    font-size: 14px;
  }
}

.nav-info {
  max-width: 260px;
  background-color: #f6f6f6;
  padding: 20px 15px 20px 35px;

  .know-more {
    font-size: 12px;
    color: #342bc2;
  }

  .know-more-arrow {
    position: absolute;
    margin: 5px;
  }

  h4 {
    font-size: 12px;

    &::before {
      background-image: url("../assests/icons/info-icon.svg");
      background-size: 16px 16px;
      display: inline-block;
      width: 20px;
      height: 20px;
      content: "";
      background-repeat: no-repeat;
      position: absolute;
      margin-left: -25px;
      margin-top: -1px;
    }
  }

  p {
    font-size: 12px;
    color: #979797;
    margin-bottom: 3px;
  }
}

.nav-menu {
  text-align: center;

  h4 {
    font-size: 16px;
    padding-top: 10px;
  }

  p {
    font-size: 14px;
    color: #6b6b6b;
    margin-bottom: 3px;
  }
}

.add-page-icon-noslider {
  width: 65%;
  margin-top: 2px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
    border-radius: 2px;
  }
}

.add-page-icon {
  margin: 0 !important;
  padding: 0 !important;
  width: 77% !important;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
    border-radius: 2px;
  }
}

.charttype-block {
  padding: 0px 20px;
}

.radial {
  display: none;
}

textarea.input-detail {
  background: #ffffff;
  border-radius: 2px;
  border: solid 1px #e6e7e9;
  background-color: #ffffff;
  padding: 10px 15px;
  font-size: 13px;

  &:focus-within {
    border: 1px solid #e6e7e9;
    box-shadow: none !important;
    outline: 0 !important;
  }
}

textarea.input-detail::placeholder {
  font-size: 13px;
  opacity: 0.5 !important;
}

.description-block {
  padding: 10px 20px;
}

.check-size {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
  display: inline-block;
}

.elements-tabs.detail-tabs {
  margin-bottom: 10px;

  .nav-item {
    padding: 1px 4px 0px;
    border-radius: 2px;
    width: 50%;
  }

  .nav-link {
    text-align: center;

    &.active {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.themes-popup .content .theme-list {
  .element-variants {
    background-color: #f6f6f6;
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
    border: 1px solid transparent;

    &::after {
      content: "";
      position: absolute;
      color: #ffffff;
      border: 1px solid #c1c0c0;
      font-size: 13px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      top: 6px;
      right: 5px;
    }

    &.active-element {
      border: 1px solid #342bc2;

      &::before {
        content: "\f058";
        font-family: "FontAwesome", "Rubik";
        position: absolute;
        color: #342bc2;
        font-size: 15px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        top: 2px;
        right: 5px;
        z-index: 999;
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }

    .variant-name {
      display: none;
      position: absolute;
      background: #06060678;
      bottom: -1px;
      font-size: 13px;
      width: 100%;
      left: 0px;
      padding: 5px 5px 5px 10px;
      border-radius: 0px;
      color: #ffffff;
    }

    &:hover {
      .variant-name {
        display: block;
      }
    }
  }
}

.chart-combo-settings {
  margin: -4px 0 -5px 0;
  // text-align: center;
  color: #342bc2;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin: 0 10px 0 0px;
    display: inline-flex;

    img {
      margin: -3px 0;
    }
  }
}

table.chart-settings {
  thead {
    background-color: #f6f6f6;
    border-bottom: 2px solid transparent !important;
    padding: 8px 3px;

    th {
      font-weight: normal;
      color: #5e5e5e;
      font-size: 12px;
      padding: 8px 0px 8px 30px;
      text-align: left;
    }
  }

  tbody {
    font-size: 14px;
    color: #000000;
    font-weight: normal;

    tr {
      vertical-align: middle;
    }

    td.labels {
      padding-left: 10px;

      span {
        display: contents;
      }

      span.bullet-square::before {
        content: "";
        background-color: inherit;
        width: 10px;
        height: 10px;
        border-radius: 0%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
      }
    }

    td {
      padding: 25px 0;
    }

    td.radio {
      text-align: center;
    }
  }
}

.hide-tab {
  display: none !important;

  ~ .tab-content .tab-pane.active .details-wrapper h4 {
    padding-top: 10px !important;
  }
}

.create-wrapper {
  padding: 5px;
  text-align: left;

  .logo-header {
    font-size: 24px;
    padding: 0 10px 20px;
    color: #342bc2;
    font-weight: 900;
    text-align: left;
  }

  .blue-curve {
    position: absolute;
    z-index: -1;
    width: 175px;
    top: 142px;
    right: 5px;
  }

  .grey-block {
    position: absolute;
    z-index: -1;
    width: 175px;
    top: 142px;
    left: 5px;
  }

  .polygon {
    position: absolute;
    z-index: -1;
    width: 35px;
    right: 215px;
  }

  .create-block {
    text-align: left;

    .heading-block {
      padding: 5px 60px 15px;

      p {
        padding-top: 10px;
        color: #adb5bd;
        margin: 0 auto;
        font-size: 14px;
      }

      h4 {
        margin: 0 auto;
        font-size: 20px;

        span {
          position: absolute;
          width: 25px;
          margin: -2px 0 0 7px;
        }
      }
    }
  }

  .details-block {
    padding: 5px 60px 30px;

    .form-group {
      margin-bottom: 15px;

      .input-details {
        border: 1px solid #e6e7e9 !important;
      }
    }

    .upload-image-label {
      position: absolute;
      cursor: pointer;
      right: 0px;
      background-color: #342bc2;
      color: #ffffff;
      padding: 9px 20px;
      font-size: 12px;
      top: 25px;
    }

    .btn-block-create {
      text-align: center;
    }

    textarea.input-detail {
      background: #ffffff;
      border-radius: 2px;
      border: solid 1px #e6e7e9;
      background-color: #ffffff;
      padding: 5px 15px;
      font-size: 1rem;

      &:focus-within {
        border: 1px solid #e6e7e9;
        box-shadow: none !important;
        outline: 0 !important;
      }
    }
  }
}

.column-ordering {
  .heading-dnd {
    color: #5e5e5e;
    font-weight: 100;
    padding-bottom: 5px;
    font-size: 14px;
  }

  ul {
    padding-inline-start: 0px;

    li {
      list-style: none;
      padding: 5px 5px;
      border: 1px solid #e6e7e9;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f6f6f6;

        .icons {
          display: block;
        }
      }

      .list-div {
        display: flex;
        align-items: center;
      }

      .characters-thumb {
        margin-right: 5px;

        &.first-item {
          opacity: 0.5 !important;
          cursor: not-allowed !important;
        }
      }

      p {
        margin: 0;
        font-size: 13px;
        color: #000000;
        font-weight: 400;
        margin-bottom: -4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 150px;

        span {
          color: #a9a9a9;
          font-weight: 100;
        }
      }

      span {
        font-size: 12px;
        color: #9ca0a9;
      }

      .icons {
        display: none;
        cursor: pointer !important;

        img {
          padding: 0 3px;
        }
      }
    }
  }
}

.builder-header {
  .quick-access {
    .tooltip-headers {
      margin-top: 10px !important;
    }

    .tooltip-headers-fit {
      margin-top: 10px !important;
    }

    &.element {
      .tooltip-headers-del {
        margin-top: 13px !important;
      }
    }
  }

  .filename-back-btn {
    .tooltip-headers {
      margin-top: 27px !important;
    }
  }

  .export-div {
    .tooltip-headers {
      margin-top: 27px !important;
    }
  }
}

.tooltip-main-text {
  font-size: 14px;
  display: inline;

  .tooltip-shortcut {
    color: rgba(255, 255, 255, 0.5);
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: inline;
    padding-left: 10px;
    font-size: 13px;
  }
}

.screen-bar {
  .screen-tabs {
    .tooltip-headers {
      margin-top: -17px !important;
    }

    .tooltip-headers-add {
      margin-top: -13px !important;
    }
  }

  .tools-block {
    .tooltips-headers {
      margin-top: -17px !important;
    }

    .tooltips-headers-full {
      margin-top: -28px !important;
    }

    .tooltips-headers-arr {
      margin-top: -19px !important;
    }
  }
}

.preview-screen-bar {
  bottom: -1px;
  position: fixed;
  background: #060515;
  width: 100%;
  height: 40px;
  z-index: 999;
}

.preview-footer-wrapper {
  z-index: 999;
  height: 100%;

  .screen-tabs {
    display: flex;
    height: 100%;

    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      height: 100%;
    }

    ul {
      height: 100%;

      li {
        height: 100%;
        list-style-type: none;

        a {
          height: 100%;
          text-decoration: none;
        }
      }
    }

    .nav-tabs {
      border-bottom: none;

      .nav-item {
        .nav-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #979797;
          border: none;
          padding: 0.1rem 0rem;

          .screen-option {
            padding-right: 8px;
            padding-left: 5px;
          }

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
            margin-block-end: 0;
            margin-block-start: 0;
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            width: 120px;
            color: #5e5e5e;
            // padding-left: 20px;
          }

          &.active {
            color: #ffffff;
            border: none;
            background-color: #342bc2 !important;
            border-radius: 2px;

            p {
              color: #ffffff;
            }

            .dropdown-btn[aria-expanded="true"] {
              color: #ffffff;
            }

            .dropdown-btn {
              color: #ffffff;
              padding-bottom: 4px;
              margin-bottom: 4px;
              padding-left: 16px;
              padding-right: 8px;

              &:hover {
                background: rgba(255, 255, 255, 0.3);
                border-radius: 2px;
                padding-left: 16px;
                padding-right: 8px;
              }
            }
          }

          span {
            font-size: 10px;
            padding: 0 0 0 3px;

            .btn {
              line-height: 0.9;
            }

            .dropdown-btn[aria-expanded="true"] {
              background-color: transparent;
              border: 1px solid transparent;
              color: #979797;
              margin-bottom: 4px;

              &:focus {
                border: 1px solid transparent !important;
                outline: 0 !important;
                color: #ffffff;
              }
            }

            .dropdown-btn {
              border: 1px solid transparent;
              width: 10px;
              background: transparent;
              color: #979797;
              margin-bottom: 4px !important;
              padding: 6px 8px 2px 16px !important;

              &:focus {
                border: 1px solid transparent !important;
                outline: 0 !important;
                color: #ffffff;
              }

              i {
                font-size: 14px;
                margin-top: 0px;
              }
            }
          }
        }
      }
    }

    span {
      padding: 0 0px;
      margin-top: 7px;
      margin-bottom: 2px;
    }
  }

  .borderRight {
    border-right: 1px solid #dfdfdf;
  }

  .footer-tools {
    text-align: right;
    padding: 5px 5px 2px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05),
      0px 0px 5px rgba(0, 0, 0, 0.05);
    border-left: 1px solid #dfdfdf;

    img {
      margin: 3px 8px;
      padding: 2px 3px;
      width: 20px;
      margin-top: 2px !important;
      cursor: pointer;

      &:hover {
        background: #ececec;
        border-radius: 2px;
      }
    }

    span.divider {
      color: rgba(236, 236, 236, 1);
      padding: 0 18px;
      margin-top: -3px;
    }
  }

  .footer-block {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    border: 1px solid rgba(223, 223, 223, 0.5);
    box-shadow: 0px -4px 5px 5px rgba(0, 0, 0, 0.02);

    .screen-names {
      flex-basis: calc(100% - 320px);
    }

    .tools-block {
      flex: 0 0 190px;
      position: absolute;
      right: 0;
    }
  }
}

.preview-screen-bar {
  .screen-tabs {
    .tooltip-headers {
      margin-top: -17px !important;
    }

    .tooltip-headers-add {
      margin-top: -13px !important;
    }
  }

  .tools-block {
    .tooltips-headers {
      margin-top: -17px !important;
    }

    .tooltips-headers-full {
      margin-top: -28px !important;
    }

    .tooltips-headers-arr {
      margin-top: -19px !important;
    }
  }
}

.preview-wrapper {
  .tooltip-headers {
    margin-top: 20px !important;
  }
}

.buttonListInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f6f6f6;
  width: 230px !important;
  height: 40px !important;
  gap: 10px;
  border: 1px solid #e6e7e9;
  border-radius: 2px;

  .buttonListDragIcon {
    width: 20px;
    margin-left: 10px;
  }

  .form-group {
    margin-bottom: 0rem !important;
  }
}

.buttonListInputDelete {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.buttonListInputText {
  border: 0;
  background-color: #f6f6f6 !important;
  width: 100% !important;
  margin-top: 2px;
  font-size: 14px;

  padding: 0 !important;

  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.buttonListAddBtn {
  background-color: #342bc2 !important;
  color: #ffffff !important;
  border-radius: 2px;
  height: 40px !important;
  margin-top: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
  }
}

.buttonListLeftPad {
  padding-inline-start: 0;
}

.buttonListDeleteIcon {
  margin-left: auto !important;

  img {
    width: 22px;
  }
}

.layout-size-block {
  padding: 0px 5px 80px 5px !important;

  .preview-image-block {
    background-color: #f6f6f6;

    .preview-image {
      background: #ffffff;
    }
  }
}

.add-new-block {
  position: fixed;
  bottom: 0px;
  background-color: white;
  left: 0;
  padding: 10px 17px 10px 17px;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 305px !important;
  left: 0;

  hr {
    margin: 10px 0 !important;
    width: 100% !important;
  }

  .addNewScreenBtn {
    width: 90% !important;
    margin-bottom: 10px !important;
  }
}

.dragImageThumbnail {
  display: flex;
  justify-content: flex-start;

  .leftDragImage {
    margin-left: -8px;
    margin-right: 0px;
  }
}

.thumbnailPad {
  padding: 0px !important;
  // margin-top: 20px !important;
}

.outers-frame {
  width: 100%;
  height: 240px;
  max-height: 240px;
  min-height: 240px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  margin-bottom: 10px;

  p {
    padding: 0 !important;
    margin: -10px;
    color: #979797 !important;
  }
  .layout-preview-text-customize{
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 20px;
    width: 170px;
  }
}

.inner {
  background-color: #ffffff;
  margin-bottom: -20px;
  border: 1px solid #342bc2;
}

.layout-size-inputs {
  .discard {
    border: 1px solid #e6e7e9;
    background: #ffffff;
    border-radius: 2px;
    color: #000;

    &:hover {
      background-color: #ededed;
      border: 1px solid #ededed;
    }
  }

  .save {
    background-color: #342bc2;
    border-radius: 2px;
    border: 1px solid #342bc2;

    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }
  }

  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: -20px;

    button.custom-size {
      height: 40px !important;
      min-width: 80px !important;
      font-size: 14px !important;
    }
  }

  .row {
    display: flex;
    align-items: baseline;
    font-size: 0.9rem;
    justify-content: space-between;
    text-align: left;
  }

  label {
    font-size: 13px;
    color: #5e5e5e;
  }

  .interchange {
    position: absolute;
    top: 15%;
    left: 53.5%;
    cursor: pointer;
    width: 14%;

    img {
      position: absolute;
    }
  }

  .tooltip-header-change {
    left: 110px !important;
    width: 160px;
    top: 430px !important;
  }
}

.frame-name {
  height: 40px;
  font-size: 14px;
}

.width-after {
  background: #ffffff;
  border-radius: 2px;
  border: solid 1px #e6e7e9;
  background-color: #ffffff;
  padding: 5px 15px;
  height: 40px;
  font-size: 14px;
  color: #000000;
  background-image: url(../assests/icons/WW.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 90% 50% !important;
}

.height-after {
  background: #ffffff;
  border-radius: 2px;
  border: solid 1px #e6e7e9;
  background-color: #ffffff;
  padding: 5px 15px;
  height: 40px;
  font-size: 14px;
  color: #000000;
  background-image: url(../assests/icons/HH.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 90% 50% !important;
}

.pad-width,
.pad-height {
  padding-left: 0 !important;
  width: 36.33333333%;
}

.dashboardSizePopUp {
  padding: 1.5rem !important;

  .sizes-box {
    position: relative;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .alignHeadCloseBtn {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h4 {
      font-size: 18px;
    }
  }

  .interchange {
    position: absolute;
    top: 25%;
    left: 46%;
    cursor: pointer;
    width: 8%;

    img {
      position: absolute;
    }
  }

  .tooltip-header-change {
    left: 480px !important;
    width: 160px;
    top: 450px !important;
  }

  .frameSizePopUp-main {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 1rem;
    margin-bottom: 1rem;

    label {
      color: #5e5e5e;
      font-size: 14px;
    }

    .frameSizePopUp-left {
      width: 48%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .label-details {
        margin-bottom: 0.5rem;
      }

      .frame-size {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .frame {
          .row {
            padding-right: 0;

            .pad-right {
              padding-right: 0;
            }
          }
        }

        .size {
          .row {
            padding-right: 0;

            .pad-right {
              padding-right: 0;
            }
          }
        }
      }
    }

    .frameSizePopUp-right {
      width: 52%;

      p {
        margin-bottom: 0.55rem !important;
        color: #5e5e5e;
        font-size: 14px;
      }

      .outers-frame {
        width: 100%;
        height: 219px;
        max-height: 219px;
        min-height: 219px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 10px;
        background-color: #f6f6f6;

        p {
          padding: 0 !important;
          margin: -10px;
          color: #979797 !important;
        }
      }

      .inner {
        background-color: #ffffff;
        border: 1px solid #342bc2;
        margin-bottom: 00px;
      }
    }
  }
}

.loader-popup {
  background-color: #9ca0a900;
  border: 1px solid #9ca0a900;

  .modal-content {
    background-color: #9ca0a900;
    border: 1px solid #9ca0a900;

    .modal-body {
      background-color: #9ca0a900;
      border: 1px solid #9ca0a900;

      .gif-modal {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 35%;
        }
      }
    }
  }
}

.kpi-percent-icon {
  height: 40px;
  border: 1px solid #e6e7e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matrix-table {
  input[type="number"] {
    cursor: default;
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  input[type="number"]:focus {
    outline: none;
  }
}

.preview-layouts-wrapper {
  .builder-header .filename-back-btn {
    display: flex;
    align-items: center;
    width: auto !important;
  }
}

.builder-header1 {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 100;
}

.builder-header1:hover #navbar {
  visibility: visible;
  opacity: 1;
}

#navbar {
  visibility: hidden;
  opacity: 0;

  transition: all 0.5s ease;
  // transition: transform .5s cubic-bezier(.4,0,.2,1);
}

#botbar {
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  bottom: 100;
}

.builder-bottom {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 0;
}

.builder-bottom:hover #botbar {
  visibility: visible;
  opacity: 1;
}

.css-1f1fwq2-option {
  .options-pro {
    svg {
      fill: #ffffff;
    }

    span {
      color: #ffffff !important;
      margin-left: 12px;
      font-size: 12px;
    }

    span.pro-icon {
      float: right;
      display: none;
    }
  }
}

.options-pro {
  svg {
    fill: #989898;
  }

  span {
    color: #000000;
    margin-left: 10px;
    font-size: 12px;
  }

  span.pro-icon {
    display: none;
    float: right;
    margin-top: -1px;

    img {
      width: 94%;
    }
  }

  &.paid-subscription {
    span.pro-icon {
      display: none !important;
    }
  }

  &.unpaid-subscription {
    span.pro-icon {
      display: block !important;
    }
  }
}

.crown-class {
  display: inline-flex;
  align-items: baseline;
  margin-bottom: 14px !important;
  margin-top: 10px !important;
  margin-right: 20px !important;
  margin-left: 10px !important;
  gap: 4px;

  &::before {
    content: "";
    background-image: url("/assests/icons/crown-bg.svg");
    background-size: 100% 100%;
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
  }
}

.data-dictionary {
  h4 {
    font-size: 16px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 18px;
      color: var(--error-red);
      cursor: pointer;
    }
  }

  .dictionary {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 40px;

    .options {
      padding: 15px 20px;
      font-size: 12px;
      cursor: pointer;
    }

    .active {
      background-color: var(--brand-light-color);
      border: 1px solid var(--brand-color);
    }

    .inactive {
      background-color: var(--text-active-color);
      border: 1px solid var(--border-color);
    }
  }
}
.color-picker {
  position: absolute;
  right: -600%;
  z-index: 999999;
  background-color: var(--text-active-color);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
  padding: 0px 0px 15px;

  .form-block {
    padding: 0 15px;
  }

  .sketch-picker {
    padding: 15px 15px 0px !important;
    box-shadow: none !important;

    > div:nth-child(1) {
      > div:nth-child(1) {
        .saturation-white {
          > div:nth-child(2) {
            cursor: pointer !important;
            > div:nth-child(1) {
              width: 7px !important;
              height: 7px !important;
            }
          }
        }
      }
    }

    .flexbox-fix {
      padding: 20px 0px !important;

      > div:nth-child(1) {
        padding: 0px 10px 0px 0px !important;

        > div:nth-child(1) {
          height: 15px !important;

          .hue-horizontal {
            // border-radius: 20px;
            div {
              position: absolute;

              div {
                height: 13px !important;
                cursor: pointer;
              }
            }
          }
        }

        > div:nth-child(2) {
          height: 15px !important;
          // border-radius: 20px;
          margin-top: 10px !important;

          > div {
            > div:nth-child(3) {
              > div > div {
                height: 13px !important;
                cursor: pointer;
              }
            }
          }
        }
      }

      > div:nth-child(2) {
        width: 25px !important;
        height: 25px !important;
        position: relative !important;
        margin-top: 9px !important;
        margin-left: 8px !important;
        border-radius: 3px !important;
      }
    }

    > div:nth-child(3) {
      display: none !important;
    }

    > div:nth-child(4) {
      display: none !important;
    }
  }
}

.header-separator {
  height: 50px;
  border: 1px solid #383744;
  margin: 0 5px;
}
