.billing-wrapper {
  padding: 0px 100px 0 100px;
  text-align: left;

  hr {
    background-color: #e6e7e9 !important;
    opacity: 1 !important;
  }

  .billing-header {
    position: sticky;
    z-index: 98;
    top: 0;
    background: #ffffff;
    padding: 50px 0 40px 0;

    .heading {
      padding-bottom: 25px;
      display: flex;
      align-items: center;
      gap: 15px;

      h2 {
        color: var(--Text---Light-mode-primary, #000);
        font-family: Rubik;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0;
      }

      .elements-tabs {
        width: 205px;
        height: 37px;

        .nav-tabs {
          .second {
            .nav-link {
              padding-right: 15px !important;
              padding-left: 8px !important;
            }
          }

          .nav-item {
            .nav-link {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              text-overflow: ellipsis;
              text-wrap: nowrap;
              overflow: hidden;
              padding-left: 0;
              padding-right: 0;
              font-size: 12px;
              padding-top: 8px !important;
              padding-bottom: 7px !important;
              line-height: normal;

              .crown {
                margin-right: 5px;
                width: 14px;
              }

              &.active {
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                padding-top: 8px !important;
                padding-bottom: 7px !important;
                line-height: normal;
              }

              &:hover {
                isolation: auto;
              }

              &:focus {
                isolation: auto;
              }

              .team-wrap {
                width: 85%;
                text-align: center;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
                padding-left: 0;
                padding-right: 0;
                font-size: 12px;
                /* padding-top: 8px !important; */
                /* padding-bottom: 7px !important; */
                line-height: normal;
              }

              .dropdown-menu {
                z-index: 9999999;
                max-height: 250px;
                overflow-y: auto;
                overflow-x: hidden;
                transform: translate3d(0px, 25px, 1000px) !important;
                min-width: 11rem;

                .dropdown-item.active {
                  background-color: #342bc2 !important;
                  color: #ffffff;
                  border: 1px solid #342bc2;

                  .dropdown-item-text {
                    color: #ffffff !important
                  }
                }

                .dropdown-item {
                  &:hover {
                    .dropdown-item-text {
                      color: var(--text-active-color)
                    }
                  }

                  .dropdown-item-text {
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    text-wrap: wrap;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    overflow: hidden;
                    max-width: 120px;
                    padding: 0px;
                    padding-right: 5px;

                    color: #5e5e5e;

                    &:hover {
                      color: var(--text-active-color);
                    }
                  }

                  border-radius: 2px;
                  padding: 2px 1rem;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  svg {
                    float: right;
                    width: 12px;
                  }

                  &:hover {

                    &::after {
                      display: none;
                      float: none;
                    }
                  }
                }

                .dropdown-divider {
                  margin: 3px -7px 1px 6px;
                }

                .createTeamItem {
                  // border-top: 1px solid #e6e7e9;
                  padding: 0px;


                  .createteam {
                    background: transparent;
                    border: 1px solid #e6e7e9;
                    width: 100%;
                    border-radius: 2px;
                    margin-top: 10px;
                    font-size: 12px;
                    font-weight: 500;

                  }

                  &:hover {
                    background-color: transparent !important;
                    color: #000000 !important;
                    border: 1px solid transparent !important;
                    padding: 0px !important;

                    &::after {
                      display: none;
                      float: none;
                    }
                  }


                }
              }

              .btn-group {
                position: absolute;
                // margin-top: -20px;
                float: right;
                margin-left: 60px;

                .btn-secondary {
                  padding: 0px;
                  border: none;
                  background: none;
                }
              }
            }
          }
        }
      }

      .team-invite-members-button {
        width: 119px !important;
        height: 36px !important;
        min-width: auto;
        margin-left: auto;
        color: var(--text-blue-dark-surface-primary, var(--Light, #FFF));
        text-align: center;
        font-family: Rubik;
        font-size: 12px !important;
        font-weight: 400;
      }
    }

    .active-group {
      display: flex;
      margin-bottom: 10px;

      .team-toggle-container {
        color: #000;

        .team-wrap-container {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .team-wrap {
            // width: 85%;

            // text-align: center;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
            padding-left: 0;
            padding-right: 0;
            font-size: 24px;
            font-weight: 400;
            /* padding-top: 8px !important; */
            /* padding-bottom: 7px !important; */
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .team-menu-container {
        padding: 16px 16px 16px 16px;
        width: 264px;
        box-shadow: 8px 10px 32px 8px #342BC20F;
        max-height: fit-content !important;

        .team-menu-header {
          font-size: 12px;
          font-weight: 400;
          color: #9CA0A9;
          padding-left: 10px;
        }

        .team-menu {
          margin-top: 8px;
          max-height: 200px;
          overflow-y: auto;

          .team-item-container {
            display: flex;
            align-items: center;
            padding: 8px 12px 8px 8px;
            cursor: pointer;
            margin-right: 8px;

            &:hover {
              background: #342BC20A;
            }

            &.active-item {
              background: #342BC20A;
              border-radius: 2px;
            }

            .dropdown-item-text {
              display: flex;
              flex-direction: column;
              padding: 3px 10px;

              .item-title {
                font-size: 12px;
                font-weight: 400;
                line-height: 14.4px;
                color: #000000;
                margin-bottom: 2px;
                max-width: 130px;
                text-overflow: ellipsis;
                display: -webkit-box;
                text-wrap: wrap;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;

                &.max-width-handled {
                  max-width: 115px !important;
                }
              }

              .item-subtitle {
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                color: #9CA0A9;
              }
            }

            .dropdown-item-crown-icon-container {
              position: relative;
              bottom: 8px;
              right: 4px;

              .dropdown-item-crown-icon {
                height: 16px;
                width: 16px;
              }
            }

            .active-item-tick {
              float: right;
              margin-left: auto;
            }

            .team-profile-photo-credentials {
              .team-profile-photo {
                border-radius: 50%;
                width: 26px;
                height: 26px;
                object-fit: cover;
              }
            }
          }
        }

        .create-team-button {
          margin-top: 10px;

          .create-team {
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            padding: 6px;
            background-color: transparent;
            border: 1px solid #E6E7E9;
            border-radius: 2px;

            .plus-icon {
              font-size: 13px;
              margin-left: 1px;
            }
          }
        }
      }

      .dropdown-menu {
        z-index: 9999999;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translate3d(0px, 25px, 1000px) !important;
        min-width: 11rem;

        .team-profile-photo-credentials {
          width: 26px;
          height: 26px;
          background-color: seagreen;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          color: white;
          font-weight: 500;
        }

        .switch-text {
          padding: 1px 0px 7px 24px;
          color: #9CA0A9;

        }

        .dropdown-item.active {
          background-color: #342bc2 !important;
          color: #ffffff;
          border: 1px solid #342bc2;

          .dropdown-item-text {
            color: #ffffff !important
          }
        }

        .dropdown-item {
          &:hover {
            .dropdown-item-text {
              color: var(--text-active-color)
            }
          }

          .dropdown-item-text {
            text-overflow: ellipsis;
            display: -webkit-box;
            text-wrap: wrap;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            overflow: hidden;
            max-width: 120px;
            padding: 0px;
            padding-right: 5px;

            color: #5e5e5e;

            &:hover {
              color: var(--text-active-color);
            }
          }

          border-radius: 2px;
          padding: 2px 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            float: right;
            width: 12px;
          }

          &:hover {

            &::after {
              display: none;
              float: none;
            }
          }
        }

        .dropdown-divider {
          margin: 3px -7px 1px 6px;
        }

        .createTeamItem {
          // border-top: 1px solid #e6e7e9;
          padding: 0px;


          .createteam {
            background: transparent;
            border: 1px solid #e6e7e9;
            width: 100%;
            border-radius: 2px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 500;

          }

          &:hover {
            background-color: transparent !important;
            color: #000000 !important;
            border: 1px solid transparent !important;
            padding: 0px !important;

            &::after {
              display: none;
              float: none;
            }
          }


        }
      }

      .btn-group {
        // position: absolute;
        // margin-top: -20px;
        // float: right;
        // margin-left: 60px;

        .btn-secondary {
          padding: 0px;
          border: none;
          background: none;
        }
      }

    }

    .individual {
      .bar {
        top: 153px !important;
      }

      &:not(.active):hover {
        .bar {

          top: 153px !important;

        }

      }
    }

    .tabs {
      .nav-tabs {
        border-bottom: 1px solid #e6e7e9;

        .nav-item {
          padding-bottom: 5px;

          .nav-link {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-left: 0px;
            padding-right: 0px;
            margin-right: 1.5rem;
            color: #5e5e5e;
            border: none;
            cursor: pointer;
            transition: color 0.15s ease-in-out,
              background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;

            .plans-icon {
              width: 21px;
            }

            img {
              opacity: 0.4;
              padding-bottom: 4px;
              padding-right: 5px;
              width: 22px;
            }

            .bar {
              display: none;

            }

            .count-number {
              width: 24px;
              height: 16px;
              min-width: 25px;
              background: var(--text-tertiary-color);
              font-size: 8px;
              color: var(--text-active-color);
              border-radius: 8px;
              position: relative;
              line-height: 9px;
              position: relative;
              top: -4px;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
              }
            }

            &.active {
              .count-number {
                background: #342bc2;
              }

              .bar {
                display: flex !important;
                background: #342bc2;
                position: absolute;
                height: 4px;
                top: 190px;
                border: none;
                border-radius: 10px;
              }

              font-weight: 400;
              color: #000000;
              border: none;
              border-radius: 2px;

              img {
                opacity: 1;
              }
            }

            &:not(.active):hover {
              .bar {
                display: flex !important;
                background: #e9ecef;
                position: absolute;
                height: 4px;
                top: 190px;
                border: none;
                border-radius: 10px;

              }

            }
          }
        }
      }
    }
  }

  .tab-header {
    padding-bottom: 15px;

    h4 {
      font-size: 18px;
      color: #000000;
    }

    p {
      font-size: 14px;
      color: #5e5e5e;
    }
  }

  .action-success {
    position: relative;

    .content {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 70vh;

      img {
        width: 17%;
        padding-bottom: 40px;
      }

      h4 {
        font-size: 28px;
        font-weight: 500;
        color: #000000;
      }

      p {
        font-size: 14px;
        color: #9ca0a9;

        span {
          color: #342bc2;
          cursor: pointer;
        }
      }
    }
  }

  .cycle-banner {
    margin-top: 20px;

    p {
      border-left: 3px solid #342bc2;
      font-size: 14px;
      background-color: rgba(52, 43, 194, 0.04);
      margin: 0;
      padding: 15px 15px;

      span.image {
        margin-right: 10px;
      }
    }

    &.changed {
      p {
        background-color: rgba(255, 158, 42, 0.08);
        border-left: 3px solid rgba(255, 158, 42, 1);
        color: #000000;

        span.revert {
          float: right;
          cursor: pointer;
          color: rgba(239, 68, 68, 1);
        }
      }
    }
  }

  .payment-details {
    h5 {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }

    .gateway-details {
      padding: 10px 0 0;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #000;
        ;
      }

      span {
        color: #9ca0a9;
        font-size: 12px;
      }

      .apply-button {
        top: auto !important;

        img {
          margin: -3px 0 0 2px;
        }
      }
    }
  }

  .button-block {
    position: absolute;
    right: 0;
    top: -80px;
    display: flex;
    gap: 10px;
  }

  .apply-button {
    background-color: transparent;
    color: #342bc2;
    border: 1px solid transparent;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: auto;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: #342bc2;
      border: 1px solid transparent;
    }
  }

  .info-details-wrapper {
    border: 1px solid #e6e7e9;
    background: #ffffff;
    padding: 40px 40px;

    .billing-form {
      .form-wrapper-block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 30px;
        position: relative;
      }

      .information,
      .billing-address {
        border: 1px solid #e6e7e9;
        padding: 25px;
        width: 70%;

        .billing-title {
          color: #000;
          font-family: Rubik;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 30px;
        }

        .apply-button {
          background-color: transparent;
          color: #342bc2;
          border: 1px solid transparent;
          font-size: 14px;
          position: absolute;
          right: 10px;
          top: 7px;
          padding: 0;
          font-weight: 400;

          &:hover {
            background-color: transparent;
            color: #342bc2;
            border: 1px solid transparent;
          }

          &.billing-toggle {
            position: static !important;
            float: right !important;
            padding-top: 4px;
          }
        }

        h4 {
          color: #000000;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 20px;

          &.sub-heading {
            padding-bottom: 10px;
          }
        }

        .validation {
          color: rgba(239, 68, 68, 1);
          font-weight: 400;
          font-size: 14px;
        }

        .form-group {
          margin-bottom: 15px;
        }

        .address {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
        }
      }
    }
  }

  .subscription-wrapper {
    border: 1px solid #e6e7e9;
    background: #ffffff;
    padding: 40px 40px;

    .billing-header-container {
      display: flex;
      justify-content: space-between;

      .billing-buttons-container {
        .invite-members-button {
          width: 135px;
          min-width: auto;
          margin-right: 12px;
          border-radius: 2px;
          border: 1px solid var(--Icons-Brand, #342BC2);
          background: var(--text-blue-dark-surface-primary, #FFF);
          color: var(--Icons-Brand, #342BC2);
          font-family: Rubik;
          font-size: 14px !important;
          font-weight: 400 !important;
        }

        .manage-licenses-button {
          width: fit-content;
          padding-left: 16px !important;
          padding-right: 16px !important;
          min-width: auto;
          color: var(--White-Fill, #FFF);
          font-family: Rubik;
          font-size: 14px !important;
          font-weight: 400 !important;
        }
      }
    }

    .plan-overview {
      position: relative;

      .button-block {
        .invite-members-button {
          width: 135px;
          min-width: auto;
          margin-right: 12px;
          border-radius: 2px;
          border: 1px solid var(--Icons-Brand, #342BC2);
          background: var(--text-blue-dark-surface-primary, #FFF);
          color: var(--Icons-Brand, #342BC2);
          font-family: Rubik;
          font-size: 14px !important;
          font-weight: 400 !important;

          &:hover {
            background: var(--text-blue-dark-surface-primary, #FFF);
            color: var(--Icons-Brand, #342BC2);
          }

          .renew-subscription-button {
            width: fit-content !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
            min-width: auto;
            color: var(--White-Fill, #FFF);
            font-family: Rubik;
            font-size: 14px !important;
            font-weight: 400 !important;
          }
        }
      }
    }

    .subscription-details {
      border: 1px solid #e6e7e9;
      background: #ffffff;
      padding: 20px;

      .plan-details {

        .info-text {
          color: #9ca0a9;
          font-size: 14px;
        }

        .brief {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 13px;
            color: #5e5e5e;
            margin-bottom: 0px;

          }

          .plan-name {
            position: relative;
            font-size: 16px;
            margin-top: 8px;

            span.name {
              img {
                margin-right: 6px;
                width: 35px;
                margin-top: -7px;
              }
            }

            p {
              font-size: 12px;
              color: #000000;
            }

            .period {
              background-color: #dff5ef;
              color: #22946b;
              font-size: 10px;
              white-space: nowrap;
              padding: 3px 5px 2px;
              border-radius: 2px;
              position: absolute;
              top: 7%;
              margin-left: 7px;
              font-weight: normal;

              &.trial {
                color: var(--text-active-color);
                background-color: var(--primary-gradient);
                background: var(--primary-gradient);
              }
            }
          }

          .plan-amount {
            font-size: 32px;
            // padding: 10px 0px 20px;
            color: #000000;
            margin-bottom: 0px;

            span {
              color: #9ca0a9;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }

        .total-licenses-section {
          margin-top: 30px;
          margin-bottom: 10px;

          .total-licenses-heading {
            color: var(--Text---Light-mode-primary, #000);
            font-family: Rubik;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
          }

          .total-licenses-details {
            display: flex;
            align-items: center;
            gap: 15px;

            .number-of-licenses {
              color: var(--Text---Light-mode-primary, #000);
              font-family: Rubik;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 0;

              span {
                color: var(--Text---Light-mode-primary, #000);
                font-family: Rubik;
                font-size: 14px;
                font-weight: 500;
              }
            }

            .vertical-seperator-line {
              height: 20px;
              color: black;
              border: 1px solid #EFEFEF;
              border-radius: 1px;
            }

            .number-of-allocated-licenses {
              color: var(--Text---Light-mode-primary, #000);
              font-family: Rubik;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 0;
            }
          }
        }

        .pro-licenses-assign-section {
          margin-top: 10px;

          .pro-licenses-assign-heading {
            color: #000;
            font-family: Rubik;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 7px;
          }

          .pro-licenses-assign-short {
            color: var(--Text---Light-mode-secondary, #5E5E5E);
            font-family: Rubik;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 25px !important;
          }
        }

        .status-bar {
          padding: 20px 0;

          h5 {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            display: inline-block;

            &.days-left {
              float: right;
              margin-top: 5px;

              &.month-count {
                font-size: 12px !important;
              }
            }
          }

          h5.inactive {
            display: block;
            margin: 15px 0 25px;

            img {
              margin-right: 5px;
            }
          }

          .insuffiecient-fund {
            font-size: 12px;
            border: 1px solid #be1931;
            border-left: 4px solid #be1931;
            padding: 15px;

            p {
              margin-bottom: 0;
              margin-left: 4px;
            }
          }

          .progress {
            height: 0.65rem;
            width: 100% !important;

            .progress-bar {
              background-color: #342bc2;
              border-radius: 10px;
            }
          }
        }
      }

      .billing-note-container {
        background: #FFF8F0;
        border-left: 4px solid var(--Warning-primary, #FF9E2A);
        padding: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        margin: 20px auto;

        .billing-note-description {
          color: var(--Text---Light-mode-primary, #000);
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;

          .bold-part {
            font-weight: 500 !important;
            margin-bottom: 0;
          }
        }

        .info-icon {
          cursor: pointer;
          position: relative;
          right: 2px;
        }

        .cancel-changes {
          margin-left: auto;
          margin-bottom: 0;
          color: var(--Danger-Primary, #EF4444);
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
          cursor: pointer;
        }
      }

      .cancel-subscription-button {
        font-size: 14px !important;
        padding-left: 0;
        padding-bottom: 5px;
        height: fit-content !important;
        margin-top: 5px;
      }

      .cancel-subscription-note {
        margin-bottom: 10px;
        color: var(--Text---Light-mode-secondary, #5E5E5E);
        font-family: Rubik;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .footer-billing-note {
    color: #000;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    padding-left: 25px;
  }

  .teams-profile-wrapper {

    // border: 1px solid #e6e7e9;
    // background: var(--text-active-color);
    // padding: 40px 40px;
    .team-subscription-inactive-wrapper {
      margin-bottom: 30px;
      border: 1px solid #e6e7e9;
      background: var(--text-active-color);
      padding: 40px 40px;
      padding-right: 110px;

      .subscription-inactive-text {
        margin-top: 15px;
        color: var(--Text---Light-mode-secondary, #5E5E5E);
        font-size: 16px;
      }

      ul {
        padding-left: 0px;

        img {
          margin-bottom: 3px;
          margin-right: 7px;

        }

        list-style: none;

        li {
          color: var(--Text---Light-mode-secondary, #5E5E5E);
          font-size: 14px;
        }
      }

      .next-steps {
        width: 103px;
      }

      .buy-licenses {
        width: 136px !important;
        min-width: 100px !important;
        height: 46px !important;
        color: var(--text-blue-dark-surface-primary, #FFF) !important;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        min-width: auto;
        margin-top: 10px;
      }
    }

    .teams-profile-details-wrapper {
      border: 1px solid #e6e7e9;
      background: var(--text-active-color);
      padding: 40px 40px;

      .team-profile-billing-form {
        .team-profile-billing-section {
          .form-wrapper-block {
            .billing-address {
              .form-control:disabled {
                background-color: rgba(52, 43, 194, 0.05) !important;
              }

              .teamspace-name-label,
              .industry-name-label {
                color: var(--Text---Light-mode-secondary, #5E5E5E);
                font-family: Rubik;
                font-size: 16px;
                font-weight: 400;
                padding-bottom: 10px;
              }
            }

            .team-profile-photo-container {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 15px;
              padding-top: 18px !important;

              .team-photo-container {

                .team-profile-photo {
                  border-radius: 10px !important;
                  object-fit: cover;
                  width: 160px;
                  height: 160px;
                }
              }

              .team-profile-photo-credentials {
                width: 160px;
                height: 160px;
                background-color: seagreen;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 60px;
                color: white;
                font-weight: 500;
              }

              .team-profile-photo-buttons-container {
                display: flex;
                align-items: center;
                gap: 10px;

                .upload-button {
                  width: 75px;
                  height: 40px;
                  color: var(--brand-color);
                  font-family: Rubik;
                  font-size: 14px;
                  font-weight: 400;
                  cursor: pointer;
                  background: var(--Brand-Light, rgba(52, 43, 194, 0.05));
                  border-radius: 2px;
                  border: none;
                }

                .remove-button {
                  width: 75px;
                  height: 40px;
                  color: var(--Icons-Primary, #323232);
                  font-family: Rubik;
                  font-size: 14px;
                  font-weight: 400;
                  cursor: pointer;
                  background: #FFF;
                  border-radius: 2px;
                  border: 1px solid var(--Grey-Line, #E6E7E9);

                  &.disabled {
                    color: var(--Icons-Primary, #323232) !important;
                    cursor: auto !important;
                    pointer-events: none !important;
                  }
                }
              }

              .tab-header {
                padding-bottom: 0;

                p {
                  margin-bottom: 0;
                }
              }
            }
          }

          .team-profile-save-button {
            width: 170px !important;
            height: 60px !important;
            color: var(--text-blue-dark-surface-primary, #FFF) !important;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            min-width: auto;
            margin-top: 90px;
          }
        }
      }

      .teams-profile-bottom-card {
        .tab-header {
          padding-bottom: 0;

          .leave-team {
            min-width: 0;
            width: 170px;
            height: 60px;
            margin-bottom: 0;
            margin-top: 30px;
          }
        }
      }

      &.already-has-license {
        margin-top: 25px;
      }
    }
  }

  .teams-request-wrapper {
    .search-templates {
      .form-group>.team-request-search {
        background: none;
        border-radius: 2px;
        border-bottom: 1px solid #e6e7e9;
        border-left: none;
        border-right: none;
        border-top: none;
        color: var(--Text---Light-mode-tertiary, #9CA0A9);
        font-family: Rubik;
        font-size: 14px !important;
        font-weight: 400;

        &:focus-within {
          border: none !important;
          border-bottom: 1px solid #e6e7e9 !important;
        }
      }

      .team-requests-table {
        // margin-top: 35px;
        margin-bottom: 0;

        thead {
          tr {
            th {
              color: var(--Text---Light-mode-primary, #000);
              font-family: Rubik;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px !important;
            }
          }
        }

        tbody {
          border-top: 1px solid #dee2e6 !important;

          tr {
            td {
              padding-top: 20px !important;
              padding-bottom: 20px !important;

              span {
                color: var(--Text---Light-mode-primary, #000);
                font-family: Rubik;
                font-size: 14px;
                font-weight: 400;
              }

              .email-request {
                color: #5E5E5E !important;
              }

              .license-request {
                color: #5E5E5E !important;
              }

              .license-request-info-icon {
                margin-left: 4px;
                margin-bottom: 1px;
                cursor: pointer;
              }

              .delete-icon {
                cursor: pointer;
              }
            }
          }
        }
      }

      .billing-footer-container {
        display: flex;
        align-items: center;
        padding-top: 18px;
        border-top: 1px solid #dee2e6;
        justify-content: space-between;
        margin-top: 0;

        .end-note {
          color: #000000;
          font-size: 10px;
          padding-left: 0;
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        .pagination-block {
          padding: 0;
        }
      }
    }
  }

  .teams-peoples-wrapper-container {
    .teams-peoples-wrapper {
      border: 1px solid #e6e7e9;
      background: #ffffff;
      padding: 40px 40px;
      margin-bottom: 30px;

      .team-people-card-container {
        padding-left: 15px;
        margin-left: 10px;

        .team-people-card-title {
          color: var(--Text---Light-mode-primary, #000);
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;

          span {
            margin-left: 5px;
            cursor: pointer;

            img {
              vertical-align: sub;
            }
          }
        }

        .team-people-card-count {
          color: var(--Text---Light-mode-primary, #000);
          font-family: Rubik;
          font-size: 40px;
          font-weight: 500;
        }

        .team-people-card-duration {
          color: var(--Text---Light-mode-tertiary, #9CA0A9);
          font-family: Rubik;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;

          span {
            color: var(--Green-Success, #22946B);
          }
        }
      }
    }

    .teams-request-wrapper {
      .search-templates {
        .people-search-and-filter-container {
          border-bottom: 1px solid #e6e7e9;
          margin-bottom: 35px;

          .team-peoples-search-container {
            width: 80% !important;

            .team-request-search {
              border-bottom: none !important;
            }

            .search-close {
              right: 200px;
              top: 5px !important;
            }
          }

          .team-peoples-filter-container {
            .css-4dsw8p-control {
              border-bottom: none !important;
            }

            .css-tlfecz-indicatorContainer {
              padding-left: 0;
            }

            .css-1gtu0rj-indicatorContainer {
              padding-left: 0;
            }
          }
        }

        .team-requests-table {
          tbody {
            tr {
              .people-dropdown-container {

                .people-dropdown-toggle {
                  color: var(--Text---Light-mode-secondary, #5E5E5E);
                  font-family: Rubik;
                  font-size: 14px;
                  font-weight: 400;
                  background: none;
                  border: none;
                  padding: 0 !important;
                  display: flex;
                  align-items: center;
                  gap: 5px;
                }

                .info-icon {
                  cursor: pointer !important;
                  margin-left: 4px;
                }

                .dropdown-menu {
                  border-radius: 2px !important;
                  background: var(--Light, #FFF) !important;
                  box-shadow: 8px 10px 32px 8px rgba(52, 43, 194, 0.06) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .teams-account-wrapper {
    background: var(--text-active-color);

    .teams-account-top-card {
      border: 1px solid #e6e7e9;
      background: var(--text-active-color);
      padding: 40px 40px;
      margin-bottom: 25px;

      .tab-header {
        &.padding-right {
          padding-right: 80px;
        }

        padding-bottom: 0;

        p {
          margin-bottom: 0;
        }

        .change-owner-button {
          min-width: auto;
          width: 130px;
          height: 60px;
          margin-top: 50px;
          margin-bottom: 10px;
        }

        .team-account-note {
          border-radius: 2px;
          border: 1.5px solid var(--Warning-primary, #FF9E2A);
          background: rgba(255, 158, 42, 0.08);
          margin-top: 20px;
          padding: 12px 16px;
          display: flex;
          gap: 10px;
          align-items: flex-start;

          .note-pencil {
            padding-top: 5px;
          }

          .team-account-note-description {
            color: #000;
            font-family: Rubik;
            font-size: 12px;
            font-weight: 400 !important;
            line-height: 165%;
            letter-spacing: 0.06px;

            .bold-part {
              font-weight: 500 !important;
            }

            .yellow-part {
              color: var(--Warning-primary, #FF9E2A) !important;
              font-weight: 500 !important;
              text-decoration-line: underline;
              cursor: pointer;
            }
          }
        }
      }

      .team-ownership-section {
        margin-top: 30px !important;

        .team-ownership-heading {
          color: var(--Text---Light-mode-primary, #000) !important;
          font-family: Rubik !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          margin-bottom: 15px !important;
        }

        .team-ownership-box-container {
          border-radius: 2px !important;
          border: 1px solid var(--Grey-Line, #E6E7E9) !important;
          margin-bottom: 25px;

          .box-first-half {
            .input-style {
              border-bottom: 1px solid var(--Grey-Line, #E6E7E9) !important;
              border-top: none !important;
              border-left: none !important;
              border-right: none !important;
            }

            .search-bar {
              background-size: 18px 18px;
              background-position: 16px !important;
            }

            .search-close {
              img {
                width: 20px;
                height: 20px;
              }
            }
          }

          .box-second-half {
            max-height: 248px !important;
            padding: 12px !important;
            overflow-y: auto;

            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background: var(--Grey-Line, #E6E7E9);
            }

            &::-webkit-scrollbar-track {
              background: transparent !important;
            }

            .individual-details-container {
              padding: 12px 16px !important;
              display: flex !important;
              align-items: center !important;
              gap: 15px !important;

              .individual-logo {
                color: var(--text-blue-dark-surface-primary, var(--Light, #FFF)) !important;
                font-family: Rubik !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                background-color: var(--Brand-Main, #342BC2) !important;
                border-radius: 50% !important;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                width: 32px !important;
                height: 32px !important;
              }

              .individual-details {
                .individual-name {
                  color: #000 !important;
                  font-family: Rubik !important;
                  font-size: 12px !important;
                  font-weight: 400 !important;
                  margin-bottom: 5px !important;
                  line-height: normal !important;
                }

                .individual-email {
                  color: var(--Text---Light-mode-tertiary, #9CA0A9) !important;
                  font-family: Rubik !important;
                  font-size: 10px !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                  line-height: normal !important;
                  margin-bottom: 0 !important;
                }
              }

              &:hover {
                border-radius: 3px !important;
                background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04)) !important;
                cursor: pointer;
              }

              .check-icon {
                margin-left: auto !important;
                margin-right: 10px;
              }

              &.clicked-container {
                border-radius: 3px !important;
                background: var(--Brand-Light-3, rgba(52, 43, 194, 0.04)) !important;
                cursor: pointer;
              }
            }
          }
        }

        .team-ownership-footer {
          .team-ownership-footer-text {
            color: var(--Text---Light-mode-tertiary, #9CA0A9);
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 25px;
          }

          .team-ownership-buttons {
            display: flex;
            align-items: center;
            gap: 35px;

            .confirm-button {
              width: 170px !important;
              min-width: 0;
              height: 60px !important;
            }

            .go-back-button {
              color: #000;
              font-family: Rubik;
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0 !important;
              padding-bottom: 0 !important;
              cursor: pointer;
            }
          }
        }
      }
    }

    .teams-account-bottom-card {
      border: 1px solid #e6e7e9;
      background: #ffffff;
      padding: 40px 40px;

      .tab-header {
        padding-bottom: 0;

        p {
          margin-bottom: 0;
        }

        .change-owner-button {
          min-width: auto;
          width: 130px;
          height: 60px;
          margin-top: 50px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .invoice-wrapper {
    border: 1px solid #e6e7e9;
    background: #ffffff;
    padding: 40px 40px 20px;
    margin: 40px 0px 20px 0px;

    .no-billing-history {
      text-align: center;
      padding: 100px 0px 100px;
      border: 1px solid #e6e7e9;

      h3 {
        font-weight: 500;
        font-size: 16px;
        color: var(--text-primary-color);
        padding: 20px 0px 0px;
      }

      p {
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 12px;
        color: var(--text-tertiary-color);
      }
    }

    .invoice-list {
      position: relative;

      .button-block {
        button {
          padding-left: 18px;
          padding-right: 18px;
        }

        img {
          padding-right: 5px;
          padding-bottom: 4px;
        }
      }

      table {

        .check-box {
          cursor: pointer;
        }

        thead,
        th {
          vertical-align: middle;
          border-bottom: 2px solid transparent !important;
          font-size: 14px;
          color: #5e5e5e;
          font-weight: 400;
          text-align: left;
          background: rgba(52, 43, 194, 0.04);
          padding: 10px;
        }

        .pdf-invoice-icon {
          width: 34px;
        }

        tbody {
          td {
            text-align: left;
            font-size: 14px;
            padding: 25px 10px 25px;
            white-space: nowrap;
            color: #000000;
          }

          td.check-box {
            img {
              padding: 0.5rem 0.5rem !important;
              cursor: pointer;
            }
          }

          td.names {
            .icon-names {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;

              .details {
                display: flex;
                justify-content: center;
                align-items: baseline;
                flex-direction: column;
              }

              .month {
                font-weight: 400;
                color: #000000;
              }

              .number {
                font-size: 12px;
                color: #9ca0a9;
              }
            }
          }

          td.amount-paid {
            font-weight: 500;
          }

          td.card-details {
            >div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 20px;

              .card-brand-icon {
                width: 42px;
              }
            }
          }

          td.download-invoice-icon {
            vertical-align: middle;
            cursor: pointer;

            img {
              background-color: rgba(52, 43, 194, 0.05);
              padding: 11px;
            }
          }

          td.status {

            span.paid {
              color: #22946b;
              background-color: #dff5ef;
              padding: 10px 15px 10px 25px;
              border-radius: 2px;
              text-align: left;
              position: relative;

              &::before {
                content: url('../assests/icons/payment-success.svg');
                top: 9px;
                position: absolute;
                left: 9px;
              }
            }

            span.failed {
              color: #ef4444;
              background-color: rgba(239, 68, 68, 0.08);
              padding: 10px 15px 10px 25px;
              border-radius: 2px;
              text-align: left;
              position: relative;

              &::before {
                content: url('../assests/icons/payment-failed.svg');
                top: 9px;
                position: absolute;
                left: 9px;
              }
            }
          }

          .grey-square {
            img {
              padding: 0rem !important;
            }
          }
        }
      }

      .invoice-list-row {
        margin-bottom: 0;
      }

      .billing-footer-container {
        display: flex;
        align-items: center;
        padding-top: 18px;
        border-top: 1px solid #dee2e6;
        justify-content: space-between;
        margin-top: 7px;

        .end-note {
          color: #000000;
          font-size: 10px;
          padding-left: 0;
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        .pagination-block {
          padding: 0;
        }
      }
    }
  }

  .cancellation-wrapper {

    .error-block-cancel {
      color: rgba(239, 68, 68, 1);
      font-size: 13px;
      margin-top: 10px;
    }

    .header {
      position: sticky;
      z-index: 999;
      top: 0;
      background: #ffffff;
      padding: 50px 0 40px 0;

      span {
        color: #9ca0a9;
        font-size: 16px;
        margin-right: 5px;

        &.toggle {
          color: #000000 !important;
          cursor: pointer;

          img {
            margin-right: 5px;
            margin-top: -2px;
            width: 15px;
          }
        }

        &.cancel-plan-icon {
          img {
            margin-right: 4px;
            width: 15px;
            margin-top: -3px;
          }
        }

        i {
          margin: 0 4px;
        }
      }
    }

    .cancel-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;

      .reason,
      .agreement {
        width: 50%;

        h4 {
          font-size: 16px;
          color: #000000;
        }

        h6 {
          font-size: 12px;
          margin-top: 15px;
          font-weight: 100;
          margin-bottom: 20px;
          color: #000000;

        }

        .list {
          padding: 20px 0;
          margin-left: -10px;
          display: contents;
          width: 90%;

          .form-group {
            margin-top: 10px;
            width: 97%;

            textarea.textarea-style {
              outline: none !important;
              box-shadow: none !important;
              border: 1px solid #e6e7e9;
              background: #ffffff;
              border-radius: 2px;
              padding: 5px 15px;
              color: #000000;
              font-size: 14px;
              max-height: 120px;

              &:focus-within {
                outline: none !important;
                box-shadow: none !important;
                border: 1px solid #e6e7e9;
              }

              &:hover {
                outline: none !important;
                box-shadow: none !important;
                border: 1px solid #e6e7e9;
              }
            }
          }

          span {
            border: 1px solid #e6e7e9;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 35px 28px 10px;
            color: #5e5e5e;
            display: inline-block;
            margin: 10px 20px 10px 0;
            cursor: pointer;
            position: relative;
            width: 45%;
            height: 80px;

            &.option {
              &::after {
                content: "";
                position: absolute;
                color: #ffffff;
                border: 1px solid #c1c0c0;
                font-size: 14px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                top: 5px;
                right: 5px;
              }
            }

            &.active {
              border: 1px solid #342bc2;

              &::after {
                content: "\f058";
                font-family: "FontAwesome", "Rubik";
                position: absolute;
                color: #342bc2;
                font-size: 19px;
                border: none;
                font-size: 14px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                top: 2px;
                right: 2px;
              }
            }
          }
        }
      }

      .agreement {
        border: 1px solid #e6e7e9;
        padding: 25px;

        .plan-details {
          p {
            font-size: 14px;
            color: #5e5e5e;
          }

          .plan-name {
            position: relative;
            margin-top: 23px;

            span.name {
              img {
                margin-right: 6px;
                width: 35px;
                margin-top: -6px;
              }
            }

            p {
              font-size: 12px;
              color: #000000;
            }

            .period {
              background-color: #dff5ef;
              color: #22946b;
              font-size: 10px;
              white-space: nowrap;
              padding: 3px 5px 1px;
              border-radius: 2px;
              position: absolute;
              top: 15%;
              margin-left: 8px;
              font-weight: normal;
            }
          }

          .plan-amount {
            font-size: 30px;
            padding: 10px 0px 20px;
            color: #000000;

            span {
              color: #9ca0a9;
              font-size: 14px;
              font-weight: 100;
            }
          }
        }

        .cancel-footer {
          margin-top: 50px;

          .load-image {
            height: 50px;
            margin-bottom: 20px;
            border: solid 1px #be1931;
            background-color: #be1931;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 25%;
            }
          }

          .cancel-agree {
            padding: 10px 0;
            margin-bottom: 20px;

            ~button {
              width: 100% !important;

              &.danger {
                border: solid 1px #EF4444;
                background-color: #EF4444;
                color: #ffffff;
                margin-bottom: 15px;

                &:hover {
                  border: 1px solid #ac1128;
                  background-color: #ac1128;
                  color: #ffffff;
                }
              }
            }

            label {
              color: #5e5e5e;
              margin-left: 5px;
            }

            span {
              color: rgba(52, 43, 194, 1);
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .credits-overview-main-container {
    margin-top: 40px;
    margin-bottom: 40px;

    .credits-overview-wrapper {
      border: 1px solid #E6E7E9;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 40px 40px;

      .credits-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .credits-header-left {
          display: flex;
          flex-direction: column;

          .credits-title {
            font-size: 18px;
            font-weight: 500;
          }

          .credits-subtitle {
            font-size: 14px;
            color: #5E5E5E;
            margin-bottom: 0;
          }
        }

        .credits-header-right {
          display: flex;
          gap: 9px;

          .blue-button {
            font-size: 14px !important;
            width: fit-content !important;
            min-width: auto !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
          }

          .transparent-button {
            width: fit-content !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
            min-width: auto;
            border-radius: 2px;
            border: 1px solid var(--Icons-Brand, #342BC2);
            background: var(--text-blue-dark-surface-primary, #FFF);
            color: var(--Icons-Brand, #342BC2);
            font-size: 14px !important;
            font-weight: 400 !important;
          }

          .btn-outline-primary {
            color: #342bc2;
            border-color: #342bc2;
            font-size: 14px !important;
          }

          .btn-outline-primary:hover {
            background-color: transparent;
          }
        }
      }

      .credits-body {
        display: flex;
        align-items: center;
        gap: 4px;

        .credits-count {
          font-size: 32px;
          font-weight: 500;
          margin-bottom: 0;
          line-height: 38px;
        }
      }

      .credits-footer {
        .credits-chip {
          background-color: #FF9E2A0F;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #FF9E2A;
          padding: 12px;
        }

        .chip-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          margin-bottom: 0;
        }
      }
    }
  }

  .credits-history-top-navigation {
    margin-bottom: 20px;

    .billing-text {
      font-size: 14px;
      font-weight: 400;
      color: var(--brand-color);
      cursor: pointer;
    }

    .seperator-icon {
      font-size: 14px;
      font-weight: 400;
      color: #9CA0A9;
    }

    .credits-history-text {
      font-size: 14px;
      font-weight: 400;
      color: #9CA0A9;
    }
  }

  .credits-history-wrapper {
    border: 1px solid #E6E7E9;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .credits-history-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .history-header-left {
        display: flex;
        flex-direction: column;

        .credits-title {
          font-size: 16px;
          font-weight: 500;
        }

        .credits-subtitle {
          font-size: 14px;
          color: #5E5E5E;
          margin-bottom: 0;
        }
      }

      .blue-button {
        font-size: 14px !important;
        width: fit-content !important;
        min-width: auto !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }

    .credits-status-container {
      border: 1px solid #E6E7E9;
      display: flex;
      justify-content: space-between;
      padding: 30px 50px;

      .credits-status-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .status-label {
          font-size: 14px;
          margin-bottom: 0;
        }

        .status-value {
          font-size: 40px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      .status-divider {
        flex: 0.5;

        hr {
          margin: auto;
          height: 100%;
          width: 1px;
          border: 1px solid #EFEFEF;
        }
      }
    }

    .credits-history-container {
      position: relative;

      .credit-table-filters {
        padding-bottom: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        .filter-date-container {
          display: flex;
          align-items: center;

          .custom-input-container {
            display: flex;
            align-items: center;

            >img {
              padding-left: 4px;
            }
          }

          .filter-label {
            font-size: 14px;
            font-weight: 400;
            color: #9CA0A9;
            margin-bottom: 0;
            cursor: pointer;

            >span {
              color: #000000;
            }
          }
        }

        .filter-dropdown-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .filter-dropdown {
          background-color: transparent;
          font-size: 20px;
          border: none;
          text-align: center;
          height: 38px;

          &:hover {
            color: #ffffff;
            background-color: none;
            border: none;
          }

          &[aria-expanded="true"] {
            background-color: none;
            color: #ffffff;
            border: none;
          }

          .filter-dropdown-inner {
            color: #9CA0A9;
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            font-weight: 400;

            >span {
              color: #000000;
            }
          }
        }
      }

      thead,
      th {
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        padding: 10px;
        padding-bottom: 15px;
        border-bottom: 2px solid #E6E7E9 !important;
      }

      tbody {

        td {
          vertical-align: middle;
          text-align: left;
          font-size: 14px;
          padding: 15px 10px 15px;
          white-space: nowrap;
          color: #5E5E5E;

          .credit-action-inner {
            display: flex;
            justify-content: center;

            img {
              background-color: rgba(52, 43, 194, 0.05);
              padding: 11px;
              cursor: pointer;
            }
          }

          .fade-action-icon {
            opacity: 0.25;
            cursor: none;
            pointer-events: none;
          }
        }

        td.credit-text-center {
          text-align: center;
        }
      }
    }

    .pagination-container {
      width: 100%;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pagination-status {
        font-size: 10px;
        margin-bottom: 0;
      }

      .pagination-block {
        padding: 0;
      }

    }
  }
}

.card-modal {
  max-width: 560px;
}

.card-details {
  padding: 20px;

  .card-details-header {
    position: relative;

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }

    p {
      font-size: 12px;
      color: #9ca0a9;
    }

    .closes-button {
      position: absolute;
      right: 0;
      top: 0;
    }

  }

  .card-form {
    padding-top: 10px;

    .information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .form-group {
        margin-bottom: 2px;

        span.error-valid {
          color: rgba(239, 68, 68, 1);
          font-size: 13px;
        }
      }

      .card-number-block {
        width: 60%;
      }

      .card-expiry-block {
        width: 20%;
      }

      .card-cvv-block {
        width: 20%;
      }

      &.error-card-blocks {
        margin-bottom: 30px;
      }
    }
  }
}


.upgrade-wrapper {
  margin-bottom: 40px;

  .header {
    position: sticky;
    z-index: 999;
    top: 0;
    background: #ffffff;
    padding: 50px 0 40px 0;

    span {
      color: #9ca0a9;
      font-size: 16px;
      margin-right: 5px;

      &.toggle {
        color: #000000 !important;
        cursor: pointer;

        img {
          margin-right: 5px;
          margin-top: -2px;
          width: 15px;
        }
      }

      &.cancel-plan-icon {
        img {
          margin-right: 4px;
          width: 15px;
          margin-top: -3px;
        }
      }

      i {
        margin: 0 4px;
      }
    }
  }

  .upgrade-blocks {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;

    .info,
    .review {
      width: 50%;
    }

    .info {
      .billing {
        border: 1px solid #e6e7e9;
        padding: 20px;
        margin-bottom: 20px;

        h4 {
          color: #000000;
          font-size: 14px;
          font-weight: 400;
        }

        .cycle-switch {
          display: flex;
          padding: 10px 0 28px;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .switch-arrow {
            // position: relative;
            width: 100%;
            height: 20px;
            width: 20px;

            &::before {
              content: "";
              background-image: url("../assests/icons/white-nav-arrow.svg");
              background-repeat: no-repeat;
              background-size: 100%;
              width: 20px;
              height: 20px;
              background-color: #342bc2;
              border: 1px solid #342bc2;
              color: #ffffff;
              border-radius: 50%;
              // position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          h4 {
            color: #000000;
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
          }

          .amount {
            display: flex;
            align-items: baseline;
            gap: 5px;

            h5 {
              font-size: 24px;
              font-weight: 500;
              color: #000000;

              span {
                font-size: 12px;
              }
            }

            h6 {
              color: rgba(156, 160, 169, 1);
              font-size: 10px;
              font-weight: 400;
              white-space: nowrap;
            }
          }

          .period-billing {
            border: 1px solid rgb(52, 43, 194);
            padding: 15px 40px 10px 20px;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
            width: 44%;

            .save-money {
              position: absolute;
              color: #ffffff;
              background-color: #342bc2;
              border-radius: 2px;
              font-weight: 400;
              font-size: 11px;
              padding: 2px 6px;
              display: inline-flex;
              gap: 3px;
              top: -12px;
              right: 12px;

              p {
                font-size: 11px;
                margin-bottom: 0;
              }

            }
          }
        }
      }

      .payment-details {
        border: 1px solid #e6e7e9;
        padding: 20px;
        margin-bottom: 20px;

        .gateway-details .apply-button {
          top: auto !important;
        }

        ~.cycle-banner {
          margin-bottom: 20px;
        }

        ~p {
          color: #9ca0a9;
          font-size: 12px;
        }
      }
    }

    .review {
      text-align: left;
      background-color: rgba(52, 43, 194, 0.02);
      padding: 40px 30px 40px;
      // width: 510px;

      h4 {
        font-size: 16px;
        color: #000000;
      }

      .plan-duration {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        p {
          font-size: 14px;
          color: #5e5e5e;
          margin-bottom: 10px;
        }
      }

      .discount {
        padding: 0px 0 5px;

        .payment-separate-line {
          background-color: #e6e7e9;
          height: 1px;
          opacity: 1;
          margin: 1rem 0;
        }

        .discount-label {
          font-weight: 400;
          font-size: 16px !important;
          color: #000000;
        }

        .redeem-icon {
          background-image: url("../assests/icons/redeem.svg") !important;
          background-repeat: no-repeat !important;
          background-position: 7px !important;
          padding: 5px 85px 5px 45px !important;
        }


        .validation {
          color: rgba(239, 68, 68, 1);
          font-weight: 400;
          font-size: 14px;
        }

        .apply-button {
          background-color: transparent;
          color: #342bc2;
          border: 1px solid transparent;
          font-size: 14px;
          position: absolute;
          right: 10px;
          top: 55%;

          &:hover {
            background-color: transparent;
            color: #342bc2;
            border: 1px solid transparent;
          }
        }

        .discount-value {
          background-color: #dff5ef;
          color: #22946b;
          font-size: 10px;
          white-space: nowrap;
          padding: 3px 5px 1px;
          border-radius: 2px;
          position: absolute;
          top: 2px;
          margin-left: 8px;
        }

        h4 {
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }

        .discount-summary {
          .deductions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              margin: 0;
              margin-block-start: 0em;
              margin-block-end: 0em;
              font-weight: 400;
              font-size: 16px;
              color: #5e5e5e;
            }

            &.tax-amount {
              padding-bottom: 10px;
            }
          }
        }

        .total {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-weight: 500;
            font-size: 18px;
            color: #000000;
          }
        }
      }

      .checkout {
        .load-image {
          height: 50px;
          margin-bottom: 20px;
          border: solid 1px #342bc2;
          background-color: #342bc2;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 25%;
          }
        }

        span.prefix {
          position: relative;
        }

        .taxes {
          font-weight: 400;
          font-size: 14px;
          color: #9ca0a9;
        }
      }
    }
  }
}

.plans-body {
  padding: 0 0 0 0;
  position: relative;
}

.plans-wrapper {
  max-height: 92vh;
  overflow: auto;
  background: #ffffff;
  padding: 30px 40px 30px;

  .pricing-blue-btn {
    border-radius: 2px;
    border: 1px solid #342bc2 !important;
    width: 100%;
    height: 45px;
    background: #342bc2 !important;
    background-color: #342bc2 !important;
    color: #fff;
    padding: 0 22px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    cursor: pointer !important;

    &:hover {
      background: #342bc2 !important;
      background-color: #342bc2 !important;
      color: #fff;
      border: 1px solid #342bc2 !important;
      cursor: pointer !important;
    }
  }

  .pricing-white-btn {
    border-radius: 2px;
    border: 1px solid #342bc2 !important;
    width: 100%;
    height: 45px;
    background: #ffffff !important;
    background-color: #ffffff !important;
    color: #342bc2 !important;
    padding: 0 22px;
    font-size: 14px;
    margin-top: 15px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      background: #ffffff !important;
      background-color: #ffffff !important;
      color: #342bc2;
      border: 1px solid #342bc2 !important;
    }

    &:active {
      color: #342bc2 !important;
    }

    &.recommended-btn {
      border: none !important;
    }
  }

  .pricing-plain-btn {
    border-radius: 2px;
    border: 1px solid transparent !important;
    width: 100%;
    height: 45px;
    background: #ffffff !important;
    background-color: #ffffff !important;
    color: #342bc2 !important;
    padding: 0 22px;
    margin-top: 0px;
    margin-bottom: -5px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }

  .font-size {
    font-size: 14px;
  }

  .closes-button {
    margin-top: -11px;
    text-align: right;

    button {
      text-align: right;
      width: 50px;
      height: auto !important;
      background-color: transparent !important;
      background: transparent !important;
      border: 1px solid transparent !important;
      color: #000000;
      font-weight: 400;
      position: absolute;
      top: 5px;
      right: -2px;

      &:hover {
        background-color: transparent !important;
        background: transparent !important;
        border: 1px solid transparent !important;
        color: #000000;
        font-weight: 400;
      }
    }
  }

  .tab-header {
    h4 {
      font-size: 1rem;
      margin-bottom: 0.1rem;
    }

    p {
      margin-bottom: 1rem;
      font-size: 12px;
    }
  }

  .more-features-banner {
    border: 1px solid #e6e7e9;
    border-radius: 2px;
    border-left-color: #342bc2;
    border-left-width: thick;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    position: relative;
    z-index: 1;
    clip-path: inset(0);

    .icon-plus-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .crown-icon {
        width: 40px;
      }

      .more-features-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        h4 {
          margin-bottom: 5px;
          font-size: 14px;
          color: #000000;

          span {
            color: #342bc2;
          }
        }

        p {
          margin-bottom: 0;
          font-size: 10px;
          color: #5e5e5e;
          white-space: nowrap;
        }
      }
    }

    .img-plus-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      img {
        width: 90%;
      }

      button {
        min-width: unset !important;
        padding-left: 30px;
        padding-right: 30px;
        white-space: nowrap;
        height: 30px !important;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 10px !important;
      }
    }
  }

  .plan-cards {
    .plan-list {
      text-align: left;
      display: flex;
      justify-content: center;
      gap: 30px;
      width: 100%;
      margin: 20px auto 20px auto;
      position: relative;

      .card-body {
        z-index: 1;
        background-color: #FFFFFF;
        border: 1px solid #E6E7E9;
        flex: none;
        margin-bottom: 20px;
        padding: 16px 22px 20px !important;
        border-radius: 2px;

        &.recommeded-bg {
          background: linear-gradient(90deg, #2667B5 0%, #5930FD 100%) !important;
        }
      }

      .plan-features {
        list-style: none;
        padding-left: 0 !important;
        margin-bottom: 10px !important;

        li {
          font-size: 14px;
          color: var(--text-secondary-color);

          .selected-tick-icon {
            margin-right: 5px;
          }
        }
      }

      .plan-list-row {
        width: 100%;
        justify-content: space-between;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .pricing-page-pattern {
        position: absolute;
        right: 0;
        overflow: hidden;
        height: 100%;
      }

      .cards-margin {
        border-radius: 2px;
        border: none !important;
        background-color: transparent !important;
      }

      .card.recommended {
        border: 2px solid #342bc2;
      }

      .plan-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #5e5e5e;
        height: 66px;

        &.recommended-text {
          color: #FFFFFFBF !important;
        }

        &.new-teams-text {
          color: #9CA0A9 !important;
        }
      }

      .plan-heading {
        font-size: 16px;
        display: flex;
        gap: 20px;
        align-items: center;
        color: #000000;
        margin-bottom: 0;
        position: relative;

        p {
          margin-bottom: 0;
        }

        .recommended-p {
          color: #FFFFFF;
        }

        .icon-present {
          margin-left: 30px;
        }

        .logo-badge {
          position: absolute;
          left: 0px;
          bottom: 6px;
          width: 26px;
        }

        .logo-badge-hidden {
          display: none;
        }

        .popular-tag-hidden {
          visibility: hidden;
        }

        .popular-tag {
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 5px;
          background: #FFFFFF;
          padding: 2px 14px;
          border-radius: 2px;
          color: black;
          font-weight: 400;
          margin-left: auto;
          position: absolute;
          right: -5px;
          bottom: 25px;
          border: 1px solid #E6E7E9;
          line-height: 25px;
        }

        .new-teams-tag-hidden {
          visibility: hidden;
        }

        .new-teams-tag {
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 5px;
          background: #FF9E2A !important;
          padding: 2px 10px;
          border-radius: 2px;
          color: #FFFFFF;
          font-weight: 400;
          margin-left: auto;
          position: absolute;
          right: -5px;
          bottom: 25px;
          line-height: 25px;
        }
      }

      .amount {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        padding-top: 0px;
        padding-bottom: 5px;

        .recommended-amount {
          color: #FFFFFF !important;
        }

        .card-title {
          font-size: 34px;
          color: #000000;
          margin-bottom: 0;
          font-weight: 600 !important;
        }

        .card-subtitle {
          font-size: 14px;
          color: #9ca0a9;
          font-weight: 400;

          .period-text {
            position: relative;

            img {
              position: absolute;
              top: -2px;
              left: 60px;
              width: 30%;
            }

            span {
              background-color: #dff5ef;
              color: #22946b;
              position: absolute;
              font-size: 9px;
              white-space: nowrap;
              padding: 2px 8px;
              border-radius: 2px;
              top: -21px;
              right: -58px;
            }
          }
        }

        ul {
          padding-inline-start: 20px;
          padding-inline-end: 20px;
          padding-bottom: 20px;

          li {
            list-style-type: none;
            position: relative;
            font-size: 14px;
            color: #5E5E5E;
            line-height: 20px;
            padding-inline-start: 12px;
            text-align: justify;
            margin-bottom: 0;
            font-weight: 400;

            img {
              position: absolute;
              top: 15%;
              left: -20px;
            }
          }
        }
      }
    }
  }
}

.pricing-header-lazy {

  // padding-top: 115px;
  .shimmer-button--lg {
    // width: 500px;
    height: 35px;
  }

  .shimmer-button--md {
    width: 300px;
    height: 30px;
  }

  div {
    // margin: auto;
    margin-bottom: 15px;
  }
}

.plan-list-container {
  display: flex;
  gap: 20px;
  // width: 63%;
  justify-content: center;
  margin: auto;

  .plan-list-lazy {
    margin-top: 10px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 65px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #f1efef;
    border-radius: 6px;

    // width: 45%;
    .shimmer-button--sm {
      width: 110px;
      height: 25px;
    }

    .shimmer-button--md {
      width: 100%;
      height: 45px;
    }

    .shimmer-button--lg {
      width: 100%;
      height: 50px;
    }

    .amount-lazy {
      display: flex;
      align-items: center;
      gap: 10px;

      .shimmer-button--sm {
        width: 50px;
        height: 50px;
      }

      .shimmer-button--md {
        width: 50px;
        height: 20px;
      }
    }

    .items-container {
      margin-top: 25px;

      .items-lazy {
        display: flex;
        gap: 15px;
        align-items: center;

        .shimmer-button--sm {
          width: 20px;
          height: 20px;
        }

        .shimmer-thumbnail {
          height: 20px !important;
        }
      }
    }
  }
}


.cancel-btn-new {
  border: none !important;
  background-color: transparent !important;
  font-size: 14px;
  color: var(--error-red) !important;

  &:hover {
    background-color: transparent !important;
    border: none !important;
  }
}

.invite-members-modal-container {
  &.leave-team-modal {
    width: 328px;
    height: 304px;

    .leave-team-button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      margin-top: 25px;

      .cancel-button {
        margin: 0 !important;
        width: 136px !important;
        height: 42px !important;
        color: var(--Text---Light-mode-primary, #000) !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        background: transparent !important;
        border: 1px solid var(--Grey-Line, #E6E7E9) !important;
        border-radius: 2px !important;

        &:hover {
          background: transparent !important;
          border: 1px solid var(--Grey-Line, #E6E7E9) !important;
        }
      }

      .leave-button {
        margin: 0 !important;
        color: var(--White, #FFF) !important;
        width: 136px !important;
        height: 42px !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        border: none !important;
        border-radius: 2px !important;
        background: #E50000;

        &:hover {
          background-color: #ce1212 !important;
        }
      }
    }
  }

  &.left-team-modal {
    width: 434px;
    height: 356px;

    .left-team-gif-container {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      .left-team-gif {
        width: 221px;
        height: 221px;
      }
    }

    .left-team-header {
      align-items: center !important;
      justify-content: center !important;
      margin-bottom: 5px;

      h4 {
        color: var(--Text---Light-mode-primary, #000);
        text-align: center;
        font-family: Rubik;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .left-team {
      color: var(--Text---Light-mode-secondary, #5E5E5E);
      text-align: center;
      font-family: Rubik;
      font-style: normal;
      font-weight: 400;
      padding-bottom: 30px;
    }
  }

  &.renew-subscription-modal {
    .renew-subscription-header {
      margin-bottom: 20px !important;
      padding-top: 6px;

      h4 {
        color: var(--Text---Light-mode-primary, #000);
        font-family: Rubik;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 500 !important;
      }
    }

    .renew-subscription-wrapper {
      .renew-subscription {
        color: var(--Text---Light-mode-secondary, #5E5E5E);
        font-family: Rubik;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 20px !important;
      }

      .leave-team-button-container {
        .cancel-button {
          font-weight: 400 !important;
        }

        .renew-button {
          margin: 0 !important;
          color: var(--White, #FFF) !important;
          width: 136px !important;
          height: 42px !important;
          text-align: center !important;
          font-family: Rubik !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          border: none !important;
          border-radius: 2px !important;
          background-color: var(--brand-color);
          color: var(--text-active-color);

          &:hover {
            background-color: #272092;
            border: 1px solid #272092;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      color: #000000;
    }

    &.leave-team-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 15px;

      h4 {
        font-size: 18px;
      }
    }
  }

  hr {
    color: #e6e7e9;
  }

  .invite-wrapper {
    background-color: var(--text-active-color);

    h4 {
      font-size: 14px;
      margin-bottom: 0.25rem;
      font-weight: 400;
    }

    .invite-link-description {
      color: var(--Text---Light-mode-tertiary, #9CA0A9);
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;

      .bold-part {
        font-weight: 500;
        color: var(--Text---Light-mode-primary, #000);
      }

      &.leave-team {
        font-size: 16px;
        color: var(--Text---Light-mode-secondary, #5E5E5E);
        margin-bottom: 10px;
      }
    }

    .enabling-invite-link-container {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 15px;

      .enabling-invite-link-switch {
        cursor: pointer;
      }

      .enabling-invite-link-description {
        margin-bottom: 0;
        color: #000;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .invite-link-short-description {
      color: var(--Text---Light-mode-tertiary, #9CA0A9);
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
    }

    .project-link {
      padding: 4px 20px 0 20px;

      .url {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--border-color);
        border-radius: 2px;
        padding: 5px 5px 5px 10px;
        margin: 10px 0 0;
        height: 45px;

        p {
          font-size: 12px;
          padding-right: 2px;
          max-width: 328px;
          margin-bottom: 0px;
          color: var(--text-tertiary-color);
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        button {
          font-size: 12px;
          padding: 8px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--brand-light-color);
          color: var(--active-blue-color);
          border: transparent;
          gap: 4px;
          width: 72px !important;

          &:hover {
            background-color: var(--brand-light-color);
          }
        }

        .regenerate-code-icon {
          cursor: pointer;
          margin-right: 12px;
          margin-left: 5px;
        }
      }
    }

    .invite-box {
      padding: 0 20px;

      .error-text {
        color: var(--Danger-Primary, #EF4444);
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .email-invites-role-container {
        display: flex;
        align-items: center;
        gap: 7px;

        .form-group {
          margin: 0;
          display: flex;
          align-items: center;

          >div:first-child {
            width: 100%;
          }

          .input-style {
            height: 40px !important;
            width: 100%;
            font-size: 12px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            margin: 7px 0 0;
            max-height: 400px;
            overflow-y: scroll;
          }

          .input-style::placeholder {
            font-size: 12px;
          }

          .role-distribution {
            border: 1px solid var(--border-color);
            border-left: none;
            border-radius: 0 !important;

            .dropdown-toggle {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2px;
              height: 40px;
              background-color: transparent !important;
              color: var(--brand-color);
              outline: none;
              border: none;
              width: 100% !important;
              padding: 0 12px !important;
              font-size: 12px;
              box-shadow: -10px 0px 10px rgba(217, 217, 217, 0.4) !important;
            }
          }
        }
      }

      .grant-permission-checkbox {
        margin-top: 12px !important;
        display: flex;
        align-items: center;
        gap: 4px;

        .input-style {
          position: relative !important;
          bottom: 2px;
        }

        .grant-permission-checkbox-text {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0;
          margin-left: 2px;
        }

        img {
          cursor: pointer;
        }
      }

      .invite-button {
        font-size: 12px;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--brand-color);
        color: var(--text-active-color);
        border: transparent;
        gap: 4px;
        width: 86px !important;
        border-radius: 2px !important;
        height: 36px;
        margin: 24px 0 22px auto;
        font-weight: 400 !important;

        &:hover {
          background-color: #272092;
          border: 1px solid #272092;
        }

        &.grant-license {
          width: 84px !important;
        }

        &.request-license {
          width: 86px !important;
        }

        &.upgrade-plan-button {
          width: 84px !important;
        }

        &.revoke-license {
          background: #E50000;
          border: none !important;

          &:hover {
            background-color: #ce1212 !important;
          }
        }

        &.remove-member {
          background: #E50000;
          width: 116px !important;
          border: none !important;

          &:hover {
            background-color: #ce1212 !important;
          }
        }
      }

      .multi-input {
        width: 100%;
        margin-top: 10px;

        .tags-input-container {
          display: flex;
          flex-direction: column;
        }

        .tags-input {
          border: 1px solid rgba(230, 231, 233, 1);
          border-radius: 2px;
          padding: 0px;
          min-height: 32px;
          max-height: 106px;
          overflow-y: auto;

          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: var(--Grey-Line, #E6E7E9);
          }

          &::-webkit-scrollbar-track {
            background: transparent !important;
          }
        }

        .tag {
          background-color: rgba(246, 246, 246, 1);
          color: rgba(156, 160, 169, 1);
          padding: 2px 6px;
          margin: 3px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          font-size: 14px;
          gap: 8px;
        }

        .ws-billing-members-tag {
          font-size: 14px !important;
          margin-left: 3px;
        }

        .tag button {
          background: none;
          border: none;
          color: rgba(156, 160, 169, 1);
          cursor: pointer;
          padding: 0;
        }

        .tag button:hover {
          color: #000000;
        }

        .tags-input input {
          flex: 1;
          border: none;
          outline: none;
          font-size: 14px !important;
          min-width: 20px;

          &::placeholder {
            font-size: 14px;
            color: rgba(156, 160, 169, 1);
            opacity: 1;
          }

          &:not(:placeholder-shown) {
            background-color: #ffffff;
          }

          &:focus-within {
            font-size: 14px;
            border: none !important;
            color: rgb(0, 0, 0) !important;
          }
        }

      }
    }
  }

  .closes-button {
    button {
      height: 30px !important;
      text-align: center;
      font-size: 11px;
      border-radius: 2px;
      color: #ffffff;
      background-color: #000000;
      border: 1px solid #000000;

      &:hover {
        color: #000000;
        background-color: #e9ecef;
        border: 1px solid #e9ecef;
        font-weight: 900;
      }
    }
  }

  .close-player {
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      height: 30px !important;
      text-align: center;
      font-size: 11px;
      border-radius: 2px;
      color: #ffffff;
      background-color: #000000;
      border: 1px solid #000000;

      &:hover {
        color: #000000;
        background-color: #e9ecef;
        border: 1px solid #e9ecef;
        font-weight: 900;
      }
    }
  }

  .nav-tabs .nav-item {
    width: 33.3%;
    text-align: center;
  }

  // .nav-tabs .nav-link {
  //   // border: 1px solid transparent;
  //   // border-top-left-radius: unset;
  //   // border-top-right-radius: 0.25rem;
  //   // position: relative;
  //   // color: var(--text-tertiary-color);
  //   // font-size: 14px;
  //   // padding-bottom: 12px;
  //   // cursor: pointer;

  //   .bar {
  //     display: flex !important;
  //     position: absolute;
  //     height: 4px;
  //     top: 38px;
  //     border: none;
  //     border-radius: 10px;
  //   }

  //   &:hover {
  //     .bar {
  //       background: #e9ecef;
  //     }
  //   }

  //   &.active {
  //     // background-color: transparent;
  //     // border-color: transparent;
  //     // color: var(--text-primary-color);

  //     .bar {
  //       background: var(--active-blue-color);
  //     }
  //   }
  // }
}

.default-amount {
  font-size: 14px !important;
  text-decoration: line-through;
  font-weight: 400 !important;
  color: #FFFFFFBF !important;
}

.default-month {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #FFFFFFBF !important;
}

.new-teams-amount {
  font-size: 14px !important;
  text-decoration: line-through;
  font-weight: 400 !important;
  color: #9CA0A9 !important;
}

.new-teams-month {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #9CA0A9 !important;
}

.translate-issue-fix-percent-off {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  padding: 3px 5px 1px 0px !important;
  margin-left: 25px !important;
}

.translate-issue-fix-percent {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.invalid-link-error-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .invalid-link-error-wrapper {
    padding: 0 155px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .invalid-link-error-title {
      color: var(--Text---Light-mode-primary, #000);
      text-align: center;
      font-size: 30px;
      font-weight: 500;

      span {
        color: var(--Brand-Main, #342BC2);
      }
    }

    .invalid-link-error-description {
      color: var(--Text---Light-mode-secondary, #5E5E5E);
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 10px;
    }

    .prime-btn {
      margin-top: 30px;

      &.invalid-link-error-button {
        min-width: auto !important;
        padding: 0 30px;
        height: 60px !important;
      }
    }

    .invalid-link-error-image {
      height: 350px;
      width: auto;
      margin-top: 90px;
    }

    .secondaryPolygon-img {
      margin: -120px 0 0 -650px;
      position: absolute;
    }

    .dashedPolygon-img {
      margin: 50px 0 0 -450px;
      position: absolute;
    }

    .dottedRectangle-img {
      margin: 143px 0 0 -715px;
      position: absolute;
    }

    .polygon-img {
      position: absolute;
      margin: 106px 0 0 350px;
      z-index: -1;
      width: 35px;
    }

    .blue-curve-img {
      position: absolute;
      margin: 125px 0 0 486px;
      z-index: -1;
      width: 175px;
    }
  }
}

.lazy-team-requests-container {
  margin-top: 20px;

  .lazy-team-requests-search-bar {
    width: 191px;
  }

  .lazy-team-requests-row-one {
    margin-top: 60px;

    .lazy-team-requests-header-title-one {
      width: 60px;
      height: 20px;
    }

    .lazy-team-requests-header-title-two {
      width: 60px;
      height: 20px;
      margin-left: 40px;
    }

    .lazy-team-requests-header-title-three {
      width: 100px;
      height: 20px;
      margin-right: 30px;
      margin-left: 30px;
    }

    .lazy-team-requests-header-title-four {
      width: 60px;
      height: 20px;
      margin-right: 30px;
    }
  }

  .lazy-team-requests-row-two {
    margin-top: 20px;

    .lazy-team-requests-data-one {
      width: 130px;
      height: 17px;
      margin-top: 5px;
    }

    .lazy-team-requests-data-two {
      width: 200px;
      height: 17px;
      margin-top: 5px;
      margin-left: 40px;
    }

    .lazy-team-requests-data-three {
      width: 128px;
      height: 17px;
      margin-top: 5px;
      margin-left: 30px;
    }

    .lazy-team-requests-data-collective {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 5px;

      .lazy-team-requests-data-four {
        width: 86px;
        height: 17px;
      }

      .lazy-team-requests-data-five {
        width: 48px;
        height: 17px;
      }
    }
  }
}

.lazy-team-people-container {
  .lazy-team-people-row-one {
    padding: 40px 40px;

    .lazy-team-people-row-one-data-one {
      width: 97px;
      height: 16px;
    }

    .lazy-team-people-row-one-data-two {
      width: 126px;
      margin-bottom: 0;
    }

    .lazy-team-people-row-one-data-three {
      width: 97px;
      height: 16px;
    }
  }

  .lazy-team-people-row-two {
    margin-top: 30px;

    .lazy-team-people-row-two-data-one {
      width: 183px;
      height: 17px;
    }

    .lazy-team-people-row-two-data-two {
      width: 88px;
      height: 17px;
      margin-left: 20px;
    }
  }

  .lazy-team-people-row-three {
    margin-top: 45px;

    .lazy-team-people-row-three-data-one {
      width: 60px;
      height: 20px;
    }

    .lazy-team-people-row-three-data-two {
      width: 60px;
      height: 20px;
    }

    .lazy-team-people-row-three-data-three {
      width: 63px;
      height: 20px;
      margin-left: 30px;
    }

    .lazy-team-people-row-three-data-four {
      width: 88px;
      height: 20px;
      margin-left: 55px;
    }

    .lazy-team-people-row-three-data-five {
      width: 51px;
      height: 20px;
      margin-left: 70px;
    }
  }

  .lazy-team-people-row-four {
    margin-top: 18px;

    .lazy-team-people-row-four-data-one {
      width: 130px;
      height: 17px;
    }

    .lazy-team-people-row-four-data-two {
      width: 202px;
      height: 17px;
    }

    .lazy-team-people-row-four-data-three {
      width: 54px;
      height: 17px;
      margin-left: 30px;
    }

    .lazy-team-people-row-four-data-four {
      width: 60px;
      height: 17px;
      margin-left: 55px;
    }

    .lazy-team-people-row-four-data-five {
      width: 24px;
      height: 18px;
      margin-left: 70px;
    }
  }
}

.team-image {
  // margin: 10px 0 12px 0;
  width: 20%;
  position: relative;
  cursor: pointer;

  &:hover {
    .hover-image-block {
      display: block;
    }

  }

  .hover-image-block {

    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-58%) translateY(-47%);
    -webkit-transform: translateX(-58%) translateY(-47%);
    transform: translateX(-58%) translateY(-47%);
    width: 100%;
    transition: opacity 0.5s ease;
    height: 100%;
    display: none;
    border-radius: 2px;

    span.view {
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translateX(-58%) translateY(-47%);
      -webkit-transform: translateX(-58%) translateY(-47%);
      transform: translateX(-58%) translateY(-47%);
      color: var(--text-active-color);
    }
  }

  &.uploaded-image {
    .hover-image-block {
      span.close {
        position: absolute;
        top: 0px;
        right: -10px;
        transform: translateX(-60%) translateY(-54%);
        color: var(--text-primary-color);
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.comment-image {
      margin: 10px 0 12px 6px;
    }
  }
}

.error-text {
  color: var(--Danger-Primary, #EF4444);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.members-multi-checkbox-header {
  position: relative !important;
  bottom: 9px !important;
  margin-right: 10px;
}

.members-multi-checkbox-body {
  position: relative !important;
  top: 2px !important;
  margin-right: 10px;
}

.members-selected-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 20px;
  background: linear-gradient(90deg, #2667B5 0%, #5930FD 100%);
  border-radius: 2px;
  margin-top: 5px;

  .left-part {
    display: flex;
    align-items: center;
    gap: 8px;

    .white-checkbox-icon {
      height: 20px;
      width: auto;
    }

    .members-selected-text {
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 0;
    }
  }

  .right-part {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    img {
      cursor: pointer;
    }
  }
}

.date-picker-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  box-shadow: 8px 10px 32px 8px #342BC20F;
  background-color: white;
  padding: 20px;
  z-index: 999999;
  margin-bottom: 20px;

  .date-picker-footer {
    .date-picker-apply-button {
      background-color: #342bc2;
      border-radius: 2px;
      border: 1px solid #342bc2;
      padding: 8px 24px;
      font-size: 15px;

      &:hover {
        background-color: #272092;
        border: 1px solid #272092;
      }
    }

    .date-picker-close-button {
      border: 1px solid #e6e7e9;
      background: #ffffff;
      border-radius: 2px;
      color: #000;
      padding: 8px 24px;
      font-size: 15px;
      margin-left: 15px;

      &:hover {
        background-color: #ededed;
        color: #000;
        border: 1px solid #ededed;
      }
    }
  }

  .rdp-today:not(.rdp-outside) {
    color: var(--brand-color) !important;
  }

  .rdp-caption_label {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .rdp-selected {
    font-weight: 300 !important;
    font-size: 14px;

    .rdp-day_button {
      border-radius: 2px;
    }
  }

  .rdp-range_start,
  .rdp-range_end {
    .rdp-day_button {
      background-color: var(--brand-color) !important;
      border: var(--brand-color) !important;
      border-radius: 2px;
    }
  }

  .rdp-day {
    width: 36px;
    height: 36px;
    padding: 0 !important;
  }

  .rdp-day_button {
    width: 34px;
    height: 34px;
    font-size: 14px;
  }
}