:root {
  --brand-color: rgba(52, 43, 194, 1);
  --brand-light-color: rgba(52, 43, 194, 0.04);
  --text-secondary-color: #5e5e5e;
  --text-primary-color: #000000;
  --text-tertiary-color: #9ca0a9;
  --text-active-color: #ffffff;
  --border-color: #e6e7e9;
  --text-inactive-color: #979797;
  --active-blue-color: #342bc2;
  --background-color: #f6f6f6;
  --background-light-color: #eeeeee;
  --success-green: rgba(34, 148, 107, 1);
  --success-light-green: rgba(34, 148, 107, 0.08);
  --warning-orange: rgba(255, 158, 42, 1);
  --warning-light-orange: rgba(255, 158, 42, 0.08);
  --error-red: rgba(239, 68, 68, 1);
  --error-light-red: rgba(239, 68, 68, 0.08);
  --icon-color: #323232;
  --primary-gradient: linear-gradient(90deg, #2667b5 0%, #5930fd 100%);
  --menu-slider-color: #eeeeee;
}

body {
  font-family: "Rubik", sans-serif;
  background-color: var(--pseudo-color);
  user-select: none;

  button.prime-btn.btn.btn-primary {
    min-width: 100%;
    height: 50px;
    background-color: #342bc2;
    border-radius: 2px;
    border: 1px solid #342bc2;

    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }
  }

  button.small-btn.btn.btn-primary {
    min-width: 35%;
    height: 50px;
    background-color: #342bc2;
    border-radius: 2px;
    border: 1px solid #342bc2;

    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }

    &.delete {
      background-color: #e50000 !important;
      border: 1px solid #e50000 !important;

      &:hover {
        background-color: #cf0a0a !important;
        border: 1px solid #cf0a0a !important;
      }
    }
  }

  .hr-line {
    height: 1px;
    background-color: rgba(230, 231, 233, 1);
    opacity: 0.7;
  }

  button.medium-btn.btn.btn-primary {
    min-width: 35%;
    height: 50px;
    background-color: #342bc2;
    border-radius: 2px;
    border: 1px solid #342bc2;

    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }
  }

  button.notification-btn.btn.btn-secondary {
    border: 1px solid #e6e7e9;
    background: #ffffff;
    border-radius: 2px;
    height: 50px;
    color: #000;

    &:hover {
      background-color: #ededed;
      border: 1px solid #ededed;
    }
  }

  button.small-skip-btn.btn.btn-primary {
    width: 30%;
    height: 50px;
    background-color: #fff;
    border-radius: 2px;
    color: #000;
    border-color: #342bc2;

    &:hover {
      background-color: #342bc2;
    }
  }

  .input-style {
    background: #ffffff;
    border-radius: 2px;
    border: solid 1px #e6e7e9;
    background-color: #ffffff;
    padding: 5px 15px;
    height: 50px;
    color: #000000;
  }

  .notDisable {
    background: #ffffff !important;
    border-radius: 2px;
    border: solid 1px #e6e7e9;
    background-color: #ffffff;
    padding: 5px 15px;
    height: 50px;
    color: #000000;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #efeefb inset !important;
  }

  .display-block {
    display: none;
  }

  .closes-button {
    button {
      height: 30px !important;
      text-align: center;
      font-size: 11px;
      border-radius: 2px;
      color: #ffffff;
      background-color: #000000;
      border: 1px solid #000000;

      &:hover {
        color: #000000;
        background-color: #e9ecef;
        border: 1px solid #e9ecef;
        font-weight: 900;
      }
    }
  }

  #nprogress .peg {
    display: none;
  }

  #nprogress .bar {
    height: 5px;
    background: #342bc2;
  }

  #nprogress .spinner {
    display: none;
  }

  input:not(:placeholder-shown) {
    background-color: rgba(52, 43, 194, 0.05);
    background: rgba(52, 43, 194, 0.05);
  }

  .label-input {
    font-weight: 400;
    padding: 5px 0;
    font-size: 14px;
    color: #000000;
  }

  .pull-right {
    text-align: right;
  }

  img {
    max-width: 100%;
  }

  .no-results {
    text-align: center;
    border: 1px solid #ffffff;
    background: #ffffff;
    margin-bottom: 40px;

    &.team-account-custom-style-wrong-search {
      margin-bottom: 0;

      .no-block {
        padding: 60px 65px;

        p {
          margin-bottom: 0;
        }
      }
    }

    &.team-account-custom-style-empty {
      margin-bottom: 0;

      .no-block {
        padding: 80px 85px;

        p {
          margin-bottom: 0;
        }
      }
    }

    &.geomap {
      width: 100%;
      height: 100%;
      padding: 10px;
      margin-bottom: 0px !important;

      .no-block {
        border: 1px dashed var(--active-blue-color);
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: var(--brand-light-color);
        padding: 0px !important;

        img {
          height: 20%;
          min-height: 50px;
          max-width: 200px;
          max-height: 200px;
        }

        h3 {
          font-size: 14px;
          font-weight: 500;
          color: "#000000";
        }

        p {
          font-size: 12px;
          margin: 0px;
          color: #5e5e5e;

          .try-again {
            color: #342bc2;
            cursor: pointer;
          }
        }
      }
    }

    .no-block {
      border: 1px solid #f6f6f6;
      background: #f6f6f6;
      padding: 150px 85px;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        color: #9ca0a9;
      }
    }

    &.error-api {
      max-width: 290px;

      .no-block {
        border: 1px solid #f6f6f6;
        background: #f6f6f6;
        padding: 130px 30px;

        h3 {
          font-size: 16px;
        }

        p {
          font-size: 13px;
          color: #9ca0a9;
        }
      }
    }

    &.chatbotError {
      margin-bottom: 0px;
      font-size: 14px;
      margin: 20px;

      .no-block {
        padding: 60px 30px;

        h3 {
          font-size: 14px;
          font-weight: 500;
        }

        p {
          font-size: 13px;
          color: #9ca0a9;
          font-weight: 400;
        }
      }
    }

    &.elements {
      padding: 85px 20px 60px;
      max-width: 277px;
      margin: 23px 0 0 12px;
      background-color: #f6f6f6;

      .no-block {
        border: 1px solid #f6f6f6;
        background: #f6f6f6;
        padding: 130px 30px;
      }

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 13px;
        color: #9ca0a9;
      }
    }

    &.popup {
      margin-bottom: 0px;

      .no-block {
        padding: 80px 85px;
      }
    }
  }

  .password-input {
    position: relative;

    img {
      position: absolute;
      top: 48px;
      right: 15px;
    }
  }

  .css-1nhhh2-control {
    &:focus-within {
      outline: 0 !important;
      border: 1px solid #e6e7e9 !important;
      box-shadow: none !important;
    }

    &:hover {
      border: 1px solid #e6e7e9 !important;
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    font-size: 14px;
    line-height: 26px;
    color: #5e5e5e;
    border: none;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05),
      0px 0px 5px rgba(0, 0, 0, 0.05);
    padding: 10px 15px 10px 0;
    border-radius: 2px;

    .dropdown-item {
      margin: 0 10px 0 7px;
      color: #5e5e5e;
      border: 1px solid transparent;

      &:hover {
        background-color: #342bc2;
        color: #ffffff;
        border: 1px solid #342bc2;

        &::after {
          content: "\f0da";
          text-align: right;
          color: #ffffff;
          font-family: FontAwesome;
          float: right;
          padding: 0 0;
        }
      }

      &.delete {
        color: #ff2d2d;

        &:hover {
          background-color: #ff2d2d;
          color: #ffffff;
          border: 1px solid #ff2d2d;

          &::after {
            content: "\f0da";
            text-align: right;
            color: #ffffff;
            font-family: FontAwesome;
            float: right;
            padding: 0 0;
          }
        }
      }
    }
  }

  button {
    box-shadow: none !important;
  }

  input {
    box-shadow: none !important;

    &:focus-within {
      border: 1px solid #e6e7e9 !important;
    }
  }

  .css-14dclt2-Input {
    input {
      border: none !important;
    }
  }

  .css-vjjs73-control {
    &:hover {
      border-bottom: 1px solid #e6e7e9 !important;
    }

    &:focus {
      border-bottom: 1px solid #e6e7e9 !important;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.no-dom {
  pointer-events: none;
}

.react-toast-notifications__container {
  .react-toast-notifications__toast {
    height: 90%;
    padding: 5px;
    background-color: #060515;

    .react-toast-notifications__toast__content {
      color: #ffffff;
      font-size: 15px;
    }

    .react-toast-notifications__toast__dismiss-button {
      .react-toast-notifications__toast__dismiss-icon {
        fill: #ffffff !important;
      }
    }

    .react-toast-notifications__toast__icon-wrapper {
      .react-toast-notifications__toast__icon {
        display: none !important;
      }
    }

    &.react-toast-notifications__toast--success {
      .react-toast-notifications__toast__icon-wrapper {
        background-color: #060515;

        &::after {
          content: "\f058";
          color: #48b16e;
          font-family: FontAwesome;
          font-size: 18px;
          padding-bottom: 8px;
          padding-top: 6px;
        }
      }

      .react-toast-notifications__toast__content {
        color: #ffffff;
        font-size: 15px;
        line-height: 1.2;

        &::before {
          content: "Success!\A\A";
          font-size: medium;
          font-weight: 500;
          white-space: pre;
        }
      }
    }

    &.react-toast-notifications__toast--error {
      .react-toast-notifications__toast__icon-wrapper {
        background-color: #060515;
        padding-bottom: 8px;
        padding-top: 6px;

        &::after {
          content: "\f057";
          color: #fb3836;
          font-family: FontAwesome;
          font-size: 18px;
        }
      }

      .react-toast-notifications__toast__content {
        color: #ffffff;
        font-size: 15px;

        &::before {
          content: "Error!\A\A";
          font-size: medium;
          font-weight: 500;
          white-space: pre;
        }
      }
    }

    &.react-toast-notifications__toast--warning {
      .react-toast-notifications__toast__icon-wrapper {
        background-color: #060515;
        padding-bottom: 8px;
        padding-top: 6px;

        &::after {
          content: "\f06a";
          color: #dca048;
          font-family: FontAwesome;
          font-size: 18px;
        }
      }

      .react-toast-notifications__toast__content {
        color: #ffffff;
        font-size: 22px;

        &::before {
          content: "Warning!\A\A";
          font-size: medium;
          font-weight: 500;
          white-space: pre;
        }
      }
    }
  }
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  box-shadow: none !important;
}

.modal {
  background-color: #9ca0a954;
}

.modal-content {
  border: 1px solid transparent;
}

.switch-tabs {
  background-color: #d9d9d9;
  background-color: var(--menu-slider-color);
  border-bottom: 1px solid var(--menu-slider-color) !important;
  // margin-bottom: 10px;
  padding-top: 3px;
  margin-bottom: 10px;
  padding-bottom: 3px;
  display: flex;
  border-radius: 4px;
  justify-content: space-evenly;

  .nav-tabs {
    border-bottom: 1px solid var(--menu-slider-color);
    background-color: var(--menu-slider-color);
    width: 100%;

    .nav-item {
      padding: 1px 4px 0px;
      border-radius: 2px;
      width: 50%;


      .new-tag {
        // position: absolute;
        border-radius: 2px;
        font-size: 10px;
        margin-left: -9px;
        margin-right: 4px;
        margin-top: -2px;
        padding: 2px 8px;
        color: var(--text-active-color);
        // background: linear-gradient(101.51deg, #FFA95F 1.21%, #FFD064 91.87%);
        background-color: var(--warning-orange);
      }

      .nav-link {
        font-size: 13px;
        color: #979797;
        border: none;
        cursor: pointer;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out !important;

        &.active {
          font-weight: 400;
          color: #000000;
          border: none;
          border-radius: 4px;
          // padding-left: 25px;
          // padding-right: 26px;
        }

        &:hover {
          color: #000;
        }
      }
    }
  }
}


.modal-header {
  padding: 10px 20px !important;
  font-size: 20px !important;

  button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #5d5d5d;
  }
}

.modal-content {
  border-radius: 2px;
}

.success-modal {
  padding: 25px;
  text-align: center;

  h4 {
    font-size: 18px;
    padding-bottom: 10px;
    padding-top: 20px;
    color: #000000;
  }

  p {
    font-size: 14px;
    color: #5e5e5e;
  }

  img {
    padding-top: 10px;
    display: block;
    margin: 0 auto;
    width: 25%;
  }
}

.confirm-modal {
  text-align: left;
  padding: 20px 20px;

  h4 {
    font-size: 18px;
    padding-bottom: 10px;
    padding-top: 20px;
    color: #000;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    color: #9ca0a9;

    &.statement {
      color: #000;
    }

    strong {
      color: #000;
    }
  }

  button {
    margin-top: 20px;
    min-width: 160px !important;
  }

  .confirm-btn-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .alert-icon {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1279px) {
  .confirm-btn-block {
    .notification-btn.btn.btn-secondary {
      height: 45px !important;
    }
  }
}

@media screen and (min-width: 1365px) {
  .confirm-btn-block {
    .notification-btn.btn.btn-secondary {
      height: 45px !important;
    }
  }
}

.modal-backdrop {
  background-color: rgb(17, 16, 17);
  opacity: 10.5;
}

.board-wrapper {
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  transform-origin: left top;

  &.active {
    border: 1px solid #342bc2 !important;
  }
}

.none {
  background: var(--pseudo-selection);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 1120px) {
  button.small-btn.btn.btn-primary {
    width: 50% !important;
  }

  button.medium-btn.btn.btn-primary {
    width: 50% !important;
  }

  button.small-skip-btn.btn.btn-primary {
    width: 50% !important;
  }
}

body {
  // scrollbar-color: rgb(168, 168, 168) #f1f1f1 !important;
  // scrollbar-width: 6px !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6.8px;
  z-index: 10 !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  z-index: 10 !important;
}

input {
  &::-webkit-caps-lock-indicator {
    content: none;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
  }

  &::-webkit-contacts-auto-fill-button {
    opacity: 0;
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}

::-webkit-scrollbar-thumb {
  background: rgb(168, 168, 168);
  border-radius: 45px;
  z-index: 10 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  z-index: 10 !important;
}

.loader-gif {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  img {
    width: 25%;
  }
}

.react-toast-notifications__container {
  z-index: 999999999999999 !important;
}

.abs::-webkit-scrollbar {
  z-index: 10;
}

body.modal-open {
  overflow: hidden;
}

#root {
  width: 100%;
}

input::placeholder {
  font-size: 14px;
  opacity: 0.5 !important;
}

.css-14el2xx-placeholder {
  font-size: 14px;
  opacity: 0.5 !important;
}

.btnWidth {
  width: 48%;
}

.p_colorGrey {
  color: #15192080 !important;
  font-weight: 400 !important;
}

.drag-cursor {
  cursor: drag;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.loader-gif,
div.deactivate-success,
div.payment-wrapper,
div.error-block-404,
.chatbot {
  height: auto !important;
  background-color: #ffffff;
}

.help-center-container {
  height: auto !important;
  background-color: transparent;
}

html,
body,
#root,
.App,
.App>div,
.builder-wrapper,
.canvas-wrapper {
  height: 100%;
}

.enclose-popup {

  .modal:not(.modal-top-none),
  .modal-backdrop:not(.modal-backdrop-top-none) {
    top: 5px !important;
  }
}

.mobile-error {
  bottom: 0;
  opacity: 1;
  max-width: 100%;
  margin: 5rem auto 0;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
  overflow-y: hidden !important;

  &.modal-dialog-centered {
    display: flex;
    align-items: flex-end;
    min-height: calc(100% - 5rem);
  }

  .content-error {
    padding: 10px 30px 10px;
    max-height: calc(99.7vh - 80px);
    overflow: auto;
    text-align: center;

    .no-block {
      position: relative;
      text-align: center;

      span {
        position: absolute;
        top: 0;
        left: 43%;
        background: #ced4da;
        height: 7px;
        width: 45px;
        border-radius: 8px;
      }

      h3 {
        display: block;
        font-size: 22px;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin: 10px auto;
        font-weight: 500;
        line-height: 1.2;
      }

      p {
        margin-top: 0;
        margin-bottom: 1rem;
        text-align: justify;
        font-size: 16px;
        color: #3e3e3e;
        line-height: 1.5;
        text-align: center;
      }

      button.prime-btn.btn.btn-primary {
        min-width: 100%;
        height: 50px;
        background-color: #342bc2;
        border-radius: 2px;
        border: 1px solid #342bc2;
        margin: 20px 0;

        &:hover {
          background-color: #272092;
          border: 1px solid #272092;
        }
      }

      .live-demo {
        border-radius: 2px;
        border: solid 1px #342bc2;
        background-color: rgba(52, 43, 194, 0.08);
        color: #342bc2;
        height: 50px;
        min-width: 100%;
        margin: 0 0 20px;

        &:hover {
          border: 1px solid #342bc2;
          background-color: #e3e2ea;
        }
      }
    }
  }
}

::placeholder {
  color: #9ca0a9;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9ca0a9;
}

::-ms-input-placeholder {
  color: #9ca0a9;
}

.sized-modal {
  max-width: 385px !important;

  .confirm-modal {
    padding: 10px 10px !important;

    button {
      margin-top: 10px !important;
    }
  }
}

input::-webkit-input-placeholder {
  font-size: 15px;
}

input::placeholder {
  font-size: 15px;
}

.upgrade-modal {
  max-width: 850px;

  .modal-content {
    background-color: transparent !important;
  }

  .bg-white {
    background-color: #ffff !important;
  }

  .upgrade-body {
    padding: 0;

    .parent {
      display: flex;

      .child {
        flex-basis: 50%;
        flex-grow: 1;
      }

      .child-1 {}

      .child-2 {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .close-player {
      position: absolute;
      top: 0px;
      right: -40px;

      button {
        color: #ffffff;
        background-color: #060606;
        border: 1px solid #060606;
        outline: none !important;
        font-weight: normal;
        font-size: 12px;
        border-radius: 2px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: #ffffff;
          background-color: #060606;
          border: 1px solid #060606;
        }
      }
    }

    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .col-sm-6 .col-md-6,
    .upgrade-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .content-upgrade {
      background-color: #ffffff;
      padding: 40px 40px 30px;

      .header {
        h3 {
          font-size: 24px;
          font-weight: 500;
          color: #000000;
          position: relative;
          padding-bottom: 8px;
        }

        h3.plan::after {
          content: "";
          background-image: url(../assests/icons/crownicon.svg);
          background-repeat: no-repeat;
          position: absolute;
          background-size: 100% 100%;
          width: 30px;
          height: 30px;
          transform: rotate(35deg);
          right: 150;
          top: -17px;
          margin-left: -2px;
        }

        p {
          font-size: 14px;
          color: #000000;
          padding-bottom: 8px;
        }
      }

      .plans {
        display: flex;
        padding: 10px 0 28px;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        h4 {
          color: #000000;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;

          span {
            background-color: #dff5ef;
            color: #22946b;
            font-size: 9px;
            white-space: nowrap;
            padding: 2px 4px;
            border-radius: 2px;
            margin-left: 4px;
            position: absolute;
          }
        }

        .amount {
          display: flex;
          align-items: baseline;
          gap: 5px;

          h5 {
            font-size: 24px;
            font-weight: 500;
            color: #000000;

            span {
              font-size: 12px;
            }
          }

          h6 {
            color: rgba(156, 160, 169, 1);
            font-size: 10px;
            font-weight: 400;
            white-space: nowrap;
          }
        }

        .period-billing {
          border: 1px solid rgb(52, 43, 194);
          padding: 15px 40px 10px 20px;
          border-radius: 2px;
          cursor: pointer;
          position: relative;

          .blue-tick {
            position: absolute;
            top: -12px;
            right: -7px;
            z-index: 1;

            img {
              width: 16px;
              background: white;
              box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1);
              -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1);
              -moz-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 1);
            }
          }

          .save-money {
            position: absolute;
            color: #ffffff;
            background-color: #342bc2;
            border-radius: 2px;
            font-weight: 500;
            font-size: 12px;
            padding: 4px 10px;
            display: inline-flex;
            gap: 3px;
            top: -14px;
            right: 12px;

            p {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }

      .items-included-in-pro {
        margin-bottom: 30px;

        p {
          font-size: 14px;
          color: #000000;
        }

        .items-list-section {
          ul {
            display: flex;
            justify-content: flex-start;
            border: 1px solid rgba(230, 231, 233, 0.5);
            padding: 15px 20px;
            margin-bottom: 0.7rem;

            img {
              width: 10%;
              margin-right: 10px;
              border-radius: 50%;
              padding: 8px;
              width: 35px;
              height: 35px;
            }

            .icon-1 {
              background-color: rgba(93, 95, 239, 0.08);
            }

            .icon-2 {
              background-color: rgba(252, 167, 12, 0.08);
            }

            .icon-3 {
              background-color: rgba(34, 148, 107, 0.08);
            }

            .items-in-pro {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }

            li {
              list-style: none;
              color: #5e5e5e;
              font-size: 12px;
            }
          }
        }
      }

      .popup-resize-btn {
        height: 40px !important;
      }

      .skip {
        color: var(--active-blue-color);
        font-weight: 500;
        margin: 20px auto 0;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .illustration {
      background-color: #f6f6f6;
      height: 100%;
      width: 100%;
      text-align: center;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

.pro-tag {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: rgba(62, 62, 62, 0.7);
  padding: 0px 6px 0px 5px;
  border-radius: 4px;

  &::before {
    content: " ";
    background-image: url("../assests/icons/crownicon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
  }
}

.pro-tag-list {
  position: relative;
  margin-left: 20px;
  padding-right: 0 !important;

  &::before {
    content: url("../assests/icons/crown-bg.svg");
    height: 20px;
    width: 20px;
    margin-right: 0px;
    position: absolute;
    top: 0px;
    left: -23px;
  }
}

.designs-col {
  position: relative;

  .designs-pro-tag {
    position: absolute;
    left: 21px;
    top: 11px;
    z-index: 10;
  }

  .templates-pro-tag {
    position: absolute;
    left: 21px;
    top: 11px;
    z-index: 10;
  }

  .screens-pro-tag {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .themes-pro-tag {
    position: absolute;
    top: 5px;
    right: 45px;
    z-index: 10;

    .pro-tag {
      font-size: 11px;
      padding: 0 3px 0 2px;

      &::before {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.template-info-pro-tag {
  display: inline-block;
  padding-bottom: 19px;

  .pro-tag {
    padding: 0px 6px 0px 5px;
  }
}

.pro-tag-upgrade-popup {
  // background-color: rgba(0, 0, 0, 0.5);
  background-color: #323232;
  opacity: 0.75;
  border-radius: 16px;
  color: #ffffff;
  padding: 3px 12px;
  font-size: 13px;
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
  letter-spacing: 0.4px;

  &::before {
    display: inline-block;
    content: "";
    background: url("../assests/icons/crownicon.svg") no-repeat 0 0;
    background-size: 100%;
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
}

.screen-limit-tag-upgrade-popup {
  background-color: #323232;
  opacity: 0.75;
  border-radius: 16px;
  color: #ffffff;
  padding: 4px 14px;
  font-size: 13px;
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
  letter-spacing: 0.4px;

  &::before {
    display: inline-block;
    content: "";
    background: url("../assests/icons/get-screens.svg") no-repeat 0 0;
    background-size: 100%;
    height: 17px;
    width: 17px;
    margin-right: 5px;
  }
}

.project-limit-tag-upgrade-popup {
  background-color: #323232;
  opacity: 0.75;
  border-radius: 16px;
  color: #ffffff;
  padding: 4px 14px;
  font-size: 13px;
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
  letter-spacing: 0.4px;

  &::before {
    display: inline-block;
    content: "";
    background: url("../assests/icons/get-projects.svg") no-repeat 0 0;
    background-size: 100%;
    height: 17px;
    width: 17px;
    margin-right: 5px;
  }
}

.exports-limit-tag-upgrade-popup {
  background-color: #323232;
  opacity: 0.75;
  border-radius: 16px;
  color: #ffffff;
  padding: 4px 14px;
  font-size: 13px;
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
  letter-spacing: 0.4px;

  &::before {
    display: inline-block;
    content: "";
    background: url("../assests/icons/export-popup.svg") no-repeat 0 0;
    background-size: 100%;
    height: 17px;
    width: 17px;
    margin-right: 5px;
  }
}

.fixed-project-limit-banner-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-bottom: 0px;
  background-color: #ffff;

  .fixed-project-limit-banner {
    border-left: unset;
    padding: 10px 100px 10px 100px;
  }
}

.password-check-wrapper {
  .title {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
  }

  .password-checker {
    padding-bottom: 30px;

    li {
      padding-bottom: 7px;
    }

    li.valid {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    li.invalid {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(50, 50, 50, 0.5);
    }

    svg {
      margin: 5px 10px 0px 0px !important;
    }
  }
}

:fullscreen,
::backdrop {
  background-color: var(--text-active-color) !important;
}

.custom-help-popup-body {
  padding: 0px !important;
  position: relative;

  .helpcenter-popup {
    .close-help-popup {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px 10px;
      text-align: center;
      font-size: 11px;
      border-radius: 2px;
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 999999;

      &:hover {
        background-color: rgba(39, 32, 146, 0.5);
      }
    }

    video {
      max-width: 100%;
      width: 100%;
    }

    .content {
      .highlight {
        display: inline-flex;
        align-items: center;
        gap: 2px;
        padding: 4px 8px;
        margin-bottom: 12px;
        font-size: 12px;
        border-radius: 13px;
        color: var(--text-active-color);
        background-image: linear-gradient(#fb63bc, #fa8f4f);

        &::before {
          content: "";
          display: inline-flex;
          background-image: url("../assests/images/star-highlight.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 14px;
          height: 14px;
        }
      }

      padding: 25px 40px 35px;

      h2 {
        font-size: 18px;
        font-weight: 500;
        color: var(--text-primary-color);
      }

      p {
        font-size: 14px;
        color: var(--text-secondary-color);
      }
    }

    .btns {
      display: flex;

      .visit-help-center-btn {
        font-size: 12px !important;
        padding: 0px 20px;
        min-width: unset;
        height: 40px !important;
      }

      .skip-for-now-help-center {
        font-size: 12px;
        padding: 0px 20px;
        background-color: var(--text-active-color);
        color: var(--text-secondary-color);
      }
    }
  }
}

.help-center-container {
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 999999;
}

.dashboard-builder~.chatbot {
  bottom: 60px !important;
  right: 10px !important;
}

// .dashboard-builder~.chatbot,
.onboaring-wrapper~.chatbot,
.onboarding-wrapper~.chatbot,
.embed-wrapper~.chatbot,
.loader-gif~.chatbot,
.accepting-invite~.chatbot,
.access-mode~.chatbot,
.image-saver~.chatbot {
  display: none !important;
}

.onboaring-wrapper~.help-center-container,
.onboarding-wrapper~.help-center-container,
.embed-wrapper~.help-center-container,
.loader-gif~.help-center-container {
  display: none !important;
}

.user-session-modal {
  .session-div {
    padding-top: 15px;
    padding-bottom: 17px;
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }

  p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    padding-top: 10px;
    font-size: 16px;
    color: #9ca0a9;
  }

  button {
    min-width: auto !important;
    align-self: flex-end;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    padding: 0px 61px;
    /* identical to box height, or 17px */

    text-align: center;

    color: #ffffff;
    margin-right: 10px;
  }
}

.version {
  color: var(--text-tertiary-color);
  font-size: 14px;
  padding: 5px 24px 0;
}

.skip-trial-toggle {
  padding: 10px 0;

  span {
    background-color: #dff5ef;
    color: #22946b;
    font-size: 12px;
    white-space: nowrap;
    padding: 3px 6px;
    border-radius: 2px;
    margin: 0 5px;
  }

  p {
    font-size: 14px;
    display: inline;
    margin: 0 5px 0 0;
  }

  img {
    margin: 0 5px;
  }
}

.section-gap {
  margin: 50px 0px 30px;
}

.bottom-page-space {
  margin-bottom: 100px;
}

.feedback-container {
  .themes-popup {
    .header {
      // display: block !important;
      margin-bottom: 8px;
      align-items: flex-end;

      h4 {
        font-size: 18px;
        margin-bottom: 0px;
      }

      .closes-button {
        text-align: right;
      }
    }

    .content {
      // max-width: 75%;
      padding: 15px 0 0 0;
      margin-bottom: -10px;

      h5 {
        padding: 0;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .form {
      .text-area {
        margin-bottom: 30px;

        textarea {
          max-height: 100px;
          border-radius: 2px;
          font-size: 10px;
          padding: 14px 12px;
          letter-spacing: 0.25px;
          color: var(--text-primary-color);

          &::placeholder {
            color: var(--text-tertiary-color) !important;
          }

          &:focus {
            color: var(--text-primary-color);
            border: 1px solid var(--border-color);
            outline: 0;
            box-shadow: none;
          }
        }

        label {
          font-size: 12px;
          padding-bottom: 5px;
        }
      }
    }

    .ratings-section {
      .ratings {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .rating-box {
          border-radius: 2px;
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 12px;
          margin: 25px 0px 10px;
        }

        .default-red {
          background: linear-gradient(to bottom,
              rgba(239, 68, 68, 1) 10%,
              rgba(239, 68, 68, 0.12) 10%);
        }

        .default-yellow {
          background: linear-gradient(to bottom,
              rgba(255, 158, 42, 1) 10%,
              rgba(255, 158, 42, 0.12) 10%);
        }

        .default-green {
          background: linear-gradient(to bottom,
              rgba(34, 148, 107, 1) 10%,
              rgba(34, 148, 107, 0.12) 10%);
        }

        .active-red {
          background-color: rgba(239, 68, 68, 1);
          color: var(--text-active-color);
        }

        .active-yellow {
          background-color: rgba(255, 158, 42, 1);
          color: var(--text-active-color);
        }

        .active-green {
          background-color: rgba(34, 148, 107, 1);
          color: var(--text-active-color);
        }
      }

      .ratings-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        color: var(--text-tertiary-color);
      }
    }

    .submit-btn {
      text-align: right;

      button {
        min-width: 22% !important;
      }
    }
  }
}

.resize {
  justify-content: space-between;

  .resize-period-billing {
    width: 50%;

    .amount {
      h6 {
        white-space: nowrap;
      }
    }
  }
}

.submit-response {
  .success-modal {
    h4 {
      font-size: 20px;
    }

    p {
      color: var(--text-tertiary-color);
    }
  }
}

.new-element-tag-container {
  position: relative;

  .new-element-tag {
    position: absolute;
    border-radius: 2px;
    font-size: 8px;
    top: 5px;
    left: 5px;
    padding: 2px 8px;
    color: var(--text-active-color);
    // background: linear-gradient(101.51deg, #FFA95F 1.21%, #FFD064 91.87%);
    background-color: var(--warning-orange);
  }
}

.promotion {
  padding-top: 0px !important;
  padding: 40px 100px 0px 100px;
  background-color: #ff9e2a;

  margin-bottom: -8px;

  img {
    width: 18px;
    height: 16px;
  }

  .promotion-section {
    // background-image: url("../assests/images/black-promotion-banner.svg");  //Product will be live soon
    // background-image: url("../assests/images/purple-promotion-banner.svg"); //Product is live
    padding: 10px 0px 15px;
    display: flex;

    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 2px;

    .left-section-content {
      text-align: center;
      display: flex;
      gap: 20px;
      align-items: center;

      h2 {
        font-weight: 400;
        margin-top: 6px;
        font-size: 14px;
        color: var(--text-active-color);
      }

      p {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.75);
        margin-bottom: 0px;
      }
    }

    .right-section-btn {
      button {
        background-color: var(--error-red);
        border-color: var(--error-red);
        color: var(--text-active-color);
        font-size: 12px;
        padding: 8px 24px;
        border-radius: 2px;
      }
    }

    .right-section-btn-live {
      button {
        background-color: var(--text-active-color);
        border-color: var(--text-active-color);
        color: var();
        font-size: 12px;
        padding: 8px 24px;
        border-radius: 2px;

        p {
          margin-bottom: 0px;
          background: linear-gradient(90deg, #8872f3 0%, #744efd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.error-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;

  .error-page-text {
    display: flex;
    flex-direction: column;

    .Oopstext {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 120%;
      color: rgba(24, 24, 25, 0.9);

      .highlighted-text {
        color: "#342BC2";
      }
    }

    .oopsHelpingtext {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      padding-top: 10px;
      line-height: 120%;
      color: #565758;
    }
  }

  .secondaryPolygon-img {
    margin: -120px 0 0 -650px;
    position: absolute;
  }

  .dashedPolygon-img {
    margin: 50px 0 0 -450px;
    position: absolute;
  }

  .dottedRectangle-img {
    margin: 143px 0 0 -715px;
    position: absolute;
  }

  .polygon-img {
    position: absolute;
    margin: 106px 0 0 350px;
    z-index: -1;
    width: 35px;
  }

  .blue-curve-img {
    position: absolute;
    margin: 125px 0 0 486px;
    z-index: -1;
    width: 175px;
  }

  .button-div {
    display: flex;
    padding-top: 40px;

    .contactUsbtn {
      border-radius: 2px;
      margin-right: 10px;
      border: 1px solid whitesmoke;
      background-color: rgba(52, 43, 194, 0.08);
      color: #342bc2;
      width: 140px;
      height: 50px;

      &:hover {
        border: 1px solid #342bc2;
        background-color: #e3e2ea;
        color: #342bc2;
      }
    }

    .backHomebtn {
      width: 140px;
      margin-right: 10px;

      height: 50px;
      background-color: #342bc2;
      border-radius: 2px;
      border: 1px solid #342bc2;

      &:hover {
        background-color: #272092;
        border: 1px solid #272092;
      }
    }
  }

  .img-div {
    padding-top: 40px;

    img {
      height: 300px;
    }
  }
}

.black-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    padding: 7px;
    border-radius: 2px;
    background-color: #000000;

    &:hover {
      background-color: #e9ecef;

      path {
        fill: #000000;
      }
    }
  }
}

.deployment {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  margin: 0 auto;
  text-align: center;

  .curve {
    position: absolute;
    width: 250px;
    height: 23.92px;
    top: -3px;
    left: -240px;
  }

  .polygon {
    position: absolute;
    width: 250px;
    height: 23.92px;
    top: 260px;
    left: -395px;
  }

  h2 {
    color: #000000;
    font-size: 36px;
    font-weight: 600;
    margin-right: 0px;

    span {
      color: #342bc2;
    }
  }

  p {
    color: #5e5e5e;
    font-size: 18px;
    padding-bottom: 60px;
  }

  .polygon-down {
    position: absolute;
    width: 50px;
    height: 23.92px;
    top: 170px;
    right: -80px;
  }

  .curve-down {
    position: absolute;
    height: 23.92px;
    top: 200px;
    right: -320px;
  }
}

.universal-inline-wrap {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box !important;
  // -webkit-line-clamp: 2;  Add clamping as per requirements by making new class
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.universal-block-wrap {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  // -webkit-line-clamp: 2;  Add clamping as per requirements by making new class
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.disabled-with-info-icon {
  opacity: 0.6;
  pointer-events: none;
}

// Styling for the new "Upgrade to Mokkup Pro" pop-up
.upgrade-popup {
  max-width: 850px;

  .modal-content {
    background-color: transparent !important;
  }

  .upgrade-body {
    padding: 0;

    .upgrade-popup-division-container {
      display: flex;

      .upgrade-popup-division-one {
        background-color: #ffff !important;
        height: 100%;
        padding: 42px 40px;
        background-image: url(../assests/icons/pro-icon-illustration.svg);
        background-repeat: no-repeat;
        background-position: 0% 100%;

        .upgrade-popup-personal-commercial-banner-outer {

          display: inline-block;
          width: 80%;
          height: 20px;
          border-radius: 2px;
          background-color: #FF9E2A1A;
          position: relative;
          box-sizing: border-box;
          margin-bottom: 15px;

          .upgrade-popup-personal-commercial-banner-inner {
            color: var(--warning-orange);
            font-size: 10px;
            text-align: center;
            line-height: 20px;
          }
        }

        .upgrade-popup-project-limit-tag {
          background-color: #323232;
          opacity: 0.75;
          border-radius: 16px;
          color: #ffffff;
          padding: 4px 14px;
          font-size: 13px;
          display: inline-flex;
          margin-bottom: 12px;
          align-items: center;
          letter-spacing: 0.4px;

          &::before {
            display: inline-block;
            content: "";
            background: url("../assests/icons/get-projects.svg") no-repeat 0 0;
            background-size: 100%;
            height: 17px;
            width: 17px;
            margin-right: 5px;
          }
        }

        .division-one-heading {
          color: #000000;
          font-family: Rubik;
          font-size: 18px;
          font-weight: 500;
          line-height: 21.6px;
          text-align: left;
          margin-bottom: 8px;
        }

        .division-one-sub-heading {
          color: #5e5e5e;
          font-family: Rubik;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.4px;
          text-align: left;
          margin-bottom: 5px !important;
        }

        .division-one-pricing-container {
          display: flex;
          gap: 6px;
          align-items: baseline;
          margin-bottom: 6px;

          .pricing-one {
            display: flex;
            align-items: baseline;

            .dollar-symbol {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
              letter-spacing: -0.5px;
              text-align: center;
              color: #000000;
            }

            .pricing-value-one {
              font-family: Rubik;
              font-size: 32px !important;
              font-weight: 500;
              letter-spacing: -0.5px;
              text-align: center;
              color: #000000;
              margin-bottom: 0 !important;
            }
          }

          .pricing-two {
            display: flex;
            align-items: baseline;
            text-decoration: line-through;
            text-decoration-color: #5e5e5e;

            .dollar-symbol {
              font-family: Rubik;
              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
              letter-spacing: -0.5px;
              text-align: center;
              color: #5e5e5e;
            }

            .pricing-value-two {
              font-family: Rubik;
              font-size: 16px !important;
              font-weight: 500;
              letter-spacing: -0.5px;
              text-align: center;
              color: #5e5e5e !important;
              margin-bottom: 0 !important;
            }
          }
        }

        .division-one-description {
          color: #9ca0a9;
          font-family: Rubik;
          font-size: 12px;
          font-weight: 400;
          line-height: 16.8px;
          letter-spacing: -0.5px;
          margin-bottom: 6px !important;
        }

        .get-started-button {
          font-family: Rubik;
          font-size: 12px !important;
          font-weight: 400;
          line-height: 16.8px;
          letter-spacing: -0.5px;
          text-align: center;
          height: 36px !important;
          margin-top: 22px;
        }

        .learn-more-button {
          font-family: Rubik;
          font-size: 12px !important;
          font-weight: 400;
          line-height: 16.8px;
          letter-spacing: -0.5px;
          text-align: center;
          height: 36px !important;
          margin-top: 10px;
          color: #342bc2 !important;
          background-color: #ffffff !important;
        }
      }

      .upgrade-popup-division-two {
        background-color: #ffffff !important;
        height: 100%;
        padding: 17px;
        padding-top: 15px;
        padding-left: 0;

        .upgrade-popup-division-two-container {
          background: #342bc20d;
          padding: 30px 50px 40px 40px;
          border-radius: 2px;
          background-image: url(../assests/icons/pattern-illustration.svg);
          background-repeat: no-repeat;
          background-position: 100% 0%;

          .upgrade-popup-division-two-heading {
            color: #5e5e5e;
            font-family: Rubik;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.5px;
            margin-bottom: 20px !important;
          }

          .upgrade-popup-division-two-list {
            .upgrade-popup-division-two-list-details {
              display: flex;
              align-items: center;
              gap: 15px;
              margin-bottom: 20px !important;

              .upgrade-popup-division-two-list-title {
                color: #000000;
                font-family: Rubik;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                text-align: left;
                margin-bottom: 0 !important;
              }

              .upgrade-popup-division-two-list-description {
                color: #9ca0a9;
                font-family: Rubik;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.5px;
                text-align: left;
              }

              .upgrade-popup-division-two-list-collective {
                .upgrade-popup-division-two-list-collective-container {
                  display: flex;
                  align-items: center;
                  gap: 15px;

                  .upgrade-popup-division-two-list-title {
                    margin-bottom: 0 !important;
                  }
                }

                .upgrade-popup-division-two-list-description {
                  margin-top: 4px;
                  margin-bottom: 0 !important;
                  padding-inline-start: 30px;
                }
              }
            }
          }
        }
      }
    }

    .close-player {
      position: absolute;
      top: 0px;
      right: -40px;

      button {
        color: #ffffff;
        background-color: #060606;
        border: 1px solid #060606;
        outline: none !important;
        font-weight: normal;
        font-size: 12px;
        border-radius: 2px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: #ffffff;
          background-color: #060606;
          border: 1px solid #060606;
        }
      }
    }
  }
}

.auto-save-container {
  .content {
    h5 {
      font-size: 1rem;
      color: var(--text-primary-color);
      font-weight: 500;
      margin-bottom: 0px;
    }

    p {
      color: rgba(94, 94, 94, 1);
      font-size: 14px;
      font-weight: 400;
    }

    ~.submit-btn button {
      font-size: 14px;
      width: 100px !important;
    }
  }
}